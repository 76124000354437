import { useMutation } from 'react-query';
import { notification } from 'antd';
import addressSingleAlert from './utils/addressSingleAlert';
import mutateFn from './utils/addressMultipleAlerts';
import { captureError } from 'utils';

const useAlertMutations = () => {
  const addressAlert = useMutation(addressSingleAlert, {
    onSuccess: () => {
      // notify the user
      notification.success({
        message: 'Successfully addressed alert',
      });
    },
    onError: err => {
      captureError({ error: err });
    },
  });
  const addressMultipleAlerts = useMutation(
    async (params: {
      indexArray: [] | undefined;
      setIndexArray: React.Dispatch<React.SetStateAction<[] | undefined>>;
      unaddressedAlerts: Alert[] | undefined;
      refetch: Function | undefined;
    }) => {
      return mutateFn({
        ...params,
      });
    },
    {
      onSuccess: () => {
        // notify the user
        notification.success({
          message: 'Successfully addressed alert(s)',
        });
      },
      onError: err => {
        captureError({ error: err });
      },
    },
  );

  return {
    addressAlert,
    addressMultipleAlerts,
  };
};

export default useAlertMutations;
