import _ from 'lodash';
import { notification } from 'antd';
import Auth from '@aws-amplify/auth';
import moment from 'moment';
import ky from 'ky';
import { prettyToAnelto, prettyToVitalsType } from 'dictionary';

const thresholdRegex = /\b\d{1,3}\b/;

type updatePatientThresholds = (args: {
  rowRecord: any;
  patientMin: any;
  patientMax: any;
  currentPatient: Patient | undefined;
  group: string | undefined;
  patientThresholds: PatientThresholds;
}) => Promise<any>;

const updatePatientThresholds: updatePatientThresholds = async ({
  rowRecord,
  patientMin,
  patientMax,
  currentPatient,
  group,
  patientThresholds,
}) => {
  // validate new thresholds
  if (!currentPatient || !group) return;
  if (!patientMin || !patientMax) {
    return notification.error({ message: 'Threshold must have a minimum and maximum' });
  }
  if (!thresholdRegex.test(patientMin) || !thresholdRegex.test(patientMax)) {
    return notification.error({ message: 'Threshold must be a number and 3 digits or less' });
  }
  if (_.toNumber(patientMax) <= _.toNumber(patientMin)) {
    return notification.error({ message: 'Maximum value must be greater than minimum' });
  }

  // create threshold object the api accepts
  const newThreshold = {
    maximum: '' + patientMax,
    minimum: '' + patientMin,
    localOwner: currentPatient.username,
    orbId: currentPatient.lead_id,
    thresholdCarePlanId: group,
    vitalName: rowRecord.vital,
    xmitId: currentPatient.xmit_id,
  };
  const result = await ky
    .post('https://bvaotch7t9.execute-api.us-east-1.amazonaws.com/prod', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: (await Auth.currentSession()).idToken.jwtToken,
      },
      json: {
        ...{
          ...newThreshold,
          vitalName: prettyToAnelto[rowRecord.vital as PrettyVital],
        },
        createdAt: moment().format(),
      },
    })
    .json<any>();

  // API will return errors as 200, so do this additional check
  if (result.statusCode === 400) {
    throw new Error(result.message);
  }

  return {
    ...patientThresholds,
    [prettyToVitalsType[rowRecord.vital as PrettyVital]]: {
      min: +patientMin,
      max: +patientMax,
    },
  };
};

export default updatePatientThresholds;
