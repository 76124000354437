import React, { Ref, useMemo, useState } from 'react';
import {
  CartesianGrid,
  ComposedChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Area,
} from 'recharts';
import { useVitalsChart, useFilteredVitals } from 'hooks';
import { Empty } from 'antd';
import moment from 'moment';
import { vitalsTypeToPretty, vitalsTypeToUnit } from 'dictionary';
import { useVitals } from 'context/vitals';
import determineBelowThresholdAreaColor from './utils/determineBelowThresholdAreaColor';
import determineAboveThresholdAreaColor from './utils/determineAboveThresholdAreaColor';
import {
  Card,
  Container,
  ChartContainer,
  ChartTitle,
  NoData,
  TestWrapper,
  TooltipCard,
  TooltipTitle,
  TooltipContent,
  TooltipUnits,
} from './VitalsChart.styled';

type VitalsChartProps = {
  id: string;
  vitalType: Vital;
  dateRange: [moment.Moment, moment.Moment];
  title?: string | null;
  patientMinThreshold?: number | null;
  patientMaxThreshold?: number | null;
  groupMinThreshold?: number | null;
  groupMaxThreshold?: number | null;
  dataMinOffset?: number;
  dataMaxOffset?: number;
  dataMin?: number | null;
  dataMax?: number | null;
};

const VitalsChart = React.forwardRef<ComposedChart, VitalsChartProps>(
  (
    {
      id, // id to give main div
      vitalType, // vital type of the chart
      dateRange, // display vitals within this date range
      title = null, // title of the chart
      patientMinThreshold = null, // patient minimum threshold
      patientMaxThreshold = null, // patient maximum threshold
      groupMinThreshold = null, // group minimum threshold
      groupMaxThreshold = null, // group maximum threshold
      dataMinOffset = 10, // minimum offset override
      dataMaxOffset = 10, // maximum offset override
      dataMin = null, // minimum value displayed override
      dataMax = null, // maximum value displayed override
    },
    ref,
  ) => {
    // store hooks
    const { data } = useVitals();
    const vitals = useFilteredVitals({
      data,
      type: vitalType,
      dateRange,
    });
    const { minThreshold, maxThreshold, domainMin, domainMax, chartData } = useVitalsChart({
      vitals,
      minThreshold: patientMinThreshold || groupMinThreshold,
      maxThreshold: patientMaxThreshold || groupMaxThreshold,
      dataMin,
      dataMax,
      vitalType,
      dataMinOffset,
      dataMaxOffset,
    });

    return (
      <Card data-testid="vitals-chart__card">
        <ChartTitle>{title || vitalsTypeToPretty[vitalType]}</ChartTitle>
        <Container id={id}>
          {/* Display empty if no data exists for vitalType */}
          {vitals.length === 0 && (
            <TestWrapper data-testid="vitals-chart__empty">
              <NoData image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </TestWrapper>
          )}

          {/* Display the chart with data if data is loaded and exists for vitalType */}
          {vitals.length !== 0 && (
            <ChartContainer data-testid="vitals-chart-with-data">
              <ResponsiveContainer width="100%" height="100%" minWidth={200} minHeight={200}>
                <ComposedChart data={chartData} ref={ref}>
                  <CartesianGrid strokeDasharray="3 3" />

                  {/* below threshold area */}
                  <Area
                    opacity={0.5}
                    dataKey={() => [domainMin, Math.min(minThreshold || Infinity, domainMax)]}
                    fill={determineBelowThresholdAreaColor({ minThreshold, vitals, vitalType })}
                    strokeOpacity={0}
                    isAnimationActive={false}
                    activeDot={false}
                  />

                  {/* normal area */}
                  <Area
                    opacity={0.5}
                    dataKey={() => [
                      minThreshold || domainMax,
                      Math.min(maxThreshold || Infinity, domainMax),
                    ]}
                    fill="#4bcbf3"
                    strokeOpacity={0}
                    isAnimationActive={false}
                    activeDot={false}
                  />

                  {/* above threshold area */}
                  {(maxThreshold || Infinity) < domainMax ? (
                    <Area
                      opacity={0.5}
                      dataKey={() => [maxThreshold || domainMax, domainMax]}
                      fill={determineAboveThresholdAreaColor({ maxThreshold, vitals, vitalType })}
                      strokeOpacity={0}
                      isAnimationActive={false}
                      activeDot={false}
                    />
                  ) : null}
                  <Tooltip content={<CustomTooltip />} coordinate={{ x: 100, y: 100 }} />
                  <XAxis
                    type="category"
                    dataKey="timestamp"
                    domain={['dataMin', 'dataMax']}
                    tickFormatter={date =>
                      moment
                        .utc(date)
                        .local()
                        .format('MM/DD')
                    }
                    scale="point"
                  />
                  <YAxis
                    type="number"
                    dataKey={vital => Number(vital[vitalType])}
                    domain={[domainMin, domainMax]}
                    scale="linear"
                  />

                  <Line
                    isAnimationActive={false}
                    dataKey={vitalType}
                    stroke="#094074"
                    strokeWidth={3}
                    activeDot={{ fill: '#094074', stroke: '#094074', strokeWidth: 4 }}
                  />
                </ComposedChart>
              </ResponsiveContainer>
            </ChartContainer>
          )}
        </Container>
      </Card>
    );
  },
);

type CustomTooltipProps = {
  active?: boolean;
  payload?: {
    payload?: {
      aboveThreshold?: number;
      belowThreshold?: number;
      normalRange?: number;
      heartRate?: string;
      oxygen?: string;
    };
    dataKey: Vital;
    value?: string;
  }[];
  label?: string;
};

export const CustomTooltip = ({ active, payload, label }: CustomTooltipProps) => {
  if (!active || !payload) return null;

  // find the correct reading data in the payload array
  const readingData = payload.find(data => typeof data.dataKey === 'string');
  const secondaryReadingData = payload.find(
    data => typeof data.dataKey === 'string' && data.dataKey !== readingData?.dataKey,
  );

  if (!readingData) return null;

  return (
    <TooltipCard>
      <TooltipTitle>
        {moment
          .utc(label)
          .local()
          .format('MMMM D, YYYY - h:mma')}
      </TooltipTitle>
      <TooltipContent>
        <div>
          {vitalsTypeToPretty[readingData.dataKey]}: <b>{readingData.value}</b>&nbsp;
          <TooltipUnits>{vitalsTypeToUnit[readingData.dataKey]}</TooltipUnits>
        </div>
        {secondaryReadingData ? (
          <div>
            {vitalsTypeToPretty[secondaryReadingData.dataKey]}: <b>{secondaryReadingData.value}</b>
            &nbsp;
            <TooltipUnits>{vitalsTypeToUnit[secondaryReadingData.dataKey]}</TooltipUnits>
          </div>
        ) : null}
      </TooltipContent>
    </TooltipCard>
  );
};

export default VitalsChart;
