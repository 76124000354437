import { ThresholdsContext } from 'context/thresholds';

type isVitalOutsideOfThreshold = (args: {
  reading: VitalsReading;
  thresholds: ThresholdsContext;
  type: Vital;
  activeAlerts: Alert[];
}) => Alert | null | string;

/**
 * Checks to see if vital is outside of given thresholds. Returns an Alert object if the
 * reading is outside of thresholds and has an associated active alert. Returns the string
 * 'alert is addressed' if the reading is outside of thresholds but does not have an associated
 * active alert. Returns null if the reading is not outside of thresholds.
 */
const isVitalOutsideOfThreshold: isVitalOutsideOfThreshold = ({
  reading,
  thresholds,
  type,
  activeAlerts,
}) => {
  if (!thresholds.patientThresholds.data || !thresholds.groupThresholds.data) return null;

  // grab needed values
  const value = Number(reading[type]);
  const minThreshold =
    thresholds.patientThresholds.data[type].min ||
    thresholds.groupThresholds.data[type].min ||
    -Infinity;
  const maxThreshold =
    thresholds.patientThresholds.data[type].max ||
    thresholds.groupThresholds.data[type].max ||
    Infinity;

  // grab diastolic values just incase these reading is a blood pressure reading
  const secondaryValue = Number(reading.diastolic);
  const secondaryMinThreshold =
    thresholds.patientThresholds.data.diastolic.min ||
    thresholds.groupThresholds.data.diastolic.min ||
    -Infinity;
  const secondaryMaxThreshold =
    thresholds.patientThresholds.data.diastolic.max ||
    thresholds.groupThresholds.data.diastolic.max ||
    Infinity;

  // if the value is outside of the normal range
  if (
    value < minThreshold ||
    value > maxThreshold ||
    (type === 'systolic' && secondaryValue < secondaryMinThreshold) ||
    (type === 'systolic' && secondaryValue > secondaryMaxThreshold)
  ) {
    const associatedAlert = activeAlerts.find(alert => alert.observation_id === reading.id);

    //grab the alert associated with it and return
    return associatedAlert || 'alert is addressed';
  }

  return null;
};

export default isVitalOutsideOfThreshold;
