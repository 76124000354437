import moment from 'moment';

type useFilteredVitals = (args: {
  data?: VitalsReading[];
  dateRange: [moment.Moment, moment.Moment];
  type?: Vital;
}) => VitalsReading[];

const useFilteredVitals: useFilteredVitals = ({ data = [], dateRange, type }) => {
  return data.filter(reading => {
    // if reading isn't the specific type, remove it
    if (type && !reading[type]) return false;

    // if reading is not within the past days shown range, remove it
    // (vital timestamp is stored in UTC)
    if (moment.utc(reading.timestamp).isBefore(dateRange[0])) return false;
    if (moment.utc(reading.timestamp).isAfter(dateRange[1])) return false;

    return true;
  });
};

export default useFilteredVitals;
