import styled from 'styled-components';

export const GlobalStylesProvider = styled.div`
  /* import fonts */
  @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');

  /* Ant Overrides */

  .ant-table-tbody {
    background-color: #fff !important;
  }
  .ant-table-wrapper {
    flex-grow: 1;
  }

  .ant-table-cell {
    h2 {
      text-align: center;
      margin: 0;
    }
  }

  .ant-table-content {
    tr th {
      background: #094074 !important;
      color: ${props => props.theme.textLight} !important;
    }
    > tr:first-child > th:first-child {
      border-top-left-radius: ${props => props.theme.secondaryBorderRadius};
    }
    > tr:first-child > th:last-child {
      border-top-right-radius: ${props => props.theme.secondaryBorderRadius};
    }
  }

  .ant-table-row-cell-break-word {
    text-align: center !important;
  }

  .ant-skeleton-paragraph {
    margin: 12px 0 0 0;
  }

  .ant-layout {
    overflow: hidden !important;
  }

  .ant-layout-sider-trigger {
    background-color: #0b2545 !important;
  }

  .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
    padding: 0 25px !important;
  }

  .ant-menu-item-selected {
    color: red;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    padding-top: 8px !important;
    height: 56px !important;
  }

  /* React Virtualized Overrides */

  .ReactVirtualized__Table__Grid {
    background-color: white;
    z-index: 0 !important;
  }

  .ReactVirtualized__Collection {
  }

  .ReactVirtualized__Collection__innerScrollContainer {
  }

  /* Grid default theme */

  .ReactVirtualized__Grid {
    &:focus {
      outline: none;
    }
  }

  .ReactVirtualized__Grid__innerScrollContainer {
  }

  /* Table default theme */

  .ReactVirtualized__Table {
  }

  .ReactVirtualized__Table__headerRow {
    color: ${props => props.theme.textLight};
    font-weight: 500;
    background-color: #094074;
    border-radius: ${props => props.theme.secondaryBorderRadius}
      ${props => props.theme.secondaryBorderRadius} 0px 0px;
    border-bottom: 1px solid #e8e8e8;
    text-transform: none;
    display: flex;
  }

  .ReactVirtualized__Table__row {
    border-bottom: 1px solid #e8e8e8;
    color: rgba(0, 0, 0, 0.8);
    background-color: #fff;
    transition: all 0.3s, height 0s;
    transition-property: all, height;
    transition-duration: 0.3s, 0s;
    transition-timing-function: ease, ease;
    transition-delay: 0s, 0s;
    display: flex;

    &:hover {
      background-color: rgba(71, 119, 178, 0.05);
    }
  }

  .ReactVirtualized__Table__headerTruncatedText {
    display: inline-block;
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .ReactVirtualized__Table__headerColumn {
    padding: 16px;
    height: 100%;
    margin: 0;

    /* make header text not highlightable */

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &:focus {
      outline: none;
    }
  }

  .ReactVirtualized__Table__rowColumn {
    display: flex;
    align-items: center;
    padding: 0 16px;
    margin: 0;
  }

  .ReactVirtualized__Table__rowColumn {
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .ReactVirtualized__Table__headerColumn:first-of-type,
  .ReactVirtualized__Table__rowColumn:first-of-type {
    margin-left: 10px;
  }

  .ReactVirtualized__Table__sortableHeaderColumn {
    cursor: pointer;
  }

  .ReactVirtualized__Table__sortableHeaderIconContainer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .ReactVirtualized__Table__sortableHeaderIcon {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 24px;
    flex: 0 0 24px;
    height: 1em;
    width: 1em;
    fill: currentColor;
  }

  body {
    background-color: #eef0f6;
    color: #3a4456;
    margin: 0;
    padding: 0;
  }
`;
