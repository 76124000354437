export default `query listOfficeIDS($group: String!) {
    listOfficeIDS(filter: {
      GroupName:{
        eq: $group
      }
      activeOffice:{
          eq: true
        }  
      }, limit: 250)
      {
      items {
        officeID
        officeName
        description
        officeCode
      }
    } 
  }
`;
