import React from 'react';
import _ from 'lodash';
import { usePatientAlerts } from 'context/patientAlerts';
import { useCurrentPatient } from 'context/patients';
import ControlSubHeader from 'components/display/ControlSubHeader/ControlSubHeader';
import { useKinesis } from 'hooks';
import { ExportButton } from 'components/export';
import { PatientAlertHistoryTable } from 'components/alerts';
import { PatientAlertHistoryContainer } from './PatientAlertHistory.styled';

const PatientAlertHistory = () => {
  const patientAlertsQuery = usePatientAlerts();
  const currentPatient = useCurrentPatient();

  useKinesis();

  return (
    <PatientAlertHistoryContainer>
      <ControlSubHeader
        breadcrumbSections={['Patient', 'Alert History']}
        isFetching={patientAlertsQuery.isFetching}
        refetch={[patientAlertsQuery.refetch]}
        isSuccess={patientAlertsQuery.isSuccess}
      >
        <ExportButton disabled={patientAlertsQuery.isLoading || !currentPatient} />
      </ControlSubHeader>
      <PatientAlertHistoryTable patientAlertsQuery={patientAlertsQuery} />
    </PatientAlertHistoryContainer>
  );
};

export default PatientAlertHistory;
