import React, { useState } from 'react';
import { Radio } from 'antd';
import { Button, Header, DateRangePicker } from 'elements';
import moment from 'moment';
import { Container, Settings, Actions, Label } from './ExportSettings.styled';

type ExportSettingsProps = {
  setIsExportModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  PDFButton: React.ReactNode;
  CSVButton?: React.ReactNode;
  showDateRange?: boolean;
};

const ExportSettings = ({
  setIsExportModalVisible,
  PDFButton,
  showDateRange = true,
  CSVButton,
}: ExportSettingsProps) => {
  const [exportType, setExportType] = useState<'pdf' | 'csv'>('pdf');

  return (
    <Container>
      <Settings>
        <Header noMargins>Export</Header>
        <Radio.Group
          value={exportType}
          onChange={val => setExportType(val.target.value)}
          buttonStyle="solid"
        >
          <Radio.Button value="pdf">PDF</Radio.Button>
          <Radio.Button value="csv">CSV</Radio.Button>
        </Radio.Group>
        {showDateRange ? (
          <div>
            <Label>Date Range</Label>
            <DateRangePicker />
          </div>
        ) : null}
      </Settings>
      <Actions>
        {exportType === 'pdf' ? PDFButton : null}
        {exportType === 'csv' ? CSVButton : null}

        <Button type="link" onClick={() => setIsExportModalVisible(false)}>
          Cancel
        </Button>
      </Actions>
    </Container>
  );
};

export default ExportSettings;
