import React from 'react';
import { UnauthorizedPage, WarningIcon, Text } from './Unauthorized.styled';

const Unauthorized = () => (
  <UnauthorizedPage>
    <WarningIcon data-testid="unauthorized-icon" />
    <Text>Unauthorized</Text>
  </UnauthorizedPage>
);
export default Unauthorized;
