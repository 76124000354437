/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { Layout } from 'antd';
import theme from '../../../styles/theme';

export const AppLayout = styled(Layout)`
  display: flex;
  flex-direction: row;
  min-height: 100vh !important;
`;

export const LoginTheme = {
  button: {
    backgroundColor: theme.primary1,
    color: '#ffffff',
    width: '100%',
    minWidth: '153px',
    borderRadius: theme.borderRadius,
  },
  formContainer: { height: '25%' },
  formSection: {
    backgroundColor: '#ffffff',
    boxShadow: theme.boxShadow,
    minWidth: '0',
    width: '350px',
  },
  container: { backgroundColor: '#f0f2f5', height: '100vh', width: '100vw', position: 'absolute' },
  sectionFooter: { display: 'inline' },
  a: { color: theme.primary1 },
  inputLabel: { color: 'black' },
  sectionHeader: {
    color: 'black',
  },
};
