import { useSpring } from 'react-spring';
import { useMotionPreference } from 'web-api-hooks';

export default function usePageFade() {
  const prefersReducedMotion = useMotionPreference() === 'reduce' || window.name === 'nodejs';
  const animatedStyles = useSpring({
    opacity: 1,
    from: {
      opacity: 0,
    },
    immediate: prefersReducedMotion,
  });
  return animatedStyles;
}
