import React from 'react';
import { EmptyIcon, LoadingIcon, Unauthorized } from 'elements';
import GenericTable from 'elements/GenericTable/GenericTable';
import { QueryResult } from 'react-query';
import { useOfficeList } from 'context/offices';
import { usePatientList } from 'context/patients';
import { useRoutes, useUnauthorized } from 'hooks';

type PatientAlertHistoryTableProps = {
  patientAlertsQuery: QueryResult<Alert[], Error>;
};

const PatientAlertHistoryTable = ({ patientAlertsQuery }: PatientAlertHistoryTableProps) => {
  const { isAuthorized } = useUnauthorized();
  const officeList = useOfficeList();
  const { pushRouteChange } = useRoutes();
  const patientList = usePatientList();

  if (patientAlertsQuery.isLoading || officeList.isLoading || patientList.isLoading) {
    return <LoadingIcon />;
  }

  if (!isAuthorized) return <Unauthorized />;

  if (!patientAlertsQuery.data?.length) {
    return <EmptyIcon />;
  }

  return (
    <GenericTable
      tableAttributes={[
        { attributeName: 'addressedTime', attributeType: 'timestamp', header: 'Date Addressed' },
        { attributeName: 'issue', attributeType: 'string', width: 300 },
        { attributeName: 'type', attributeType: 'string' },
        {
          attributeName: 'addressedBy',
          attributeType: 'string',
        },
        {
          attributeName: 'details',
          attributeType: 'button',
          buttonText: 'Details',
          header: ' ',
          onClick: (alert: Alert) => {
            pushRouteChange({
              section: 'alert-details',
              xmit: alert.xmit_id,
              timestamp: alert.timestamp,
            });
          },
        },
      ]}
      tableData={patientAlertsQuery.data}
    />
  );
};

export default PatientAlertHistoryTable;
