import React from 'react';
import { Button } from 'elements';
import { CSVLink } from 'react-csv';
import { vitalsTypes, vitalsTypeToPretty } from 'dictionary';
import moment from 'moment';
import { useThresholds } from 'context/thresholds';
import { useCurrentPatient } from 'context/patients';

const PatientThresholdsCSV = () => {
  const thresholds = useThresholds();
  const currentPatient = useCurrentPatient();
  const title = `${currentPatient?.customer_name} Patient Thresholds ${moment().format(
    'MM-DD-YYYY',
  )}`;

  const formattedThresholds = vitalsTypes.map(type => {
    return {
      type: vitalsTypeToPretty[type],
      groupLow: thresholds.groupThresholds.data?.[type].min,
      groupHigh: thresholds.groupThresholds.data?.[type].max,
      patientLow: thresholds.patientThresholds.data?.[type].min,
      patientHigh: thresholds.patientThresholds.data?.[type].max,
    };
  });

  const headers = [
    { label: 'Vital', key: 'type' },
    { label: 'Group Low', key: 'groupLow' },
    { label: 'Group High', key: 'groupHigh' },
    { label: 'Patient Low', key: 'patientLow' },
    { label: 'Patient High', key: 'patientHigh' },
  ];

  return (
    <CSVLink data={formattedThresholds} headers={headers} filename={`${title}.csv`} target="_blank">
      <Button>Export</Button>
    </CSVLink>
  );
};

export default PatientThresholdsCSV;
