import API, { graphqlOperation } from '@aws-amplify/api/lib';
import getPatientList from 'gql/getPatientList';
import awesomePhoneNumber from 'awesome-phonenumber';

type pullPatientList = (
  key: string,
  args: { currentOffice: Office | undefined },
) => Promise<Patient[]>;

const pullPatientList: pullPatientList = async (key, { currentOffice }) => {
  // if no office is selected, return an empty array
  if (!currentOffice) {
    return [];
  }

  const rawPatientList = (await API.graphql(
    graphqlOperation(getPatientList, {
      Office: currentOffice.officeName,
      officeCode: currentOffice.officeCode,
    }),
  )) as { data: { getPatientListData: any[] } };

  const patientList: Patient[] = rawPatientList.data.getPatientListData
    // remove any legacy users
    .filter(patient => patient.id || patient.username)
    // sort alphabetically (ascending)
    .sort((a, b) => {
      if (!a.customer_name || !b.customer_name) return -1;
      return a.customer_name.trim().localeCompare(b.customer_name.trim());
    })
    // map to a valid Patient object
    .map((patient: any) => ({
      ...patient,
      username: patient?.id || patient?.username,
      id: undefined,
      customer_phone: (() => {
        if (!patient?.customer_phone) return '-';

        // format phone number so it's displayed in a common format
        return new awesomePhoneNumber(patient?.customer_phone, 'US')
          ?.getNumber('significant')
          ?.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
      })(),
      doctor_phone: (() => {
        if (!patient?.doctor_phone) return '-';

        // format phone number so it's displayed in a common format
        return new awesomePhoneNumber(patient?.doctor_phone, 'US')
          ?.getNumber('significant')
          ?.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
      })(),
      activation_date: patient.updated_at
        ? patient.updated_at.replace('Z', '')
        : patient.created_at.replace('Z', ''),
    }));

  return patientList;
};

export default pullPatientList;
