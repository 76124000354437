import { useQuery } from 'react-query';
import getCognitoUserInfo from './utils/getCognitoUserInfo';

const useCognitoUser = () => {
  const query = useQuery('cognitoUser', getCognitoUserInfo);

  return query;
};

export default useCognitoUser;
