import styled from 'styled-components';
import { animated } from 'react-spring';
import { Empty as E } from 'antd';

export const GenericTableContainer = styled(animated.div)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const AutoSizerContainer = styled.div`
  flex-grow: 1;
  width: 100%;
`;

export const Empty = styled(E)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  z-index: 2 !important;
  position: inherit;
`;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
