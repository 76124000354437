import React from 'react';
import { useUnaddressedAlerts } from 'context/unaddressedAlerts';
import { useCurrentOffice } from 'context/offices';
import { useFilteredAlerts } from 'hooks';
import moment from 'moment';
import { Button } from 'elements';
import { Document, PDFViewer } from '@react-pdf/renderer';
import { useGeneratePDF } from 'components/export/__hooks__';
import PDFHeader from './PDFHeader';
import { PagePadding, Body, TableRow, TableColumn, Table, TableHeader, Footer } from './styles';

type WrapperProps = {
  dateRange: [moment.Moment, moment.Moment];
  display: 'preview' | 'button';
};

const Wrapper = ({ dateRange, display }: WrapperProps) => {
  const { query } = useUnaddressedAlerts();
  const currentOffice = useCurrentOffice();
  const activeAlerts = useFilteredAlerts({ data: query.data || [], dateRange });
  const title = `${currentOffice?.description} Office Active Alerts ${moment().format(
    'MM-DD-YYYY',
  )}`;
  const [generatePDF, { isLoading }] = useGeneratePDF();

  const document = (
    <OfficeActiveAlertsPDF
      activeAlerts={activeAlerts}
      currentOffice={currentOffice}
      dateRange={dateRange}
      title={title}
    />
  );

  if (display === 'preview') {
    return (
      <PDFViewer width="100%" height="100%">
        {document}
      </PDFViewer>
    );
  }

  return (
    <Button loading={isLoading} onClick={() => generatePDF({ document, title })}>
      Export
    </Button>
  );
};

type OfficeActiveAlertsPDFProps = {
  activeAlerts: Alert[] | undefined;
  currentOffice: Office | undefined;
  dateRange: [moment.Moment, moment.Moment];
  title: string;
};

const OfficeActiveAlertsPDF = ({
  activeAlerts,
  currentOffice,
  dateRange,
  title,
}: OfficeActiveAlertsPDFProps) => (
  <Document title={title}>
    <PagePadding wrap>
      <PDFHeader currentOffice={currentOffice} dateRange={dateRange} title="Office Active Alerts" />

      <Table>
        {/* Table Header Row */}
        <TableRow fixed>
          <TableColumn>
            <TableHeader>Timestamp</TableHeader>
          </TableColumn>
          <TableColumn>
            <TableHeader>Patient</TableHeader>
          </TableColumn>
          <TableColumn>
            <TableHeader>Type</TableHeader>
          </TableColumn>
          <TableColumn>
            <TableHeader>Issue</TableHeader>
          </TableColumn>
          <TableColumn>
            <TableHeader>Measured At</TableHeader>
          </TableColumn>
          <TableColumn>
            <TableHeader>Limit Over</TableHeader>
          </TableColumn>
        </TableRow>

        {/* Table Data Rows */}
        {activeAlerts
          ? activeAlerts.map((alert, index) => (
              <TableRow wrap={false} key={`${alert.patient}-${alert.timestamp}-${index}`}>
                <TableColumn>
                  <Body data-testid="pdf-row-timestamp">
                    {moment(alert.timestamp).format('MM/DD/YYYY hh:mm A') || '-'}
                  </Body>
                </TableColumn>
                <TableColumn>
                  <Body>{alert.patient || '-'}</Body>
                </TableColumn>
                <TableColumn>
                  <Body>{alert.type || '-'}</Body>
                </TableColumn>
                <TableColumn>
                  <Body>{alert.issue || '-'}</Body>
                </TableColumn>
                <TableColumn>
                  <Body>{alert.measuredAt || '-'}</Body>
                </TableColumn>
                <TableColumn>
                  <Body>{alert.limitOver || '-'}</Body>
                </TableColumn>
              </TableRow>
            ))
          : null}
      </Table>
      <Footer fixed>
        <Body fixed>Office Active Alerts</Body>
        <Body
          fixed
          render={({ pageNumber, totalPages }: { pageNumber: number; totalPages: number }) =>
            `${pageNumber} / ${totalPages}`
          }
        />
      </Footer>
    </PagePadding>
  </Document>
);

export default Wrapper;
