import { useSpring } from 'react-spring';
import { useMotionPreference } from 'web-api-hooks';

export default function useAlertPop() {
  const prefersReducedMotion = useMotionPreference() === 'reduce' || window.name === 'nodejs';
  const animatedStyles = useSpring({
    opacity: 1,
    transform: 'scale3d(1,1,1) rotateZ(0deg)',
    from: {
      opacity: 0,
      transform: 'scale3d(1.05,1.05,1.05) rotateZ(4deg)',
    },
    immediate: prefersReducedMotion,
  });
  return animatedStyles;
}
