import React from 'react';
import { usePatientAlerts } from 'context/patientAlerts';
import { useCurrentOffice } from 'context/offices';
import { useCurrentPatient } from 'context/patients';
import { useFilteredAlerts } from 'hooks';
import moment from 'moment';
import { Button } from 'elements';
import { useGeneratePDF } from 'components/export/__hooks__';
import { Document, PDFViewer } from '@react-pdf/renderer';
import PDFHeader from './PDFHeader';
import { PagePadding, Body, TableRow, TableColumn, Table, TableHeader, Footer } from './styles';

type WrapperProps = {
  dateRange: [moment.Moment, moment.Moment];
  display: 'preview' | 'button';
};

const Wrapper = ({ dateRange, display }: WrapperProps) => {
  const { data } = usePatientAlerts();
  const currentOffice = useCurrentOffice();
  const currentPatient = useCurrentPatient();
  const patientAlerts = useFilteredAlerts({ data: data || [], dateRange });
  const title = `${currentPatient?.customer_name} Patient Alert History ${moment().format(
    'MM-DD-YYYY',
  )}`;
  const [generatePDF, { isLoading }] = useGeneratePDF();

  const document = (
    <PatientAlertHistoryPDF
      patientAlerts={patientAlerts}
      currentOffice={currentOffice}
      dateRange={dateRange}
      title={title}
      patientName={currentPatient?.customer_name}
      dob={currentPatient?.date_of_birth}
      mrn={currentPatient?.MRNum}
    />
  );

  if (display === 'preview') {
    return (
      <PDFViewer width="100%" height="100%">
        {document}
      </PDFViewer>
    );
  }

  return (
    <Button loading={isLoading} onClick={() => generatePDF({ document, title })}>
      Export
    </Button>
  );
};

type PatientAlertHistoryPDFProps = {
  patientAlerts: Alert[] | undefined;
  currentOffice: Office | undefined;
  dateRange: [moment.Moment, moment.Moment];
  title: string;
  patientName?: string;
  dob?: string;
  mrn?: string;
};

const PatientAlertHistoryPDF = ({
  patientAlerts,
  currentOffice,
  dateRange,
  title,
  patientName,
  dob,
  mrn,
}: PatientAlertHistoryPDFProps) => (
  <Document title={title}>
    <PagePadding wrap>
      <PDFHeader
        currentOffice={currentOffice}
        dateRange={dateRange}
        title="Patient Alert History"
        patientName={patientName}
        dob={dob}
        mrn={mrn}
      />

      <Table>
        {/* Table Header Row */}
        <TableRow fixed>
          <TableColumn>
            <TableHeader>Addressed Time</TableHeader>
          </TableColumn>
          <TableColumn>
            <TableHeader>Patient</TableHeader>
          </TableColumn>
          <TableColumn>
            <TableHeader>Type</TableHeader>
          </TableColumn>
          <TableColumn>
            <TableHeader>Issue</TableHeader>
          </TableColumn>
          <TableColumn>
            <TableHeader>Measured At</TableHeader>
          </TableColumn>
          <TableColumn>
            <TableHeader>Limit Over</TableHeader>
          </TableColumn>
        </TableRow>

        {/* Table Data Rows */}
        {patientAlerts
          ? patientAlerts.map(alert => (
              <TableRow wrap={false} key={`${alert.patient}-${alert.timestamp}`}>
                <TableColumn>
                  <Body data-testid="pdf-row-timestamp">
                    {moment(alert.addressedTime).format('MM/DD/YYYY hh:mm A') || '-'}
                  </Body>
                </TableColumn>
                <TableColumn>
                  <Body>{alert.patient || '-'}</Body>
                </TableColumn>
                <TableColumn>
                  <Body>{alert.type || '-'}</Body>
                </TableColumn>
                <TableColumn>
                  <Body>{alert.issue || '-'}</Body>
                </TableColumn>
                <TableColumn>
                  <Body>{alert.measuredAt || '-'}</Body>
                </TableColumn>
                <TableColumn>
                  <Body>{alert.limitOver || '-'}</Body>
                </TableColumn>
              </TableRow>
            ))
          : null}
      </Table>
      <Footer fixed>
        <Body fixed>Patient Alert History</Body>
        <Body
          fixed
          render={({ pageNumber, totalPages }: { pageNumber: number; totalPages: number }) =>
            `${pageNumber} / ${totalPages}`
          }
        />
      </Footer>
    </PagePadding>
  </Document>
);

export default Wrapper;
