import React, { useState, useEffect } from 'react';
import { LogoutOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import Auth from '@aws-amplify/auth';
import { useCognitoUser } from 'hooks';
import Lottie from 'react-lottie';
import { UnaddressedAlertsProvider } from 'context';
import additarIcon from 'animations/additarIcon.json';
import NotificationButton from '../NotificationButton/NotificationButton';
import DataSelector from '../DataSelector/DataSelector';
import { Container, UserInfo, SignOutButton, Username, AvatarUsername } from './Header.styled';

const Header = () => {
  const cognitoUser = useCognitoUser();

  const [isAdditarIconHovered, setIsAdditarIconHoverd] = useState(false);

  useEffect(() => {
    if (isAdditarIconHovered) setTimeout(() => setIsAdditarIconHoverd(false), 1000);
  }, [isAdditarIconHovered]);

  return (
    <Container>
      <DataSelector />
      <UserInfo>
        <UnaddressedAlertsProvider>
          <NotificationButton />
        </UnaddressedAlertsProvider>
        <Dropdown
          overlay={
            <Menu>
              <SignOutButton
                onClick={async () => {
                  await Auth.signOut();
                  await window.location.reload(true);
                }}
              >
                &nbsp;
                <LogoutOutlined /> Logout
              </SignOutButton>
            </Menu>
          }
          placement="bottomRight"
        >
          <AvatarUsername onMouseEnter={() => setIsAdditarIconHoverd(true)}>
            <Lottie
              isStopped={!isAdditarIconHovered}
              options={{
                loop: false,
                autoplay: false,
                animationData: additarIcon,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid meet',
                },
              }}
              style={{
                display: 'flex',
                margin: 0,
                marginRight: '5px',
                marginTop: '6px',
              }}
              height={50}
              width={50}
            />
            &nbsp;
            <Username>{cognitoUser.data?.username}</Username>
          </AvatarUsername>
        </Dropdown>
      </UserInfo>
    </Container>
  );
};

export default Header;
