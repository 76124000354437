import ky from 'ky';
import moment from 'moment';
import _ from 'lodash';
import Auth from '@aws-amplify/auth';

type pullPatientAlerts = (
  key: string,
  args: {
    group: string | undefined;
    currentOffice: Office | undefined;
    isAddressed: string;
    currentPatient: Patient | undefined;
  },
) => Promise<Alert[]>;

const pullPatientAlerts: pullPatientAlerts = async (
  key,
  { group, currentOffice, isAddressed, currentPatient },
) => {
  if (!currentOffice || !currentPatient) return [];

  const {
    idToken: { jwtToken },
  } = await Auth.currentSession();

  const initialStartDate = moment()
    .utc()
    .add(1, 'd')
    .format();

  const initialEndDate = moment()
    .utc()
    .subtract(1, 'y')
    .format();

  const result = await ky
    .post('https://ski88oeab8.execute-api.us-east-1.amazonaws.com/prod', {
      json: {
        xmit_id: currentPatient?.xmit_id,
        carePlanId: group,
        officeId: currentOffice.officeName,
        startDate: initialStartDate,
        endDate: initialEndDate,
        token: jwtToken,
        isAddressed,
      },
    })
    .json<{
      url: string;
    }>();

  const alerts = await ky.get(result.url).json<Alert[]>();

  return _.orderBy(
    alerts.map(alert => ({
      ...alert,
      issue: alert.flag[0] === '{' ? '-' : alert.flag,
      patient: currentPatient?.customer_name || currentPatient?.xmit_id,
    })),
    (alert: Alert) => moment(alert.addressedTime).format(),
    ['desc'],
  );
};

export default pullPatientAlerts;
