import React from 'react';
import _ from 'lodash';
import ControlSubHeader from 'components/display/ControlSubHeader/ControlSubHeader';
import { useAddressedAlerts } from 'context/addressedAlerts';
import { ExportButton } from 'components/export';
import { OfficeAlertHistoryTable } from 'components/alerts';
import { useKinesis } from 'hooks';
import { AlertHistoryContainer } from './OfficeAlertHistory.styled';

const OfficeAlertHistory = () => {
  const { query } = useAddressedAlerts();
  useKinesis();

  return (
    <>
      <AlertHistoryContainer>
        <ControlSubHeader
          breadcrumbSections={['Office', 'Alert History']}
          isFetching={query.isFetching}
          isSuccess={query.isSuccess}
          refetch={[query.refetch]}
        >
          <ExportButton disabled={query.isLoading} />
        </ControlSubHeader>

        <OfficeAlertHistoryTable alerts={query} />
      </AlertHistoryContainer>
    </>
  );
};

export default OfficeAlertHistory;
