import React, { useMemo } from 'react';
import { useTable, Column, useBlockLayout } from 'react-table';
import { FixedSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import moment from 'moment';
import { vitalsTypeToIcon, vitalsTypeToPretty, vitalsTypeToUnit } from 'dictionary';
import { Body, SubHeader, LoadingIcon, EmptyIcon } from 'elements';
import { useThresholds } from 'context/thresholds';
import { useUnaddressedAlerts } from 'context/unaddressedAlerts';
import { useFilteredVitals, useRoutes } from 'hooks';
import { useVitals } from 'context/vitals';
import { useOfficeList } from 'context/offices';
import { usePatientList } from 'context/patients';
import convertToTableVitals from './utils/convertToTableVitals';
import {
  Container,
  TableRow,
  TableCell,
  Reading,
  Unit,
  Timestamp,
  Vital,
  ActiveAlertIcon,
  MiddleColumn,
  AddressedAlertIcon,
} from './VitalsTable.styled';

type VitalsTableProps = {
  dateRange: [moment.Moment, moment.Moment];
  tableSort: 'asc' | 'desc';
  isSelectAllChecked: boolean;
  checkedVitals: Vital[];
};

const VitalsTable = ({
  dateRange,
  tableSort,
  isSelectAllChecked,
  checkedVitals,
}: VitalsTableProps) => {
  const vitalsQuery = useVitals();
  const vitals = useFilteredVitals({ data: vitalsQuery.data, dateRange });
  const { pushRouteChange } = useRoutes();
  const thresholds = useThresholds();
  const {
    query: { data: activeAlerts },
  } = useUnaddressedAlerts();
  const tableVitals: TableVitalsReading[] = useMemo(
    () =>
      convertToTableVitals({
        vitals,
        thresholds,
        activeAlerts,
        tableSort,
        isSelectAllChecked,
        checkedVitals,
      }),
    [vitals, thresholds, activeAlerts, tableSort, isSelectAllChecked, checkedVitals],
  );
  const officeList = useOfficeList();
  const patientList = usePatientList();

  const columns: Column<TableVitalsReading>[] = useMemo(
    () => [
      {
        id: 'icon',
        Header: '',
        accessor: row => {
          const Component = vitalsTypeToIcon[row.type];

          return (
            <Vital>
              <Component />
              <Body noMargins data-testid="vitals-table-row-type">
                {row.type === 'systolic' ? 'Blood Pressure' : vitalsTypeToPretty[row.type]}
              </Body>
            </Vital>
          );
        },
      },
      {
        id: 'value',
        Header: '',
        accessor: row => (
          <MiddleColumn>
            <Reading>
              <SubHeader noMargins>{row.value}</SubHeader>
              <Unit noMargins>{vitalsTypeToUnit[row.type]}</Unit>
            </Reading>
            {(() => {
              if (row.alert === 'alert is addressed')
                return <AddressedAlertIcon data-testid="addressed-alert-icon" />;
              if (row.alert) return <ActiveAlertIcon data-testid="active-alert-icon" />;
              return null;
            })()}
          </MiddleColumn>
        ),
      },
      {
        id: 'timestamp',
        Header: '',
        defaultCanSort: true,
        accessor: row => (
          <Timestamp>
            <Body noMargins data-testid="row-timestamp-day">
              {moment(row.timestamp).format('dddd')}
            </Body>
            <Body noMargins data-testid="row-timestamp-date">
              {moment(row.timestamp).format('MMM DD, YYYY')}
            </Body>
            <Body noMargins data-testid="row-timestamp-time">
              {moment(row.timestamp).format('hh:mm a')}
            </Body>
          </Timestamp>
        ),
      },
    ],
    [],
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data: tableVitals,
    },
    useBlockLayout,
  );

  const RenderRow = React.useCallback(
    ({ index, style }) => {
      const row = rows[index];
      prepareRow(row);

      return (
        <TableRow
          {...row.getRowProps({
            style,
          })}
          onClick={
            row.original.alert && typeof row.original.alert !== 'string'
              ? () => {
                  const alert = row.original.alert;
                  if (typeof alert === 'string') return undefined;
                  pushRouteChange({
                    section: 'alert-details',
                    xmit: alert?.xmit_id,
                    timestamp: alert?.timestamp,
                  });
                }
              : undefined
          }
          data-testid="vitals-table-row"
        >
          {row.cells.map(cell => {
            return <TableCell {...cell.getCellProps()}>{cell.render('Cell')}</TableCell>;
          })}
        </TableRow>
      );
    },
    [prepareRow, pushRouteChange, rows],
  );

  if (vitalsQuery.isLoading || officeList.isLoading || patientList.isLoading) {
    return <LoadingIcon />;
  }

  if (tableVitals.length === 0) {
    return <EmptyIcon />;
  }

  // Render the UI for your table
  return (
    <Container>
      <AutoSizer>
        {({ height, width }) => (
          <table
            {...getTableProps()}
            style={{
              width,
              height,
            }}
          >
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                  ))}
                </tr>
              ))}
            </thead>

            <tbody {...getTableBodyProps()}>
              <FixedSizeList
                height={height - 24}
                itemCount={rows.length}
                itemSize={95}
                width={width}
              >
                {RenderRow}
              </FixedSizeList>
            </tbody>
          </table>
        )}
      </AutoSizer>
    </Container>
  );
};

export default VitalsTable;
