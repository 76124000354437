import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import Lottie from 'react-lottie';
import { useRoutes } from 'hooks';
import LogoWordOut from 'animations/LogoWordOut.json';
import officeIcon from 'animations/officeIcon.json';
import patientIcon from 'animations/patientIcon.json';
import LogoPulse from 'animations/LogoPulse.json';
import {
  SiderContainer,
  AnimatedIcon,
  AnimatedLogo,
  Menu,
  SubMenu,
  MenuItem,
} from './Sider.styled';

type SiderProps = {
  location: {
    pathname: string;
  };
};

const Sider = ({ location }: SiderProps) => {
  const { pushRouteChange } = useRoutes();
  const [collapsed, setCollapsed] = useState(false);
  const [isOfficeIconHovered, setIsOfficeIconHovered] = useState(false);
  const [isPatientIconHovered, setIsPatientIconHovered] = useState(false);
  const [isLogoPulseIconHovered, setIsLogoPulseIconHovered] = useState(false);

  return (
    <SiderContainer
      collapsible
      onCollapse={() => setCollapsed(!collapsed)}
      collapsed={collapsed}
      breakpoint="lg"
    >
      <Menu theme="dark" mode="inline">
        <a
          href="/"
          onMouseEnter={() => setIsLogoPulseIconHovered(true)}
          onMouseLeave={() => setIsLogoPulseIconHovered(false)}
        >
          <AnimatedLogo>
            <Lottie
              isStopped={!isLogoPulseIconHovered}
              options={{
                loop: true,
                autoplay: false,
                animationData: LogoPulse,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid meet',
                },
              }}
              width={50}
              height={50}
              style={{
                display: 'flex',
                margin: 0,
                width: '42.71px',
                height: '50',
              }}
              direction={collapsed ? 1 : -1}
            />
            <Lottie
              options={{
                loop: false,
                autoplay: false,
                animationData: LogoWordOut,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid meet',
                },
              }}
              width={130}
              height={50}
              style={{
                display: 'flex',
                margin: 0,
                marginLeft: '-6px',
              }}
              direction={collapsed ? 1 : -1}
            />
          </AnimatedLogo>
        </a>
        <SubMenu
          key="sub1"
          onTitleMouseEnter={() => setIsOfficeIconHovered(true)}
          onTitleMouseLeave={() => setIsOfficeIconHovered(false)}
          title={
            <AnimatedIcon>
              <span>
                <Lottie
                  isStopped={!isOfficeIconHovered}
                  options={{
                    loop: true,
                    autoplay: false,
                    animationData: officeIcon,
                    rendererSettings: {
                      preserveAspectRatio: 'xMidYMid meet',
                    },
                  }}
                  style={{
                    display: 'flex',
                    margin: 0,
                  }}
                  height={28}
                  width={28}
                />
              </span>
              {!collapsed && <span style={{ marginLeft: '15px' }}>Office</span>}
            </AnimatedIcon>
          }
        >
          <MenuItem
            path="/office-active-alerts"
            currentLocation={location.pathname}
            onClick={() => pushRouteChange({ section: 'office-active-alerts' })}
          >
            Active Alerts
          </MenuItem>
          <MenuItem
            path="/office-alert-history"
            currentLocation={location.pathname}
            onClick={() => pushRouteChange({ section: 'office-alert-history' })}
          >
            Alert History
          </MenuItem>
        </SubMenu>
        <SubMenu
          key="sub2"
          onTitleMouseEnter={() => setIsPatientIconHovered(true)}
          onTitleMouseLeave={() => setIsPatientIconHovered(false)}
          title={
            <AnimatedIcon>
              <span>
                <Lottie
                  isStopped={!isPatientIconHovered}
                  options={{
                    loop: true,
                    autoplay: false,
                    animationData: patientIcon,
                    rendererSettings: {
                      preserveAspectRatio: 'xMidYMid meet',
                    },
                  }}
                  style={{
                    display: 'flex',
                    margin: 0,
                  }}
                  height={28}
                  width={28}
                />
              </span>
              {!collapsed && <span style={{ marginLeft: '15px' }}>Patient</span>}
            </AnimatedIcon>
          }
        >
          <MenuItem
            path="/patient-vitals-history"
            currentLocation={location.pathname}
            onClick={() => pushRouteChange({ section: 'patient-vitals-history' })}
          >
            Vitals History
          </MenuItem>
          <MenuItem
            path="/patient-alert-history"
            currentLocation={location.pathname}
            onClick={() => pushRouteChange({ section: 'patient-alert-history' })}
          >
            Alert History
          </MenuItem>
          <MenuItem
            path="/patient-thresholds"
            currentLocation={location.pathname}
            onClick={() => pushRouteChange({ section: 'patient-thresholds' })}
          >
            Thresholds
          </MenuItem>
        </SubMenu>
      </Menu>
    </SiderContainer>
  );
};

export default withRouter(Sider);
