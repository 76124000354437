import { find } from 'lodash';

import { alertTypes } from 'dictionary';

/* --- Custom Alert Views --- */
import VitalsReading from '../alertDetailViews/VitalsReading/VitalsReading';
import GenericAlert from '../alertDetailViews/GenericAlert/GenericAlert';
import Survey from '../alertDetailViews/Survey/Survey';

const customDetailViews = {
  [alertTypes.generic]: GenericAlert,
  [alertTypes.Survey]: Survey,
  [alertTypes['Survey Results']]: Survey,
  [alertTypes['Survey Alert']]: Survey,
  [alertTypes.BPM]: VitalsReading,
  [alertTypes.Glucose]: VitalsReading,
  [alertTypes.Oxygen]: VitalsReading,
  [alertTypes.Diastolic]: VitalsReading,
  [alertTypes.Systolic]: VitalsReading,
  [alertTypes.Fev1]: VitalsReading,
  [alertTypes.Pef]: VitalsReading,
};
const alertViews = Object.values(alertTypes).reduce((acc: any, type: any) => {
  acc[type] = customDetailViews[type] || customDetailViews[alertTypes.generic];
  return acc;
}, {});

export const getDetailView = (alertType: string) =>
  alertViews[alertType] || alertViews[alertTypes.generic];

const getAlertList = (state: any) => state.user.alerts;
// eslint-disable-next-line camelcase
export const getAlert = (state: any, xmit_id: string, timestamp: string) =>
  find(getAlertList(state), { xmit_id, timestamp });

export const isAddressed = (alert: Alert) => alert && alert.addressed === 'true';
export const getAlertType = (alert: Alert) => alert && alert.type;
export const getOfficeId = (alert: Alert) => alert && alert.officeId;
