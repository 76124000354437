import styled from 'styled-components';
import { Body, Header } from 'elements';

export const Grid = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;

  @media (max-width: 1700px) {
    grid-auto-flow: row;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
  }

  @media (max-width: 1200px) {
    grid-auto-flow: row;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
`;

// noBorder will disable the border regardless of media size
// noDesktopBorder will only disable the border for non-mobile media sizes
// noMobileBorder will only disable the border for mobile media sizes
export const Item = styled.div<{
  noBorder?: boolean;
  noDesktopBorder?: boolean;
  noMobileBorder?: boolean;
}>`
  display: flex;
  flex-direction: row;
  border-bottom: none;
  padding: 4px 0;

  @media (max-width: 1700px) {
    border-bottom: ${props =>
      props.noBorder || props.noDesktopBorder ? 'none' : '1px rgba(0, 0, 0, 0.2) solid'};
  }

  @media (max-width: 1200px) {
    border-bottom: ${props =>
      props.noBorder || props.noMobileBorder ? 'none' : '1px rgba(0, 0, 0, 0.2) solid'};
  }
`;

export const PatientName = styled(Header)`
  margin: 0;
  margin-bottom: 0.4rem;
`;

export const Label = styled(Body)`
  color: rgba(0, 0, 0, 0.5);
  margin: 0;
  margin-right: 0.4rem;
`;

export const Value = styled.span`
  opacity: 1;
  color: ${props => props.theme.primary3};
  margin: 0;
`;
