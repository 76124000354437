import React, { useContext } from 'react';
import { QueryResult, useQuery } from 'react-query';
import _ from 'lodash';
import { useCognitoUser } from 'hooks';
import { useCurrentOffice } from 'context/offices';
import { usePatientList } from 'context/patients';
import { pullAddressedAlerts } from './utils';
import { useHistory } from 'react-router-dom';
import { captureError } from 'utils';

type AddressedAlertsContext = QueryResult<Alert[], Error>;

export const AddressedAlertsContext = React.createContext<AddressedAlertsContext | undefined>(
  undefined,
);

type AddressedAlertsProviderProps = {
  children: React.ReactNode;
};

//addressed alerts provider
export const AddressedAlertsProvider = ({ children }: AddressedAlertsProviderProps) => {
  const history = useHistory();
  const currentOffice = useCurrentOffice();
  const patientList = usePatientList();
  const { data: cognitoUser } = useCognitoUser();
  const query = useQuery(
    [
      'pullAddressedAlerts',
      {
        isAddressed: 'true',
        patientList: patientList.data || [],
      },
    ],
    (key, args) => pullAddressedAlerts(key, { ...args, group: cognitoUser?.group, currentOffice }),
    {
      staleTime: 0,
      enabled: currentOffice && patientList.isSuccess,
      onError: error => {
        captureError({ error });
      },
    },
  );
  return (
    <AddressedAlertsContext.Provider value={query}>{children}</AddressedAlertsContext.Provider>
  );
};

export const useAddressedAlerts = () => {
  const context = useContext(AddressedAlertsContext);

  if (context === undefined) {
    throw new Error('useUnaddressedAlerts must be used within a AlertsProvider');
  }

  return {
    query: context,
  };
};
