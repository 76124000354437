export default `query getPatientList(
  $Office: String!
  $officeCode: String!
){
  getPatientListData(Office: $Office, officeCode:$officeCode){
    xmit_id
    id
    office
    MRNum
    lead_id
    customer_name
    created_at
    date_of_birth
    doctor_name
    doctor_phone
    rp1_name
    rp1_relationship
    rp1_phone
    rp2_name
    rp2_relationship
    rp2_phone
    rp3_name
    rp3_relationship
    rp3_phone
    rp4_name
    rp4_relationship
    rp4_phone
    customer_phone
    updated_at
  }
}`;
