import ky from 'ky';
import moment from 'moment';
import _ from 'lodash';
import Auth from '@aws-amplify/auth';

type pullUnaddressedAlerts = (
  key: string,
  args: {
    group: string | undefined;
    currentOffice: Office | undefined;
    isAddressed: string;
    patientList: Patient[];
  },
) => Promise<Alert[]>;

const pullUnaddressedAlerts: pullUnaddressedAlerts = async (
  key,
  { group, currentOffice, isAddressed, patientList = [] },
) => {
  if (!currentOffice) return [];

  const {
    idToken: { jwtToken },
  } = await Auth.currentSession();

  const initialStartDate = moment()
    .utc()
    .add(1, 'd')
    .format();

  const initialEndDate = moment()
    .utc()
    .subtract(1, 'y')
    .format();

  const result = await ky
    .post('https://a76yqkvwq0.execute-api.us-east-1.amazonaws.com/Prod', {
      json: {
        carePlanId: group,
        officeId: currentOffice.officeName,
        startDate: initialStartDate,
        endDate: initialEndDate,
        token: jwtToken,
        isAddressed,
      },
    })
    .json<{
      url: string;
    }>();

  const alerts = await ky.get(result.url).json<Alert[]>();

  // filter out alerts that don't match with a patient in patientList
  const filterAlerts = alerts.filter(alert => {
    if (patientList?.find(patientInfo => patientInfo.xmit_id === alert.xmit_id)?.customer_name)
      return alert;
  });

  const orderedAlerts = _.orderBy(
    filterAlerts.map(alert => ({
      ...alert,
      issue: alert.flag[0] === '{' ? '-' : alert.flag,
      patient:
        patientList?.find(patientInfo => patientInfo.xmit_id === alert.xmit_id)?.customer_name ||
        alert.xmit_id,
    })),
    ['timestamp'],
    ['desc'],
  );
  return orderedAlerts;
};

export default pullUnaddressedAlerts;
