const alertTypes = {
  generic: 'generic',
  Survey: 'Survey',
  'Survey Results': 'Survey Results',
  'Survey Alert': 'Survey Alert',
  BPM: 'BPM',
  Glucose: 'Glucose',
  Oxygen: 'Oxygen',
  Diastolic: 'Diastolic',
  Systolic: 'Systolic',
  Fev1: 'Fev1',
  Pef: 'Pef',
};

export default alertTypes;
