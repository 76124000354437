import ky from 'ky';

type pullPatientThresholds = (
  key: string,
  args: { currentPatient?: Patient },
) => Promise<PatientThresholds>;

const pullPatientThresholds: pullPatientThresholds = async (key, { currentPatient }) => {
  if (!currentPatient)
    return {
      oxygen: {
        min: null,
        max: null,
      },
      diastolic: {
        min: null,
        max: null,
      },
      fev1: {
        min: null,
        max: null,
      },
      glucose: {
        min: null,
        max: null,
      },
      heartRate: {
        min: null,
        max: null,
      },
      pef: {
        min: null,
        max: null,
      },
      systolic: {
        min: null,
        max: null,
      },
      temperature: {
        min: null,
        max: null,
      },
      weight: {
        min: null,
        max: null,
      },
    };

  // get vitals and thresholds data from dynamo
  const thresholdResult = await ky
    .post('https://o6dy65bp92.execute-api.us-east-1.amazonaws.com/prod', {
      json: { xmitId: currentPatient.xmit_id },
    })
    .json<any>();

  // convert from Anelto's vitals format to our vitals format
  const patientThresholds: PatientThresholds = {
    heartRate: {
      min:
        +thresholdResult.Items.find((item: any) => item.vitalName.S === 'BPM')?.minimum?.N || null,
      max:
        +thresholdResult.Items.find((item: any) => item.vitalName.S === 'BPM')?.maximum?.N || null,
    },
    oxygen: {
      min:
        +thresholdResult.Items.find((item: any) => item.vitalName.S === 'Oxygen')?.minimum?.N ||
        null,
      max:
        +thresholdResult.Items.find((item: any) => item.vitalName.S === 'Oxygen')?.maximum?.N ||
        null,
    },
    glucose: {
      min:
        +thresholdResult.Items.find((item: any) => item.vitalName.S === 'Glucose')?.minimum?.N ||
        null,
      max:
        +thresholdResult.Items.find((item: any) => item.vitalName.S === 'Glucose')?.maximum?.N ||
        null,
    },
    temperature: {
      min:
        +thresholdResult.Items.find((item: any) => item.vitalName.S === 'Thermometer')?.minimum
          ?.N || null,
      max:
        +thresholdResult.Items.find((item: any) => item.vitalName.S === 'Thermometer')?.maximum
          ?.N || null,
    },
    weight: {
      min:
        +thresholdResult.Items.find((item: any) => item.vitalName.S === 'Weight')?.minimum?.N ||
        null,
      max:
        +thresholdResult.Items.find((item: any) => item.vitalName.S === 'Weight')?.maximum?.N ||
        null,
    },
    systolic: {
      min:
        +thresholdResult.Items.find((item: any) => item.vitalName.S === 'Systolic')?.minimum?.N ||
        null,
      max:
        +thresholdResult.Items.find((item: any) => item.vitalName.S === 'Systolic')?.maximum?.N ||
        null,
    },
    diastolic: {
      min:
        +thresholdResult.Items.find((item: any) => item.vitalName.S === 'Diastolic')?.minimum?.N ||
        null,
      max:
        +thresholdResult.Items.find((item: any) => item.vitalName.S === 'Diastolic')?.maximum?.N ||
        null,
    },
    fev1: {
      min:
        +thresholdResult.Items.find((item: any) => item.vitalName.S === 'Fev1')?.minimum?.N || null,
      max:
        +thresholdResult.Items.find((item: any) => item.vitalName.S === 'Fev1')?.maximum?.N || null,
    },
    pef: {
      min:
        +thresholdResult.Items.find((item: any) => item.vitalName.S === 'Pef')?.minimum?.N || null,
      max:
        +thresholdResult.Items.find((item: any) => item.vitalName.S === 'Pef')?.minimum?.N || null,
    },
  };

  return patientThresholds;
};

export default pullPatientThresholds;
