import React from 'react';
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { aneltoToVitalsType, vitalsTypeToUnit, vitalsTypeToPretty } from 'dictionary';

export const getMaxMin = (state: any, reading: AneltoVital) => {
  const patThresholds = state.patientThresholds;
  const groupThresholds = state.groupThresholds;
  const vitalsType: Vital = aneltoToVitalsType[reading];

  const { max: patMax, min: patMin } = patThresholds[vitalsType];
  const { max: groupMax, min: groupMin } = groupThresholds[vitalsType];
  return [parseInt(patMax || groupMax, 10), parseInt(patMin || groupMin, 10)];
};

export const getUnit = (reading: AneltoVital) => {
  const vitalsType: Vital = aneltoToVitalsType[reading];

  return vitalsTypeToUnit[vitalsType];
};

const getTitle = (reading: AneltoVital) => {
  const vitalsType: Vital = aneltoToVitalsType[reading];

  return vitalsTypeToPretty[vitalsType];
};
const getArrow = (problem: any) =>
  // eslint-disable-next-line no-nested-ternary
  problem === 'Over Maximum'
    ? () => <ArrowUpOutlined />
    : problem === 'Below Minimum'
    ? () => <ArrowDownOutlined />
    : () => <></>;

export const getTableData = ({ type, flag }: any) => {
  const unit = getUnit(type);
  const title = getTitle(type) || 'Measurement';
  const Arrow = getArrow(flag);
  const formatter = (val: any) => `${val} ${unit}`;
  return { unit, title, Arrow, formatter };
};
