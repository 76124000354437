import { useSpring } from 'react-spring';
import { useMotionPreference } from 'web-api-hooks';

export default function usePageSlide(slide = `translate3d(0,10px,0)`) {
  const prefersReducedMotion = useMotionPreference() === 'reduce' || window.name === 'nodejs';
  const animatedStyles = useSpring({
    opacity: 1,
    transform: `translate3d(0,0px,0)`,
    from: {
      opacity: 0,
      transform: slide,
    },
    immediate: prefersReducedMotion,
  });
  return animatedStyles;
}
