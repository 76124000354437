import { words } from 'lodash';

const routeResolve: any = {
  patient: [true, true],
  office: [true, false],
};

const trimPath = (path: any) => {
  const pathWords = words(path);
  return ((Boolean(pathWords) && pathWords) || [null])[0];
};

const getSelectors = (pathname: any) => {
  const routeCategory = trimPath(pathname) as any;
  return routeResolve[routeCategory] || [false, false];
};

export default getSelectors;
