/* eslint-disable import/prefer-default-export */

import styled from 'styled-components';

export const SurveyRenderer = styled.div`
  width: 100%;
  height: 100%;
`;
export const QuestionList = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
`;
export const QuestionItem = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
`;
export const Number = styled.div<{ danger: any }>`
  margin: 0.75rem;
  font-size: 32px;
  font-weight: lighter;
  color: ${({ danger, theme }) => (danger ? theme.danger2 : 'black')};
`;
export const SurveySubtitle = styled.h4`
  @media (max-width: 520px) {
    font-size: 24px;
  }
  @media (min-width: 521px) {
    font-size: 32px;
  }
  color: ${({ theme }) => theme.danger2};
  font-size: 32px;
  font-weight: 600;
  text-align: center;
  margin: 0px;
`;
export const SurveyTitle = styled.h3`
  @media (max-width: 520px) {
    font-size: 32px;
  }
  @media (min-width: 521px) {
    font-size: 40px;
  }
  font-size: 40px;
  font-weight: bolder;
  text-align: center;
  margin: 0px;
`;
export const Title = styled.h3<{ danger: any }>`
  color: ${({ theme, danger }) => (danger ? theme.danger2 : 'black')};
  display: block;
  margin: 0px;
`;
export const LowerBody = styled.div<{ danger: any }>`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-end;
  color: ${({ theme, danger }) => (danger ? theme.danger2 : 'black')};
`;
export const Answer = styled.p`
  color: inherit;
  font-size: 24px;
  font-weight: 800;
  margin: 0px;
  line-height: 1.25;
`;
export const Duration = styled.p<{ danger: any }>`
  color: ${({ theme, danger }) => (danger ? theme.danger1 : 'rgba(0,0,0,.5)')};
  margin: 0px;
  line-height: 1.25;
`;
export const Body = styled.div<{ danger: any }>`
  padding: 8px 16px 9px 12px;
  margin: 8px;
  border-radius: ${({ theme }) => theme.borderRadius};
  border: 1px ${({ danger, theme }) => (danger ? theme.danger2 : 'rgba(0,0,0,0.15)')} solid;
  flex-grow: 2;
`;
