import styled from 'styled-components';

export const VerticalGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 15px;

  @media (max-width: 1370px) {
    grid-template-columns: 1fr;
  }
`;

export const HorizontalGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;

  @media (max-width: 1370px) {
    grid-template-columns: 1fr;
  }
`;
