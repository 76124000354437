import React from 'react';
import style from 'styled-components';
import { animated } from 'react-spring';

export const DataSelectorContainer = style.div`
  display: flex;
  flex-grow: 2;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 832px;
  line-height: 45px;
`;

export const OfficeDropdownContainer = style(animated.div)`
  width: calc(50% - 8px);
  max-width: 400px;
  min-width: 150px;
  margin: 0px 4px 0px 4px;
  @media (max-width: 800px) {
    width: 100%;
  }
`;

export const PatientListDropdownContainer = style(animated.div)`
  width: calc(50% - 8px);
  max-width: 400px;
  min-width: 150px;
  margin: 0px 4px 0px 4px;
  @media (max-width: 800px) {
    width: 100%;
  }
`;
