import React from 'react';
import { Button } from 'elements';
import { CSVLink } from 'react-csv';
import { useFilteredAlerts } from 'hooks';
import moment from 'moment';
import { useCurrentOffice } from 'context/offices';
import { useUnaddressedAlerts } from 'context/unaddressedAlerts';

type OfficeActiveAlertsCSVProps = {
  dateRange: [moment.Moment, moment.Moment];
};

const OfficeActiveAlertsCSV = ({ dateRange }: OfficeActiveAlertsCSVProps) => {
  const { query } = useUnaddressedAlerts();
  const currentOffice = useCurrentOffice();
  const activeAlerts = useFilteredAlerts({ data: query.data || [], dateRange });
  const title = `${currentOffice?.description} Office Active Alerts ${moment().format(
    'MM-DD-YYYY',
  )}`;

  const headers = [
    { label: 'Timestamp', key: 'timestamp' },
    { label: 'Patient', key: 'patient' },
    { label: 'Type', key: 'type' },
    { label: 'Issue', key: 'issue' },
    { label: 'Measured At', key: 'measuredAt' },
    { label: 'Limit Over', key: 'limitOver' },
  ];

  return (
    <CSVLink data={activeAlerts} headers={headers} filename={`${title}.csv`} target="_blank">
      <Button>Export</Button>
    </CSVLink>
  );
};

export default OfficeActiveAlertsCSV;
