import React from 'react';
import { useThresholds } from 'context/thresholds';
import { LoadingIcon } from 'elements';
import { getTableData, getMaxMin } from './selectors/selectors';
import {
  VitalsReadingTitle,
  VitalsReadingTable,
  VitalsTBody,
  VitalsReadingTableRow,
  VitalsTableHeader,
  VitalsTableBody,
  VitalsTableHeaderBad,
  VitalsTableBodyBad,
} from './VitalsReading.styled';

const NBSP = () => <span dangerouslySetInnerHTML={{ __html: '&nbsp;' }} />;

type VitalsReadingProps = {
  alert: Alert;
};

const VitalsReading = ({ alert }: VitalsReadingProps) => {
  const {
    groupThresholds: { data: groupThresholds, isLoading: isLoadingGroupThresholds },
    patientThresholds: { data: patientThresholds, isLoading: isLoadingPatientThresholds },
  } = useThresholds();

  if (isLoadingGroupThresholds || isLoadingPatientThresholds) {
    return <LoadingIcon />;
  }

  const { type, measuredAt, flag, limitOver }: any = alert;

  const [max, min] = getMaxMin({ groupThresholds, patientThresholds }, type);

  const { unit, formatter, Arrow, title } = getTableData({
    type,
    flag,
  });

  return (
    <>
      <VitalsReadingTitle>{title}</VitalsReadingTitle>
      <VitalsReadingTable>
        <VitalsTBody>
          <VitalsReadingTableRow>
            <VitalsTableHeader>Threshold Range: </VitalsTableHeader>
            <VitalsTableBody>
              {min || 'N/A '}-{max || ' N/A'}
              <NBSP />
              {unit}
            </VitalsTableBody>
          </VitalsReadingTableRow>
          <VitalsReadingTableRow>
            <VitalsTableHeader>Measured At: </VitalsTableHeader>
            <VitalsTableBody>{formatter(measuredAt)}</VitalsTableBody>
          </VitalsReadingTableRow>
          <VitalsReadingTableRow>
            <VitalsTableHeaderBad>{flag}:</VitalsTableHeaderBad>
            <VitalsTableBodyBad>
              {limitOver}
              <NBSP />
              {unit}
              <NBSP />
              <Arrow />
            </VitalsTableBodyBad>
          </VitalsReadingTableRow>
        </VitalsTBody>
      </VitalsReadingTable>
    </>
  );
};

export default VitalsReading;
