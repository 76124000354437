import { find } from 'lodash';

const getXmit = (alert: Alert) => alert.xmit_id;
const getPatient = (patientList: Patient[], alert: Alert) =>
  find(patientList, ['xmit_id', getXmit(alert)]);
const notEmpty = ({ name = '', relationship = '', phone = '' }: any) =>
  Boolean(name || relationship || phone);
export const formatPhone = (phone: any) => {
  if (!phone) return '';
  const digits = phone.split('').filter((i: any) => Number.isInteger(parseInt(i, 10)));
  if (digits.length !== 10) return phone;
  const [a, b, c, d, e, f, g, h, i, j] = digits;
  return `(${a}${b}${c}) ${d}${e}${f}-${g}${h}${i}${j}`;
};
export const getResponsibleParties = (patientList: Patient[] = [], alert: any = {}) => {
  const patient: any = getPatient(patientList, alert) || {};
  const rps = ['rp1', 'rp2', 'rp3', 'rp4'];
  return rps
    .map((rp: any) => ({
      name: patient[`${rp}_name`],
      relationship: patient[`${rp}_relationship`],
      phone: formatPhone(patient[`${rp}_phone`]),
    }))
    .filter(rp => notEmpty(rp));
};
