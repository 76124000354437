type aneltoToVitalsType = {
  [key in AneltoVital]: Vital;
};

const aneltoToVitalsType: aneltoToVitalsType = {
  BPM: 'heartRate',
  Oxygen: 'oxygen',
  Glucose: 'glucose',
  Thermometer: 'temperature',
  Weight: 'weight',
  Systolic: 'systolic',
  Diastolic: 'diastolic',
  Fev1: 'fev1',
  Pef: 'pef',
};

export default aneltoToVitalsType;
