const vitalsTypes: Vital[] = [
  'heartRate',
  'oxygen',
  'glucose',
  'temperature',
  'weight',
  'systolic',
  'diastolic',
  'fev1',
  'pef',
];

export default vitalsTypes;
