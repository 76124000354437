import React from 'react';
import { Container } from './LoadingIcon.styled';
import './LoadingIcon.css';

const LoadingIcon = () => (
  <Container>
    <div data-testid="loading-icon" className="la-ball-fall">
      <div />
      <div /> <div />
    </div>
  </Container>
);

export default LoadingIcon;
