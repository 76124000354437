import React, { useEffect } from 'react';
import Auth from '@aws-amplify/auth';
import setupLogRocketReact from 'logrocket-react';
import LogRocket from 'logrocket';
import * as Sentry from '@sentry/react';
import { version } from '../../../package.json';

const useErrorLogging = () => {
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      LogRocket.init('guk0cx/arpmcare');

      setupLogRocketReact(LogRocket);

      Sentry.init({
        dsn: 'https://1efa64861e044b8cbe4a1d6a8209f4f9@o364041.ingest.sentry.io/5217125',
        release: `clinical-dashboard@${version}`,
      });

      LogRocket.getSessionURL(sessionURL => {
        Sentry.configureScope(scope => {
          scope.setExtra('sessionURL', sessionURL);
        });
      });
    }
    Auth.currentSession().then(({ idToken: { payload } }) => {
      LogRocket.identify(payload['cognito:username'], {
        email: payload.email,
        group: payload['cognito:groups']?.[0],
        name: payload['cognito:username'],
      });
    });
    Auth.currentSession().then(({ idToken: { payload } }) => {
      Sentry.setUser({
        username: payload['cognito:username'],
        email: payload.email,
        group: payload['cognito:groups']?.[0],
      });
    });
  }, []);
};

export default useErrorLogging;
