import styled from 'styled-components';
import { Empty as E } from 'antd';
import { ArrowDownOutlined as ArrowDown, ArrowUpOutlined as ArrowUp } from '@ant-design/icons';

export const PatientThresholdsContainer = styled.div`
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 32px;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const PatientThresholdsEmptyContainer = styled.div`
  font-size: 20;
  line-height: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding: 16px;
  height: 100%;
`;

export const TableHeadHead = styled.h2`
  color: ${props => props.theme.textLight};
`;

export const OverriddenThreshold = styled.span<{ renamedPatMin?: any; renamedPatMax?: any }>`
  text-decoration: ${({ renamedPatMin, renamedPatMax }) =>
    renamedPatMin ? 'line-through' : renamedPatMax ? 'line-through' : ''};
  color: ${({ renamedPatMin, renamedPatMax }) =>
    renamedPatMin ? 'rgba(0, 0, 0, 0.35)' : renamedPatMax ? 'rgba(0, 0, 0, 0.35)' : ''};
`;

export const ArrowDownOutlined = styled(ArrowDown)`
  margin-right: 11px;
`;

export const ArrowUpOutlined = styled(ArrowUp)`
  margin-right: 11px;
`;

export const Empty = styled(E)``;

export const ThresholdsInput = styled.input`
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  width: 30%;
  height: 32px;
  padding: 4px 11px;
  color: black;
  font-size: 14px;
  line-height: 1.5;
  background-image: none;
  border: 1px #d9d9d9;
  border-radius: 4px 4px 0px 0px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border-bottom: 1px #094074 solid;
  text-align: start;

  @media (max-width: 1370px) {
    width: 70%;
  }

  @media (max-width: 800px) {
    width: 100%;
  }

  &:disabled {
    background: white;
    border-radius: 4px;
    border-bottom: 0px;
  }
`;
