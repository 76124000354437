import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { Button, LoadingIcon, EmptyIcon } from 'elements';
import { useAlertMutations } from 'hooks';
import useNotesFade from './utils/useNotesFade/useNotesFade';
import {
  NotesFieldContainer,
  TextBoxLabel,
  TextBox,
  NoteTextArea,
  AddressedText,
  Footer,
  FooterText,
  ButtonsRow,
  AddressedNotesField,
  Loading,
} from './NotesField.styled';

type NotesFieldProps = {
  data: Alert;
  isFetching: boolean;
};

const NotesField = ({ data, isFetching }: NotesFieldProps) => {
  const [note, setNote] = useState<string>('');
  const [divStyles, buttonStyles] = useNotesFade();
  const history = useHistory();

  const {
    addressAlert: [addressAlert, { isError, isLoading: isAddressing, error, reset, isSuccess }],
  } = useAlertMutations();

  if (isError) {
    throw error;
  }
  if (isSuccess) {
    reset();
    history.goBack();
  }

  if (!data?.timestamp) {
    return <EmptyIcon />;
  }

  if (data.addressed === 'true')
    return (
      <NotesFieldContainer style={divStyles}>
        <TextBoxLabel>Note</TextBoxLabel>
        <AddressedNotesField noNotes={!data.notes}>
          {isFetching ? (
            <Loading>
              <LoadingIcon />
            </Loading>
          ) : (
            <AddressedText>
              {data.notes ? data.notes : 'There are no notes for this alert'}
            </AddressedText>
          )}
        </AddressedNotesField>
        {isFetching ? null : (
          <Footer style={buttonStyles}>
            <FooterText>
              Addressed {moment(data.addressedTime).format('MMMM D, YYYY [at] h:mm A')}
            </FooterText>
            <Button type="link" onClick={() => history.goBack()}>
              Cancel
            </Button>
          </Footer>
        )}
      </NotesFieldContainer>
    );

  return (
    <NotesFieldContainer style={divStyles}>
      <TextBox htmlFor="notes-field-input">Add Note</TextBox>
      {isFetching ? (
        <LoadingIcon />
      ) : (
        <NoteTextArea
          id="notes-field-input"
          rows={4}
          value={note}
          onChange={evt => setNote(evt.target.value)}
        />
      )}
      {isFetching ? null : (
        <ButtonsRow>
          <Button
            onClick={() => {
              addressAlert({ unaddressedAlert: data, notes: note });
            }}
            disabled={!data}
            loading={isAddressing}
            type="primary"
          >
            Address Alert
          </Button>
          &nbsp;
          <Button type="link" disabled={isAddressing} onClick={() => history.goBack()}>
            Cancel
          </Button>
        </ButtonsRow>
      )}
    </NotesFieldContainer>
  );
};

export default NotesField;
