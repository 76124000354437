import styled from 'styled-components';
import { Body } from 'elements';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 3rem;
`;

export const Settings = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
`;

export const Actions = styled.div`
  display: flex;
  grid-template-columns: auto auto;
  grid-gap: 0.5rem;
`;

export const Label = styled(Body)`
  font-size: 0.8rem;
  opacity: 0.7;
  margin: 0 0 0 0.4rem;
`;
