import React from 'react';
import { ReactComponent as bloodPressureIcon } from 'img/Icon_bloodPressure.svg';
import { ReactComponent as glucometerIcon } from 'img/Icon_glucometer.svg';
import { ReactComponent as pulseOxIcon } from 'img/Icon_pulseOx.svg';
import { ReactComponent as spirometerIcon } from 'img/Icon_spirometer.svg';
import { ReactComponent as temperatureIcon } from 'img/Icon_temperature.svg';
import { ReactComponent as weightIcon } from 'img/Icon_weight.svg';

import Icon from '@ant-design/icons';
import styled from 'styled-components';

type vitalsTypeToIcon = {
  [key in Vital]: React.FC;
};

const VitalsIcon = styled(Icon)`
  font-size: 50px;
  margin-right: 1.5rem;
`;

const vitalsTypeToIcon: vitalsTypeToIcon = {
  heartRate: () => <VitalsIcon component={pulseOxIcon} />,
  oxygen: () => <VitalsIcon component={pulseOxIcon} />,
  glucose: () => <VitalsIcon component={glucometerIcon} />,
  temperature: () => <VitalsIcon component={temperatureIcon} />,
  weight: () => <VitalsIcon component={weightIcon} />,
  systolic: () => <VitalsIcon component={bloodPressureIcon} />,
  diastolic: () => <VitalsIcon component={bloodPressureIcon} />,
  fev1: () => <VitalsIcon component={spirometerIcon} />,
  pef: () => <VitalsIcon component={spirometerIcon} />,
};

export default vitalsTypeToIcon;
