import React, { useRef, useEffect } from 'react';
import { VitalsChartCard } from 'animations/framer-motion';
import { LoadingIcon, Unauthorized } from 'elements';
import { QueryResult } from 'react-query';
import { useVitals } from 'context/vitals';
import { useOfficeList } from 'context/offices';
import { usePatientList } from 'context/patients';
import { useVitalsChartScreenshots } from 'context/vitalsChartScreenshots';
import VitalsChart from '../VitalsChart/VitalsChart';
import BloodPressureChart from '../BloodPressureChart/BloodPressureChart';
import { useUnauthorized } from 'hooks';
import { VerticalGrid, HorizontalGrid } from './VitalsGrid.styled';
import { useVitalsChartRefs } from '../__hooks__';

type VitalsGridProps = {
  dateRange: [moment.Moment, moment.Moment];
  patientThresholds: QueryResult<PatientThresholds, Error>;
  groupThresholds: QueryResult<GroupThresholds, Error>;
};

const VitalsGrid = ({ dateRange, patientThresholds, groupThresholds }: VitalsGridProps) => {
  const { isAuthorized } = useUnauthorized();
  const refs = useVitalsChartRefs();
  const vitalsQuery = useVitals();
  const officeList = useOfficeList();
  const patientList = usePatientList();
  const [, setVitalsChartScreenshots] = useVitalsChartScreenshots();

  useEffect(() => {
    if (!vitalsQuery.isFetching) setVitalsChartScreenshots(refs);
  }, [vitalsQuery.isFetching]);

  if (vitalsQuery.isLoading || officeList.isLoading || patientList.isLoading) {
    return <LoadingIcon />;
  }
  if (!isAuthorized) return <Unauthorized />;

  return (
    <VerticalGrid>
      <HorizontalGrid>
        <VitalsChartCard key="heartRate-chart" delay={0}>
          <VitalsChart
            id="heartRate-chart"
            vitalType="heartRate"
            dateRange={dateRange}
            ref={refs.heartRate}
            patientMinThreshold={patientThresholds.data?.heartRate.min}
            patientMaxThreshold={patientThresholds.data?.heartRate.max}
            groupMinThreshold={groupThresholds.data?.heartRate.min}
            groupMaxThreshold={groupThresholds.data?.heartRate.max}
          />
        </VitalsChartCard>
        <VitalsChartCard key="oxygen-chart" delay={0.1}>
          <VitalsChart
            id="oxygen-chart"
            vitalType="oxygen"
            dateRange={dateRange}
            dataMax={100}
            ref={refs.oxygen}
            patientMinThreshold={patientThresholds.data?.oxygen.min}
            patientMaxThreshold={patientThresholds.data?.oxygen.max}
            groupMinThreshold={groupThresholds.data?.oxygen.min}
            groupMaxThreshold={groupThresholds.data?.oxygen.max}
          />
        </VitalsChartCard>
      </HorizontalGrid>

      <VitalsChartCard delay={0.2}>
        <BloodPressureChart
          id="diastolic-chart"
          dateRange={dateRange}
          ref={refs.systolic}
          systolicMinThreshold={patientThresholds.data?.systolic.min}
          systolicMaxThreshold={patientThresholds.data?.systolic.max}
          diastolicMinThreshold={patientThresholds.data?.diastolic.min}
          diastolicMaxThreshold={patientThresholds.data?.diastolic.max}
          systolicGroupMinThreshold={groupThresholds.data?.systolic.min}
          systolicGroupMaxThreshold={groupThresholds.data?.systolic.max}
          diastolicGroupMinThreshold={groupThresholds.data?.diastolic.min}
          diastolicGroupMaxThreshold={groupThresholds.data?.diastolic.max}
        />
      </VitalsChartCard>

      <HorizontalGrid>
        <VitalsChartCard key="glucose-chart" delay={0.3}>
          <VitalsChart
            id="glucose-chart"
            vitalType="glucose"
            dateRange={dateRange}
            ref={refs.glucose}
            patientMinThreshold={patientThresholds.data?.glucose.min}
            patientMaxThreshold={patientThresholds.data?.glucose.max}
            groupMinThreshold={groupThresholds.data?.glucose.min}
            groupMaxThreshold={groupThresholds.data?.glucose.max}
          />
        </VitalsChartCard>
        <VitalsChartCard key="temperature-chart" delay={0.4}>
          <VitalsChart
            id="temperature-chart"
            vitalType="temperature"
            dateRange={dateRange}
            ref={refs.temperature}
            patientMinThreshold={patientThresholds.data?.temperature.min}
            patientMaxThreshold={patientThresholds.data?.temperature.max}
            groupMinThreshold={groupThresholds.data?.temperature.min}
            groupMaxThreshold={groupThresholds.data?.temperature.max}
          />
        </VitalsChartCard>
      </HorizontalGrid>
      <HorizontalGrid>
        <VitalsChartCard key="weight-chart" delay={0.5}>
          <VitalsChart
            id="weight-chart"
            vitalType="weight"
            dateRange={dateRange}
            ref={refs.weight}
            patientMinThreshold={patientThresholds.data?.weight.min}
            patientMaxThreshold={patientThresholds.data?.weight.max}
            groupMinThreshold={groupThresholds.data?.weight.min}
            groupMaxThreshold={groupThresholds.data?.weight.max}
          />
        </VitalsChartCard>
        <VitalsChartCard key="fev1-chart" delay={0.6}>
          <VitalsChart
            id="fev1-chart"
            vitalType="fev1"
            dateRange={dateRange}
            ref={refs.fev1}
            patientMinThreshold={patientThresholds.data?.fev1.min}
            patientMaxThreshold={patientThresholds.data?.fev1.max}
            groupMinThreshold={groupThresholds.data?.fev1.min}
            groupMaxThreshold={groupThresholds.data?.fev1.max}
          />
        </VitalsChartCard>
      </HorizontalGrid>
      <HorizontalGrid>
        <VitalsChartCard key="pef-chart" delay={0.7}>
          <VitalsChart
            id="pef-chart"
            vitalType="pef"
            dateRange={dateRange}
            ref={refs.pef}
            patientMinThreshold={patientThresholds.data?.pef.min}
            patientMaxThreshold={patientThresholds.data?.pef.max}
            groupMinThreshold={groupThresholds.data?.pef.min}
            groupMaxThreshold={groupThresholds.data?.pef.max}
          />
        </VitalsChartCard>
      </HorizontalGrid>
    </VerticalGrid>
  );
};

export default VitalsGrid;
