import React from 'react';
import { useRoutes } from 'hooks';
import { useDateRange } from 'context/dateRange';
import {
  OfficeActiveAlertsCSV,
  OfficeAlertHistoryCSV,
  PatientVitalsHistoryCSV,
  PatientAlertHistoryCSV,
  PatientThresholdsCSV,
} from '../../templates/csv';
import {
  OfficeActiveAlertsPDF,
  OfficeAlertHistoryPDF,
  PatientAlertHistoryPDF,
  PatientThresholdsPDF,
  PatientVitalsHistoryPDF,
} from '../../templates/pdf';

type useExport = () => {
  PDFPreview: React.ReactNode;
  PDFButton: React.ReactNode;
  CSVButton?: React.ReactNode;
};

const useExport: useExport = () => {
  const { queryParams } = useRoutes();
  const [dateRange] = useDateRange();

  if (queryParams.section === 'office-active-alerts')
    return {
      PDFPreview: <OfficeActiveAlertsPDF dateRange={dateRange} display="preview" />,
      PDFButton: <OfficeActiveAlertsPDF dateRange={dateRange} display="button" />,
      CSVButton: <OfficeActiveAlertsCSV dateRange={dateRange} />,
    };

  if (queryParams.section === 'office-alert-history')
    return {
      PDFPreview: <OfficeAlertHistoryPDF dateRange={dateRange} display="preview" />,
      PDFButton: <OfficeAlertHistoryPDF dateRange={dateRange} display="button" />,
      CSVButton: <OfficeAlertHistoryCSV dateRange={dateRange} />,
    };

  if (queryParams.section === 'patient-vitals-history')
    return {
      PDFPreview: <PatientVitalsHistoryPDF dateRange={dateRange} display="preview" />,
      PDFButton: <PatientVitalsHistoryPDF dateRange={dateRange} display="button" />,
      CSVButton: <PatientVitalsHistoryCSV dateRange={dateRange} />,
    };

  if (queryParams.section === 'patient-alert-history')
    return {
      PDFPreview: <PatientAlertHistoryPDF dateRange={dateRange} display="preview" />,
      PDFButton: <PatientAlertHistoryPDF dateRange={dateRange} display="button" />,
      CSVButton: <PatientAlertHistoryCSV dateRange={dateRange} />,
    };

  if (queryParams.section === 'patient-thresholds')
    return {
      PDFPreview: <PatientThresholdsPDF display="preview" />,
      PDFButton: <PatientThresholdsPDF display="button" />,
      CSVButton: <PatientThresholdsCSV />,
    };

  return {
    PDFPreview: null,
    PDFButton: null,
  };
};

export default useExport;
