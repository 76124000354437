import React from 'react';
import { useKinesis } from 'hooks';
import { ThresholdsTable } from 'components/thresholds';
import { useThresholds } from 'context/thresholds';
import { ExportButton } from 'components/export';
import { useCurrentPatient } from 'context/patients';
import ControlSubHeader from 'components/display/ControlSubHeader/ControlSubHeader';
import { PatientThresholdsContainer } from './PatientThresholds.styled';

const PatientThresholds = () => {
  const thresholds = useThresholds();
  const currentPatient = useCurrentPatient();

  useKinesis();
  return (
    <PatientThresholdsContainer>
      <ControlSubHeader
        breadcrumbSections={['Patient', 'Thresholds']}
        isFetching={
          thresholds.groupThresholds.isFetching || thresholds.patientThresholds.isFetching
        }
        isSuccess={thresholds.groupThresholds.isSuccess && thresholds.patientThresholds.isSuccess}
        refetch={[thresholds.groupThresholds.refetch, thresholds.patientThresholds.refetch]}
      >
        <ExportButton
          disabled={
            thresholds.groupThresholds.isLoading ||
            thresholds.patientThresholds.isLoading ||
            !currentPatient
          }
          showDateRange={false}
        />
      </ControlSubHeader>
      <ThresholdsTable thresholds={thresholds} />
    </PatientThresholdsContainer>
  );
};

export default PatientThresholds;
