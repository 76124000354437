import { ComposedChart } from 'recharts';
import { getPngData } from 'recharts-to-png';

type generatePNG = (args: {
  ref: React.RefObject<ComposedChart> | null | undefined;
}) => Promise<string>;

const generatePNG: generatePNG = async ({ ref }) => {
  if (!ref?.current) return '';
  return getPngData(ref.current);
};

export default generatePNG;
