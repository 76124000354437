import React from 'react';

import {
  ResponsiblePartyCardContainer,
  Relationship,
  Name,
  Number,
  UserOutlined,
} from './ResponsiblePartyCard.styled';

type ResponsiblePartyCardProps = {
  relationship?: string;
  name?: string;
  phone?: string;
};

const ResponsiblePartyCard = ({
  relationship = '',
  name = '',
  phone = '',
}: ResponsiblePartyCardProps) => (
  <ResponsiblePartyCardContainer>
    <Relationship>
      <UserOutlined /> {(Boolean(relationship) && relationship) || '—'}
    </Relationship>
    <Name>{(Boolean(name) && name) || '—'}</Name>
    <Number>{(Boolean(phone) && phone) || '—'}</Number>
  </ResponsiblePartyCardContainer>
);

export default ResponsiblePartyCard;
