import React, { useState } from 'react';
import ControlSubHeader from 'components/display/ControlSubHeader/ControlSubHeader';
import { useKinesis } from 'hooks';
import { useVitals } from 'context/vitals';
import { useThresholds } from 'context/thresholds';
import { useCurrentPatient } from 'context/patients';
import { ExportButton } from 'components/export';
import { useDateRange } from 'context/dateRange';
import { DateRangePicker } from 'elements';
import { VitalsGrid, VitalsTable, TableSortButton, TableFilterButton } from 'components/vitals';
import { LineChartOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { Container, TabLabel, TabPane, Tabs } from './PatientVitalsHistory.styled';

type PatientVitalsHistoryProps = {
  vitalsView: 'chart' | 'list';
  setVitalsView: React.Dispatch<React.SetStateAction<'chart' | 'list'>>;
};

const PatientVitalsHistory = ({ vitalsView, setVitalsView }: PatientVitalsHistoryProps) => {
  const { isFetching, refetch, isSuccess, isLoading } = useVitals();
  const { patientThresholds, groupThresholds } = useThresholds();
  const currentPatient = useCurrentPatient();
  const [tableSort, setTableSort] = useState<'asc' | 'desc'>('desc');
  const [dateRange] = useDateRange();

  // used for table filter
  const [isSelectAllChecked, setIsSelectAllChecked] = useState(true);
  const [checkedVitals, setCheckedVitals] = useState<Vital[]>([]);

  const disabled = !currentPatient || isLoading;

  useKinesis();
  return (
    <Container>
      <ControlSubHeader
        breadcrumbSections={['Patient', 'Vitals History']}
        isFetching={isFetching && patientThresholds.isFetching && groupThresholds.isFetching}
        refetch={[refetch, patientThresholds.refetch, groupThresholds.refetch]}
        isSuccess={isSuccess && patientThresholds.isSuccess && groupThresholds.isSuccess}
      >
        <ExportButton disabled={disabled || vitalsView === 'list'} />
        <DateRangePicker disabled={disabled} />
      </ControlSubHeader>

      <Tabs
        defaultActiveKey={vitalsView}
        tabBarGutter={0}
        onChange={key => setVitalsView(key as 'chart' | 'list')}
        tabBarExtraContent={
          vitalsView === 'list' ? (
            <div>
              <TableFilterButton
                isSelectAllChecked={isSelectAllChecked}
                setIsSelectAllChecked={setIsSelectAllChecked}
                checkedVitals={checkedVitals}
                setCheckedVitals={setCheckedVitals}
                disabled={disabled}
              />
              <TableSortButton
                tableSort={tableSort}
                setTableSort={setTableSort}
                disabled={disabled}
              />
            </div>
          ) : null
        }
      >
        <TabPane
          tab={
            <TabLabel>
              <LineChartOutlined />
              Chart
            </TabLabel>
          }
          key="chart"
        >
          <VitalsGrid
            dateRange={dateRange}
            patientThresholds={patientThresholds}
            groupThresholds={groupThresholds}
          />
        </TabPane>
        <TabPane
          tab={
            <TabLabel>
              <UnorderedListOutlined />
              List
            </TabLabel>
          }
          key="list"
        >
          <VitalsTable
            dateRange={dateRange}
            tableSort={tableSort}
            isSelectAllChecked={isSelectAllChecked}
            checkedVitals={checkedVitals}
          />
        </TabPane>
      </Tabs>
    </Container>
  );
};

export default PatientVitalsHistory;
