import React from 'react';
import { motion, useReducedMotion, MotionValue } from 'framer-motion';
import styled from 'styled-components';

type PatientInformationSubHeaderAnimationProps = {
  children: React.ReactNode;
};

export const Container = styled(motion.div)`
  background-color: white;
  margin: 32px 32px 0px 32px;
  border-radius: ${props => props.theme.borderRadius};
  padding: 32px;
`;

const PatientInformationSubHeaderAnimation = ({
  children,
}: PatientInformationSubHeaderAnimationProps) => {
  const shouldReduceMotion = useReducedMotion();

  if (shouldReduceMotion) {
    return <div>{children}</div>;
  }

  return (
    <Container
      initial={{ y: -10, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ duration: 0.3 }}
    >
      {children}
    </Container>
  );
};

export default PatientInformationSubHeaderAnimation;
