import React from 'react';
import { AscendingIcon, DescendingIcon } from './TableSortButton.styled';

type TableSortButtonProps = {
  tableSort: 'asc' | 'desc';
  setTableSort: React.Dispatch<React.SetStateAction<'asc' | 'desc'>>;
  disabled?: boolean;
};

const TableSortButton = ({ tableSort, setTableSort, disabled = false }: TableSortButtonProps) => {
  if (tableSort === 'asc')
    return (
      <AscendingIcon
        onClick={() => disabled || setTableSort('desc')}
        disabled={disabled}
        data-testid="table-sort-button"
      />
    );

  return (
    <DescendingIcon
      onClick={() => disabled || setTableSort('asc')}
      disabled={disabled}
      data-testid="table-sort-button"
    />
  );
};

export default TableSortButton;
