import styled from 'styled-components';
import { WarningOutlined } from '@ant-design/icons';

export const UnauthorizedPage = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const WarningIcon = styled(WarningOutlined)`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  margin-bottom: 8px;
  color: rgba(0, 0, 0, 0.25);
`;

export const Text = styled.h1`
  font-size: 14px;
  color: rgba(0, 0, 0, 0.25);
`;
