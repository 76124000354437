import React, { useContext } from 'react';
import { QueryResult, useQuery } from 'react-query';
import _ from 'lodash';
import { useCurrentOffice } from 'context/offices';
import { useCurrentPatient } from 'context/patients';
import { useCognitoUser } from 'hooks';
import { pullPatientAlerts } from './utils';
import { captureError } from 'utils';

type PatientAlertsContext = QueryResult<Alert[], Error>;

export const PatientAlertsContext = React.createContext<PatientAlertsContext | undefined>(
  undefined,
);

type PatientAlertsProps = {
  children: React.ReactNode;
};

//addressed alerts provider
export const PatientAlertsProvider = ({ children }: PatientAlertsProps) => {
  const currentOffice = useCurrentOffice();
  const currentPatient = useCurrentPatient();
  const { data: cognitoUser } = useCognitoUser();

  const query = useQuery(
    [
      'pullPatientAlerts',
      {
        isAddressed: 'true',
        currentPatient,
      },
    ],
    (key, args) => pullPatientAlerts(key, { ...args, currentOffice, group: cognitoUser?.group }),
    {
      staleTime: 0,
      enabled: currentOffice && currentPatient,
      onError: error => {
        captureError({ error });
      },
    },
  );
  return <PatientAlertsContext.Provider value={query}>{children}</PatientAlertsContext.Provider>;
};

export const usePatientAlerts = () => {
  const context = useContext(PatientAlertsContext);
  if (context === undefined) {
    throw new Error('usePatientAlerts must be used within a PatientAlertsProvider');
  }
  return context;
};
