import styled from 'styled-components';
import { SortAscendingOutlined, SortDescendingOutlined } from '@ant-design/icons';

// ant design ascending/descending icons are backwards!!
export const AscendingIcon = styled(SortDescendingOutlined)`
  font-size: 1.2rem;
  opacity: 0.3;
  cursor: not-allowed !important;

  ${props =>
    props.disabled
      ? null
      : `
  &:hover {
    color: #4777b2;
  }
  
  opacity: 0.7;
  cursor: pointer !important;
  `}
`;

export const DescendingIcon = styled(SortAscendingOutlined)`
  font-size: 1.2rem;
  opacity: 0.3;
  cursor: not-allowed !important;

  ${props =>
    props.disabled
      ? null
      : `
  &:hover {
    color: #4777b2;
  }
  
  opacity: 0.7;
  cursor: pointer !important;
  `}
`;
