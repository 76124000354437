import { useQuery, QueryResult } from 'react-query';
import { captureError } from 'utils';
import pullSingleAlert from './utils/pullSingleAlert';

type useSingleAlertProps = {
  group: QueryResult<User, Error>;
  timestamp: string | undefined;
  account: string | undefined;
};

const useSingleAlert = ({ group, timestamp, account }: useSingleAlertProps) => {
  const query = useQuery(
    [
      'pullSingleAlert',
      {
        group: group.data?.group,
        timestamp,
        xmit_id: account,
      },
    ],
    pullSingleAlert,
    {
      staleTime: 0,
      onError: error => {
        captureError({ error });
      },
    },
  );

  return query;
};

export default useSingleAlert;
