import { useIdleTimer as useReactIdleTimer } from 'react-idle-timer';
import Auth from '@aws-amplify/auth';
import { useHistory } from 'react-router-dom';

const useIdleTimer = () => {
  const history = useHistory();
  useReactIdleTimer({
    // signs the user out after 90 minutes (time in ms)
    timeout: 1000 * 60 * 90,
    onIdle: async () => {
      await Auth.signOut();
      history.go(0);
    },
  });
};

export default useIdleTimer;
