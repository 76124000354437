import styled from 'styled-components';
import { Tabs as AntTabs } from 'antd';

export const Container = styled.div`
  padding: 0px 32px 32px 32px;
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;

  .ant-tabs-top > .ant-tabs-nav::before {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  .ant-tabs-content {
    height: 100%;
    box-sizing: border-box;
  }
`;

export const Tabs = styled(AntTabs)`
  height: 100%;
`;

export const TabLabel = styled.span`
  padding: 0 1.5rem;
`;

export const TabPane = styled(AntTabs.TabPane)`
  height: 100%;
`;
