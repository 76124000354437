import styled from 'styled-components';

export const VitalsReadingTitle = styled.h3`
  @media (max-width: 520px) {
    font-size: 32px;
  }
  @media (min-width: 521px) {
    font-size: 40px;
  }
  font-size: 40px;
  font-weight: bolder;
  text-align: center;
  margin: 0px;
`;

export const VitalsReadingTable = styled.table`
  width: 100%;
  max-width: 100vw;
  color: rgba(0, 0, 0, 0.82);
`;

export const VitalsTBody = styled.tbody``;

export const VitalsReadingTableRow = styled.tr`
  @media (max-width: 420px) {
    font-size: 18px;
  }
  @media (min-width: 421px) {
    font-size: 24px;
  }
  @media (min-width: 521px) {
    font-size: 32px;
  }
  padding-top: 6px;
  padding-bottom: 6px;
`;

export const VitalsTableHeader = styled.th`
  font-weight: lighter;
  width: 50%;
  text-align: right;
  padding-right: 12px;
`;

export const VitalsTableBody = styled.td`
  text-align: left;
  font-weight: 500;
  width: 50%;
  padding-left: 12px;
`;

export const VitalsTableHeaderBad = styled(VitalsTableHeader)`
  color: rgba(242, 69, 69, 1);
`;

export const VitalsTableBodyBad = styled(VitalsTableBody)`
  color: rgba(242, 69, 69, 1);
`;
