import React, { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { notification } from 'antd';
import { useHistory } from 'react-router-dom';
import { captureError } from 'utils';

type fallbackParams = {
  error?: Error | null;
  resetErrorBoundary?: () => void;
};

const useFallback = ({ error, resetErrorBoundary }: fallbackParams) => {
  const history = useHistory();

  useEffect(() => {
    // Error boundary for data display components
    if (resetErrorBoundary && error) {
      captureError({ error });
      history.push('/');
      resetErrorBoundary();
    }
  }, [error, resetErrorBoundary, history]);
  return null;
};

export default useFallback;
