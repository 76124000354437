import React from 'react';
import { View } from '@react-pdf/renderer';
import logo from 'img/logo.png';
import moment from 'moment';
import { Logo, Header, SubHeader, HeaderRow, Body, HeaderInfo } from './styles';

type PDFHeaderProps = {
  currentOffice: Office | undefined;
  dateRange?: [moment.Moment, moment.Moment];
  title: string;
  patientName?: string;
  dob?: string;
  mrn?: string;
};

const PDFHeader = ({
  currentOffice,
  dateRange,
  title,
  patientName = '',
  dob,
  mrn,
}: PDFHeaderProps) => {
  return (
    <View>
      <HeaderRow>
        <Header>{title}</Header>
      </HeaderRow>

      {/* display patient name or office name as subheader depending on page */}
      <SubHeader>{patientName || currentOffice?.description}</SubHeader>

      {dob ? (
        <HeaderInfo>
          DoB: {dob} &nbsp; {mrn ? <>MRN: {mrn} </> : null}
        </HeaderInfo>
      ) : null}

      <Body>Generated on {moment().format('LLL')}</Body>
      {dateRange ? (
        <Body>
          Data from {dateRange[0].format('LLL')} to {dateRange[1].format('LLL')}
        </Body>
      ) : null}
    </View>
  );
};

export default PDFHeader;
