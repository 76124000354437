type vitalsTypeToUnit = {
  [key in Vital]: VitalsUnit;
};

const vitalsTypeToUnit: vitalsTypeToUnit = {
  heartRate: 'BPM',
  oxygen: '%',
  glucose: 'mg/dl',
  temperature: 'ºF',
  weight: 'lbs',
  systolic: 'mm/Hg',
  diastolic: 'mm/Hg',
  fev1: 'ml',
  pef: 'LPM',
};

export default vitalsTypeToUnit;
