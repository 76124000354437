import ky from 'ky';
import Auth from '@aws-amplify/auth';

const pullGroupThresholds = async () => {
  const loggedInUserData = await Auth.currentSession();
  const group = loggedInUserData.accessToken.payload['cognito:groups']?.[0];

  const rawThresholds = await ky
    .post('https://rwgi3alw14.execute-api.us-east-1.amazonaws.com/prod/getmasterthresholdlimits', {
      json: {
        GroupID: group,
      },
    })
    .json<any>();

  const groupThresholds: GroupThresholds = {
    heartRate: {
      min: +rawThresholds[0]?.BPMLow?.S || null,
      max: +rawThresholds[0]?.BPMHigh?.S || null,
    },
    oxygen: {
      min: +rawThresholds[0]?.OxSatLow?.S || null,
      max: +rawThresholds[0]?.OxSatHigh?.S || null,
    },
    glucose: {
      min: +rawThresholds[0]?.GlucoseLow?.S || null,
      max: +rawThresholds[0]?.GlucoseHigh?.S || null,
    },
    temperature: {
      min: +rawThresholds[0]?.TemperatureLow?.S || null,
      max: +rawThresholds[0]?.TemperatureHigh?.S || null,
    },
    weight: {
      min: +rawThresholds[0]?.WeightLow?.S || null,
      max: +rawThresholds[0]?.WeightHigh?.S || null,
    },
    systolic: {
      min: +rawThresholds[0]?.SystolicLow?.S || null,
      max: +rawThresholds[0]?.SystolicHigh?.S || null,
    },
    diastolic: {
      min: +rawThresholds[0]?.DiastolicLow?.S || null,
      max: +rawThresholds[0]?.DiastolicHigh?.S || null,
    },
    fev1: {
      min: +rawThresholds[0]?.Fev1?.S || null,
      max: +rawThresholds[0]?.Fev1?.S || null,
    },
    pef: {
      min: +rawThresholds[0]?.Pef?.S || null,
      max: +rawThresholds[0]?.Pef?.S || null,
    },
  };

  return groupThresholds;
};

export default pullGroupThresholds;
