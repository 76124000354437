import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useCurrentOffice } from 'context/offices';

import PatientListDropdown from '../PatientListDropdown/PatientListDropdown';
import OfficeDropdown from '../OfficeDropdown/OfficeDropdown';

import getSelectors from './utils/selectors/selectors';
import useSelectorFade from './utils/useSelectorFade/useSelectorFade';
import {
  DataSelectorContainer,
  OfficeDropdownContainer,
  PatientListDropdownContainer,
} from './DataSelector.styled';

const DataSelector = () => {
  const currentOffice = useCurrentOffice();
  const { pathname } = useLocation();
  const [showOfficeDropdown, showPatientDropdown] = useMemo(() => getSelectors(pathname), [
    pathname,
  ]);
  const officeStyles = useSelectorFade(showOfficeDropdown);
  const patientStyles = useSelectorFade(showPatientDropdown);

  return (
    <DataSelectorContainer>
      <OfficeDropdownContainer style={officeStyles}>
        <OfficeDropdown />
      </OfficeDropdownContainer>
      <PatientListDropdownContainer style={patientStyles}>
        <PatientListDropdown disabled={!currentOffice} />
      </PatientListDropdownContainer>
    </DataSelectorContainer>
  );
};

export default DataSelector;
