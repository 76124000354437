import React from 'react';
import { QueryResult } from 'react-query';
import { EmptyIcon, LoadingIcon, Unauthorized } from 'elements';
import GenericTable from 'elements/GenericTable/GenericTable';
import { useOfficeList } from 'context/offices';
import { usePatientList } from 'context/patients';
import { useRoutes, useUnauthorized } from 'hooks';

type OfficeAlertHistoryTableProps = {
  alerts: QueryResult<Alert[], Error>;
};

const OfficeAlertHistoryTable = ({ alerts }: OfficeAlertHistoryTableProps) => {
  const { isAuthorized } = useUnauthorized();
  const officeList = useOfficeList();
  const patientList = usePatientList();
  const { pushRouteChange } = useRoutes();

  if (alerts.isLoading || officeList.isLoading || patientList.isLoading) {
    return <LoadingIcon />;
  }

  if (!isAuthorized) return <Unauthorized />;

  if (!alerts.data?.length) {
    return <EmptyIcon />;
  }

  return (
    <GenericTable
      tableData={alerts?.data}
      isLoading={alerts?.isLoading}
      tableAttributes={[
        { attributeName: 'addressedTime', attributeType: 'timestamp', header: 'Date Addressed' },
        { attributeName: 'patient', attributeType: 'string' },
        { attributeName: 'issue', attributeType: 'string', width: 300 },
        { attributeName: 'type', attributeType: 'string' },
        {
          attributeName: 'addressedBy',
          attributeType: 'string',
        },
        {
          attributeName: 'details',
          attributeType: 'button',
          buttonText: 'Details',
          header: ' ',
          onClick: (alert: Alert) => {
            pushRouteChange({
              section: 'alert-details',
              xmit: alert.xmit_id,
              timestamp: alert.timestamp,
              patient:
                patientList.data?.find(patient => patient.xmit_id === alert.xmit_id)?.username ||
                '',
            });
          },
        },
      ]}
    />
  );
};

export default OfficeAlertHistoryTable;
