type prettyToVitalsType = {
  [key in PrettyVital]: Vital;
};

const prettyToVitalsType: prettyToVitalsType = {
  'Heart Rate': 'heartRate',
  Oxygen: 'oxygen',
  Glucose: 'glucose',
  Temperature: 'temperature',
  Weight: 'weight',
  Systolic: 'systolic',
  Diastolic: 'diastolic',
  'Fev 1': 'fev1',
  Pef: 'pef',
};

export default prettyToVitalsType;
