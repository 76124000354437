type determineAboveThresholdAreaColor = (args: {
  maxThreshold: number | null;
  vitals: VitalsReading[];
  vitalType: Vital;
}) => string;

const determineAboveThresholdAreaColor: determineAboveThresholdAreaColor = ({
  maxThreshold,
  vitals,
  vitalType,
}) => {
  // if no maxThreshold just return
  if (!maxThreshold) return '#ddd';

  // try to find a vital above the maxThreshold
  const aboveThreshold = vitals.find(vital => Number(vital[vitalType]) > maxThreshold);

  // display danger if there is one
  if (aboveThreshold) return '#f24b4b';

  // default to gray
  return '#ddd';
};

export default determineAboveThresholdAreaColor;
