import React from 'react';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';

type generatePDF = (args: {
  document: React.ReactElement;
  title: string;
  generateChartImages?: Function;
}) => Promise<Blob>;

const generatePDF: generatePDF = async ({ document, title, generateChartImages }) => {
  // take screenshots if the generateChartImages function is provided
  if (generateChartImages) {
    const chartImages = await generateChartImages();
    const file = await pdf(
      React.cloneElement(document, { ...document.props, chartImages }),
    ).toBlob();
    saveAs(file, `${title}.pdf`);
    return file;
  }

  // otherwise just generate the PDF
  const file = await pdf(document).toBlob();
  saveAs(file, `${title}.pdf`);
  return file;
};

export default generatePDF;
