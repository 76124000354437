import { useSpring } from 'react-spring';
import { useMotionPreference } from 'web-api-hooks';

const useSelectorFade = (shouldScale: any) => {
  const prefersReducedMotion = useMotionPreference() === 'reduce' || window.name === 'nodejs';
  const animatedStyles = useSpring({
    transform: shouldScale
      ? `translate3d(0px, 0px, 0px) scale3d(1,1,1)`
      : `translate3d(0px, -28px, 0px) scale3d(0,0,0)`,
    opacity: shouldScale ? 1 : 0,
    from: {
      transform: `translate3d(0px, -28px, 0px) scale3d(0,0,0)`,
      opacity: 0,
    },
    immediate: prefersReducedMotion,
  });
  return animatedStyles;
};

export default useSelectorFade;
