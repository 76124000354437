import styled from 'styled-components';
import { animated } from 'react-spring';
import { WarningOutlined as Warning } from '@ant-design/icons';

export const AlertDetailCardContainer = styled(animated.div)`
  display: flex;
  flex-direction: column;
  flex-grow: 2;
  min-height: 486px;
  padding: 8px;
`;

export const CardTitle = styled.h3`
  font-size: 24px;
  font-weight: bolder;
  text-align: left;
  margin-bottom: 8px;
  color: ${props => props.theme.textDark};
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 2;
  border-radius: ${props => props.theme.secondaryBorderRadius};
  padding: 24px;
  background-color: rgba(255, 255, 255, 1);
  border: ${props => props.theme.danger1} 1px solid;
  filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.15));
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
`;

export const HeaderTitle = styled.h2`
  @media (max-width: 420px) {
    font-size: 18px;
  }
  @media (min-width: 421px) {
    font-size: 24px;
  }
  font-size: 24px;
  margin: 0px;
`;

export const WarningOutlined = styled(Warning)`
  color: ${props => props.theme.danger1};
  font-size: 2rem;
`;

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  background: rgba(0, 0, 0, 0.2);
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const DateContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const CardDate = styled.div`
  @media (max-width: 420px) {
    font-size: 16px;
  }
  @media (min-width: 421px) {
    font-size: 19px;
  }
  font-size: 19px;
`;

export const ViewContainer = styled.div`
  flex-grow: 2;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
