/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_content_delivery_bucket": "emr-health-dashboard-hosting-int",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d2unkpnpsdeiv9.cloudfront.net",
    "aws_cognito_identity_pool_id": "us-east-1:6921d448-ac88-4271-96a1-019cf11ad643",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_QLefmi72Y",
    "aws_user_pools_web_client_id": "3sf7uj76omvk2au290e9abmsc8",
    "oauth": {},
    "aws_mobile_analytics_app_id": "982c615c95f741dcacf863d850454f3a",
    "aws_mobile_analytics_app_region": "us-east-1"
};


export default awsmobile;
