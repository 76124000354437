import React, { useContext, useState } from 'react';
import { ComposedChart } from 'recharts';
import { useMutation, MutationResultPair } from 'react-query';
import generateChartImages from './utils/generateChartImages';

type VitalsChartScreenshotsContext = [
  {
    [key in Vital]?: React.RefObject<ComposedChart>;
  },
  React.Dispatch<
    {
      [key in Vital]?: React.RefObject<ComposedChart>;
    }
  >,
];

export const VitalsChartScreenshotsContext = React.createContext<
  VitalsChartScreenshotsContext | undefined
>(undefined);

type VitalsChartScreenshotsProviderProps = {
  children: React.ReactNode;
};

export const VitalsChartScreenshotsProvider = ({
  children,
}: VitalsChartScreenshotsProviderProps) => {
  const state = useState<
    {
      [key in Vital]?: React.RefObject<ComposedChart> | undefined;
    }
  >({});

  return (
    <VitalsChartScreenshotsContext.Provider value={state}>
      {children}
    </VitalsChartScreenshotsContext.Provider>
  );
};

export const useVitalsChartScreenshots = () => {
  const context = useContext(VitalsChartScreenshotsContext);

  if (context === undefined) {
    throw new Error(
      'useVitalsChartScreenshots must be used within a VitalsChartScreenshotsProvider',
    );
  }

  return context;
};

type useVitalsChartPNGs = (args?: {
  type?: Vital;
}) => MutationResultPair<
  {
    [key in Vital]?: string;
  },
  undefined,
  Error
>;

export const useVitalsChartPNGs: useVitalsChartPNGs = () => {
  const [screenshots] = useVitalsChartScreenshots();
  const mutation = useMutation(async () => generateChartImages({ screenshots: screenshots || {} }));

  return mutation;
};
