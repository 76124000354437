import { Text, View, Image, Font, Page } from '@react-pdf/renderer';
import styled from '@react-pdf/styled-components';

Font.register({
  family: 'Open Sans',
  fonts: [
    {
      src: 'https://fonts.gstatic.com/s/opensans/v18/mem8YaGs126MiZpBA-UFVZ0ef8pkAg.ttf',
    },
    {
      src: 'https://fonts.gstatic.com/s/opensans/v18/mem5YaGs126MiZpBA-UN7rgOUuhsKKSTjw.ttf',
      fontWeight: 700,
    },
  ],
});

Font.register({
  family: 'Roboto Condensed',
  fonts: [
    {
      src:
        'https://fonts.gstatic.com/s/robotocondensed/v19/ieVl2ZhZI2eCN5jzbjEETS9weq8-19K7CAk6YvM.ttf',
    },
    {
      src:
        'https://fonts.gstatic.com/s/robotocondensed/v19/ieVi2ZhZI2eCN5jzbjEETS9weq8-32meGCQYatlecyU.ttf',
      fontWeight: 700,
    },
  ],
});

export const Logo = styled(Image)`
  width: 150px;
`;

export const PagePadding = styled(Page)`
  padding: 50px 52px 85px 52px;
`;

export const HeaderRow = styled(View)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Header = styled(Text)`
  font-family: 'Roboto Condensed';
  font-weight: bold;
  font-size: 28px;
`;

export const SubHeader = styled<{ bold?: boolean }>(Text)`
  font-family: 'Open Sans';
  font-size: 12px;
  margin-bottom: 4px;
  font-weight: ${(props: { bold?: boolean }) => (props.bold ? 'bold' : 'normal')};
`;

export const HeaderInfo = styled(Text)`
  font-family: 'Open Sans';
  font-size: 10px;
  margin-bottom: 4px;
`;

export const PageHeader = styled(Text)`
  font-family: 'Roboto Condensed';
  font-size: 16px;
  margin-bottom: 4px;
`;

export const TableHeader = styled(Text)`
  font-family: 'Open Sans';
  font-size: 8px;
  font-weight: bold;
  margin: 0;
`;

export const Body = styled(Text)`
  font-family: 'Open Sans';
  font-size: 8px;
  margin: 0;
`;

export const Table = styled(View)`
  display: table;
  margin-top: 8px;
`;

export const TableRow = styled(View)`
  width: 100%;
  flex-direction: row;
  justify-content: space-around;
  margin: 0;
`;

export const TableColumn = styled(View)`
  border-bottom: 1pt solid black;
  padding: 6px;
  flex: 1;
`;

export const TimestampColumn = styled(View)`
  border-bottom: 1pt solid black;
  padding: 6px;
  flex: 2;
`;

export const TitleRow = styled(View)`
  margin-bottom: 12px;
`;

export const Footer = styled(View)`
  opacity: 0.8;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 50px 52px;
  justify-content: space-between;
  flex-direction: row;
`;
