import React, { useEffect } from 'react';
import { useCurrentOffice } from 'context/offices';
import { useCurrentPatient } from 'context/patients';
import { useVitals } from 'context/vitals';
import { useDateRange } from 'context/dateRange';
import { useFilteredVitals } from 'hooks';
import { useVitalsChartPNGs } from 'context/vitalsChartScreenshots';
import moment from 'moment';
import { Button, LoadingIcon } from 'elements';
import { vitalsTypes, vitalsTypeToPretty, vitalsTypeToCondensed } from 'dictionary';
import { Document, PDFViewer, Image } from '@react-pdf/renderer';
import { useGeneratePDF } from 'components/export/__hooks__';
import PDFHeader from './PDFHeader';
import {
  PagePadding,
  Body,
  TableRow,
  TableColumn,
  Table,
  TableHeader,
  TimestampColumn,
  TitleRow,
  Footer,
  PageHeader,
} from './styles';

type OfficeActiveAlertsPDFPreviewProps = {
  document: React.ReactElement;
};

const OfficeActiveAlertsPDFPreview = ({ document }: OfficeActiveAlertsPDFPreviewProps) => {
  const [generateChartImages, { data }] = useVitalsChartPNGs();
  const [dateRange] = useDateRange();

  useEffect(() => {
    // retake chart screenshots when dateRange changes
    generateChartImages();
  }, [dateRange, generateChartImages]);

  // wait for screenshots to be taken
  if (!data) return <LoadingIcon />;

  return (
    <PDFViewer width="100%" height="100%">
      {React.cloneElement(document, { ...document.props, chartImages: data })}
    </PDFViewer>
  );
};

type WrapperProps = {
  dateRange: [moment.Moment, moment.Moment];
  display: 'preview' | 'button';
};

const Wrapper = ({ dateRange, display }: WrapperProps) => {
  const { data } = useVitals();
  const vitals = useFilteredVitals({ data, dateRange });
  const currentOffice = useCurrentOffice();
  const currentPatient = useCurrentPatient();
  const title = `${currentPatient?.customer_name} Patient Vitals History ${moment().format(
    'MM-DD-YYYY',
  )}`;
  const [generatePDF, { isLoading }] = useGeneratePDF();
  const [generateChartImages] = useVitalsChartPNGs();

  const document = (
    <OfficeActiveAlertsPDF
      vitals={vitals}
      currentOffice={currentOffice}
      dateRange={dateRange}
      title={title}
      patientName={currentPatient?.customer_name}
      dob={currentPatient?.date_of_birth}
      mrn={currentPatient?.MRNum}
    />
  );

  if (display === 'preview') {
    return <OfficeActiveAlertsPDFPreview document={document} />;
  }

  return (
    <Button
      loading={isLoading}
      onClick={() => generatePDF({ document, title, generateChartImages })}
    >
      Export
    </Button>
  );
};

type OfficeActiveAlertsPDFProps = {
  vitals: VitalsReading[] | undefined;
  currentOffice: Office | undefined;
  dateRange: [moment.Moment, moment.Moment];
  title: string;
  patientName?: string;
  dob?: string;
  mrn?: string;
  chartImages?: {
    [key in Vital]?: string;
  };
};

const OfficeActiveAlertsPDF = ({
  vitals,
  currentOffice,
  dateRange,
  title,
  patientName,
  dob,
  mrn,
  chartImages,
}: OfficeActiveAlertsPDFProps) => (
  <Document title={title}>
    <PagePadding wrap>
      <PDFHeader
        currentOffice={currentOffice}
        dateRange={dateRange}
        title="Patient Vitals History"
        patientName={patientName}
        dob={dob}
        mrn={mrn}
      />

      <Table>
        {/* All Readings Table Header Row */}
        <TableRow fixed>
          <TimestampColumn>
            <TableHeader>Timestamp</TableHeader>
          </TimestampColumn>
          <TableColumn>
            <TableHeader>BP</TableHeader>
          </TableColumn>
          <TableColumn>
            <TableHeader>{vitalsTypeToCondensed.heartRate}</TableHeader>
          </TableColumn>
          <TableColumn>
            <TableHeader>{vitalsTypeToCondensed.weight}</TableHeader>
          </TableColumn>
          <TableColumn>
            <TableHeader>{vitalsTypeToCondensed.oxygen}</TableHeader>
          </TableColumn>
          <TableColumn>
            <TableHeader>{vitalsTypeToCondensed.glucose}</TableHeader>
          </TableColumn>
          <TableColumn>
            <TableHeader>{vitalsTypeToCondensed.temperature}</TableHeader>
          </TableColumn>
          <TableColumn>
            <TableHeader>{vitalsTypeToCondensed.fev1}</TableHeader>
          </TableColumn>
          <TableColumn>
            <TableHeader>{vitalsTypeToCondensed.pef}</TableHeader>
          </TableColumn>
        </TableRow>
        {/* All Readings Table Data Rows */}
        {vitals
          ? vitals
              // sort in descending order
              .sort(
                ({ timestamp: z }, { timestamp: a }) => moment(a).valueOf() - moment(z).valueOf(),
              )
              .map((vital, index) => (
                <TableRow wrap={false} key={`${patientName}-${vital.timestamp}-${index}`}>
                  <TimestampColumn>
                    <Body data-testid="pdf-row-timestamp">
                      {moment(vital.timestamp).format('MM/DD/YYYY hh:mm A') || '-'}
                    </Body>
                  </TimestampColumn>
                  <TableColumn>
                    <Body>{vital.systolic ? `${vital.systolic}/${vital.diastolic}` : '-'}</Body>
                  </TableColumn>
                  <TableColumn>
                    <Body>{vital.heartRate || '-'}</Body>
                  </TableColumn>
                  <TableColumn>
                    <Body>{vital.weight || '-'}</Body>
                  </TableColumn>
                  <TableColumn>
                    <Body>{vital.oxygen || '-'}</Body>
                  </TableColumn>
                  <TableColumn>
                    <Body>{vital.glucose || '-'}</Body>
                  </TableColumn>
                  <TableColumn>
                    <Body>{vital.temperature || '-'}</Body>
                  </TableColumn>
                  <TableColumn>
                    <Body>{vital.fev1 || '-'}</Body>
                  </TableColumn>
                  <TableColumn>
                    <Body>{vital.pef || '-'}</Body>
                  </TableColumn>
                </TableRow>
              ))
          : null}
      </Table>
      <Footer fixed>
        <Body fixed>Patient Vitals History</Body>
        <Body
          fixed
          render={({ pageNumber, totalPages }: { pageNumber: number; totalPages: number }) =>
            `${pageNumber} / ${totalPages}`
          }
        />
      </Footer>
    </PagePadding>
    <PagePadding wrap>
      <Table>
        {/* Blood Pressure Page */}
        {(() => {
          // if no data exists for blood pressure, return null
          const filteredData = vitals
            ? vitals
                .filter(vital => {
                  return vital.systolic;
                })
                // sort in descending order
                .sort(
                  ({ timestamp: z }, { timestamp: a }) => moment(a).valueOf() - moment(z).valueOf(),
                )
            : [];
          if (filteredData.length === 0) return null;

          return (
            <>
              {/* Section Title */}
              <TitleRow>
                <PageHeader>Blood Pressure Readings</PageHeader>
              </TitleRow>

              {/* Image of the vitals chart for the particular vital */}
              {chartImages?.systolic ? <Image src={chartImages?.systolic || ''} /> : null}
            </>
          );
        })()}
      </Table>
      <Footer fixed>
        <Body fixed>Patient Vitals History</Body>
        <Body
          fixed
          render={({ pageNumber, totalPages }: { pageNumber: number; totalPages: number }) =>
            `${pageNumber} / ${totalPages}`
          }
        />
      </Footer>
    </PagePadding>
    {/* Generate sections for each vital type */}
    {vitalsTypes.map(vitalType => {
      // filter out blood pressure
      if (vitalType === 'diastolic' || vitalType === 'systolic') return null;

      // if no data exists for the vital, return null
      const filteredData = vitals
        ? vitals
            .filter(vital => {
              return vital[vitalType];
            })
            // sort in descending order
            .sort(({ timestamp: z }, { timestamp: a }) => moment(a).valueOf() - moment(z).valueOf())
        : [];
      if (filteredData.length === 0) return null;

      return (
        <PagePadding wrap>
          <Table>
            {/* Section Title */}
            <TitleRow>
              <PageHeader>{vitalsTypeToPretty[vitalType]} Readings</PageHeader>
            </TitleRow>

            {/* Image of the vitals chart for the particular vital */}
            {chartImages?.[vitalType] ? <Image src={chartImages?.[vitalType] || ''} /> : null}
          </Table>
          <Footer fixed>
            <Body fixed>Patient Vitals History</Body>
            <Body
              fixed
              render={({ pageNumber, totalPages }: { pageNumber: number; totalPages: number }) =>
                `${pageNumber} / ${totalPages}`
              }
            />
          </Footer>
        </PagePadding>
      );
    })}
  </Document>
);

export default Wrapper;
