import styled from 'styled-components';
import { animated } from 'react-spring';
import { Input } from 'antd';

const { TextArea } = Input;

export const NotesFieldContainer = styled(animated.div)`
  display: grid;
  grid-template-rows: 0.5fr 2fr 0.5fr;
  width: 100%;
  padding: 8px;
`;

export const TextBoxLabel = styled.h3`
  text-align: left;
  width: 100%;
  margin-bottom: 8px;
  font-weight: bolder;
  font-size: 24px;
  color: ${props => props.theme.textDark};
`;

export const TextBox = styled.label`
  text-align: left;
  width: 100%;
  margin-bottom: 8px;
  font-weight: bolder;
  font-size: 24px;
  color: ${props => props.theme.textDark};
`;

export const NoteTextArea = styled(TextArea)`
  text-align: left;
  width: 100%;
  color: ${props => props.theme.textDark};
`;

export const Footer = styled(animated.div)`
  display: flex;
  padding-left: 8px;
  padding-right: 8px;
  flex-direction: row;
  justify-content: space-between;
  align-items: top;
  margin-top: 16px;
  width: 100%;
  color: rgba(0, 0, 0, 0.5);
  text-align: center;
`;

export const FooterText = styled(Footer)`
  margin: 0px;
  padding-left: 0px;
`;

export const ButtonsRow = styled(animated.div)`
  display: flex;
  flex-direction: row-reverse;
  padding: 20px;
`;

export const AddressedNotesField = styled.div<{ noNotes: any }>`
  padding: 1rem;
  overflow-y: auto;
  border-radius: $ecg-border-radius;
  border: 1px rgba(0, 0, 0, 0.15) solid;
  width: 100%;
  height: 150px;
  background-color: rgba(255, 255, 255, 1);
  font-size: 16px;
  color: rgba(0, 0, 0, 0.75);
  text-align: ${({ noNotes }) => (noNotes ? 'center' : '')};
  justify-content: ${({ noNotes }) => (noNotes ? 'center' : '')};
  border-radius: ${props => props.theme.secondaryBorderRadius};
`;

export const AddressedText = styled.p`
  margin: 0px;
  text-align: justify;
`;
export const Loading = styled.div`
  height: 100%;
`;
