import React from 'react';
import { Container, Spinner } from './Button.styled';

type ButtonProps = {
  children: React.ReactNode;
  onClick?: (event: React.SyntheticEvent<HTMLButtonElement>) => void;
  type?: 'primary' | 'secondary' | 'success' | 'danger' | 'link';
  disabled?: boolean;
  loading?: boolean;
};

const Button = ({
  children,
  type: displayType = 'primary',
  onClick,
  disabled = false,
  loading = false,
}: ButtonProps) => (
  <Container
    displayType={displayType}
    onClick={onClick}
    disabled={disabled || loading}
    loading={!!loading}
  >
    {loading && <Spinner />}
    {children}
  </Container>
);

export default Button;
