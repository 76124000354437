import React from 'react';
import { QueryResult } from 'react-query';
import { useCurrentPatient } from 'context/patients';
import { useCurrentOffice } from 'context/offices';
import { useRoutes } from 'hooks';

type useUnauthorizedProps = (args?: {
  singleAlert: QueryResult<Alert, Error>;
}) => { isAuthorized: boolean };

const useUnauthorized: useUnauthorizedProps = args => {
  const currentOffice = useCurrentOffice();
  const currentPatient = useCurrentPatient();
  const {
    queryParams: { office, patient, section },
  } = useRoutes();
  const isOfficeUnauthorized = office && !currentOffice;
  const isPatientUnauthorized = patient && !currentPatient;

  if (section === 'alert-details' && !args?.singleAlert?.isLoading && !args?.singleAlert?.data) {
    return { isAuthorized: false };
  }

  if (isOfficeUnauthorized || isPatientUnauthorized) {
    return { isAuthorized: false };
  }
  return { isAuthorized: true };
};

export default useUnauthorized;
