import ky from 'ky';
import Auth from '@aws-amplify/auth';
import moment from 'moment';

type pullVitalsData = (
  key: string,
  args: { currentPatient: Patient | undefined; group: string | undefined },
) => Promise<VitalsReading[]>;

const pullVitalsData: pullVitalsData = async (key, { currentPatient, group }) => {
  // if no patient or group selected return
  if (!currentPatient || !group) return [];

  const {
    idToken: { jwtToken },
  } = await Auth.currentSession();

  // pull raw vitals data
  const rawVitals = await ky
    .post('https://53l71j53f5.execute-api.us-east-1.amazonaws.com/prod', {
      headers: { Authorization: jwtToken },
      json: { xmit: currentPatient.xmit_id, cypress: group === 'Cypress' },
    })
    .json<VitalsReading[]>();

  // filter and sort the vitals data
  const filteredVitals: VitalsReading[] =
    // filter out vitals before the activation date
    // (activation date is stored in MST)
    rawVitals
      .filter((vital: VitalsReading) => {
        return moment
          .utc(vital.timestamp)
          .isAfter(moment(currentPatient.updated_at.replace('Z', '')));
      })
      // sort vitals by timestamp in descending order for the charts
      .sort((a, z) => moment(a.timestamp).valueOf() - moment(z.timestamp).valueOf());

  return filteredVitals;
};

export default pullVitalsData;
