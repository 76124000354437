type determineBelowThresholdAreaColor = (args: {
  minThreshold: number | null;
  vitals: VitalsReading[];
  vitalType: Vital;
}) => string;

const determineBelowThresholdAreaColor: determineBelowThresholdAreaColor = ({
  minThreshold,
  vitals,
  vitalType,
}) => {
  // if no minThreshold just return
  if (!minThreshold) return '#ddd';

  // try to find a vital below the minThreshold
  const belowThreshold = vitals.find(vital => Number(vital[vitalType]) < minThreshold);

  // display danger if there is one
  if (belowThreshold) return '#f24b4b';

  // default to gray
  return '#ddd';
};

export default determineBelowThresholdAreaColor;
