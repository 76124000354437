import { useRef } from 'react';
import { ComposedChart } from 'recharts';

const useVitalsChartRefs = () => {
  const heartRate = useRef<ComposedChart>(null);
  const oxygen = useRef<ComposedChart>(null);
  const glucose = useRef<ComposedChart>(null);
  const temperature = useRef<ComposedChart>(null);
  const weight = useRef<ComposedChart>(null);
  const systolic = useRef<ComposedChart>(null);
  const fev1 = useRef<ComposedChart>(null);
  const pef = useRef<ComposedChart>(null);

  return {
    heartRate,
    oxygen,
    glucose,
    temperature,
    weight,
    systolic,
    fev1,
    pef,
  };
};

export default useVitalsChartRefs;
