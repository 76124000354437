import { useHistory, useLocation } from 'react-router-dom';
import _ from 'lodash';
import queryString from 'query-string';
import pushRouteChange, { pushRouteChangeArgs } from './utils/pushRouteChange';

export type queryParams = {
  section?: string;
  office?: string;
  patient?: string;
  xmit?: string;
  timestamp?: string;
};

type useRoutes = () => {
  pushRouteChange: (args: queryParams) => void;
  queryParams: queryParams;
};

const useRoutes: useRoutes = () => {
  const history = useHistory();
  const location = useLocation();

  // grab the query params from the url
  const parsedURL = _.split(location.pathname, '/');
  const stringQueryParams = parsedURL[parsedURL.length - 1];
  const currentParams = queryString.parse(stringQueryParams);

  return {
    pushRouteChange: (args: pushRouteChangeArgs) =>
      pushRouteChange({ ...args, history, currentParams }),
    queryParams: { section: parsedURL[1], ...currentParams },
  };
};

export default useRoutes;
