import _ from 'lodash';
import { generatePath } from 'react-router-dom';
import queryString from 'query-string';
import { queryParams } from '../useRoutes';

export type pushRouteChangeArgs = {
  section?: string;
  office?: string;
  patient?: string;
  history?: any;
  xmit?: string;
  timestamp?: string;
  currentParams?: queryParams;
};

type pushRouteChange = (args: pushRouteChangeArgs) => void;

const pushRouteChange: pushRouteChange = ({
  section,
  office,
  patient,
  history,
  xmit,
  timestamp,
  currentParams,
}) => {
  // get current route information
  const currentURLArray = history.location.pathname.split('/');
  const currentSection: string | undefined = currentURLArray?.[1];
  const currentOffice = currentParams?.office;
  const currentPatient = currentParams?.patient;
  const currentXmit = currentParams?.xmit;
  const currentTimestamp = currentParams?.timestamp;

  // determine new route information
  const newSection = section ?? currentSection;
  const newOffice = office ?? currentOffice;
  const newPatient = patient ?? currentPatient;
  const newXmit = newSection === 'alert-details' ? xmit ?? currentXmit : '';
  const newTimestamp = newSection === 'alert-details' ? timestamp ?? currentTimestamp : '';

  const queries =
    queryString.stringify({
      office: newOffice || undefined,
      patient: newPatient || undefined,
      xmit: newXmit || undefined,
      timestamp: newTimestamp || undefined,
    }) || undefined;

  const newURL = generatePath(`/${newSection}/:queries?`, {
    queries,
  });
  history.push(newURL);
};

export default pushRouteChange;
