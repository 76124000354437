import styled from 'styled-components';
import { animated } from 'react-spring';

export const ResponsiblePartiesContainer = styled(animated.div)`
  height: 100%;
  flex-grow: 1;
  padding: 8px;
`;

export const Title = styled.h3`
  font-size: 24px;
  font-weight: bolder;
  text-align: left;
  margin-bottom: 8px;
  color: ${props => props.theme.textDark};
`;

export const NoParty = styled.div`
  background-color: rgba(255, 255, 255, 1);
  border: 1px rgba(0, 0, 0, 0.15) solid;
  border-radius: ${props => props.theme.secondaryBorderRadius};
  padding: 24px;
`;

export const TitleMissing = styled(Title)`
  margin: 0;
  text-align: center;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.5);
`;

export const PartyStyles = styled(animated.div)``;
