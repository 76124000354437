import React, { useState } from 'react';
import { Dropdown, Checkbox } from 'antd';
import _ from 'lodash';
import { vitalsTypes, vitalsTypeToPretty } from 'dictionary';
import { FilterButton, SelectMenu, SelectItem } from './TableFilterButton.styled';

type TableFilterListProps = {
  isSelectAllChecked: boolean;
  setIsSelectAllChecked: React.Dispatch<React.SetStateAction<boolean>>;
  checkedVitals: Vital[];
  setCheckedVitals: React.Dispatch<React.SetStateAction<Vital[]>>;
};

const TableFilterList = ({
  isSelectAllChecked,
  setIsSelectAllChecked,
  checkedVitals,
  setCheckedVitals,
}: TableFilterListProps) => {
  return (
    <SelectMenu selectable={false}>
      <SelectItem>
        <Checkbox
          checked={isSelectAllChecked}
          onClick={() => setIsSelectAllChecked(!isSelectAllChecked)}
        >
          Select all
        </Checkbox>
      </SelectItem>
      {vitalsTypes
        .filter(item => item !== 'diastolic')
        .map((vital, index) => {
          return (
            <SelectItem>
              <Checkbox
                data-testid={`${vital}-check-box`}
                key={`${checkedVitals.toString()}${index}`}
                checked={isSelectAllChecked || checkedVitals.includes(vital)}
                onClick={() => {
                  // if select all is checked, uncheck everything except the vital checked
                  if (isSelectAllChecked) {
                    setCheckedVitals([vital]);
                    setIsSelectAllChecked(false);
                    return;
                  }

                  // if the vital is checked, uncheck it
                  if (checkedVitals.includes(vital)) {
                    setCheckedVitals(_.remove(checkedVitals, item => item !== vital));
                    return;
                  }

                  // if the vital is unchecked, check it
                  setCheckedVitals([...checkedVitals, vital]);
                }}
              >
                {vital === 'systolic' ? 'Blood Pressure' : vitalsTypeToPretty[vital]}
              </Checkbox>
            </SelectItem>
          );
        })}
    </SelectMenu>
  );
};

type TableFilterButtonProps = {
  isSelectAllChecked: boolean;
  setIsSelectAllChecked: React.Dispatch<React.SetStateAction<boolean>>;
  checkedVitals: Vital[];
  setCheckedVitals: React.Dispatch<React.SetStateAction<Vital[]>>;
  disabled?: boolean;
};

const TableFilterButton = ({
  isSelectAllChecked,
  setIsSelectAllChecked,
  checkedVitals,
  setCheckedVitals,
  disabled = false,
}: TableFilterButtonProps) => {
  const [visible, setVisible] = useState(false);

  return (
    <Dropdown
      disabled={disabled}
      visible={visible}
      overlay={
        <TableFilterList
          isSelectAllChecked={isSelectAllChecked}
          setIsSelectAllChecked={setIsSelectAllChecked}
          checkedVitals={checkedVitals}
          setCheckedVitals={setCheckedVitals}
        />
      }
      placement="bottomRight"
      trigger={['click']}
      onVisibleChange={flag => setVisible(flag)}
    >
      <FilterButton disabled={disabled} data-testid="table-filter-button" />
    </Dropdown>
  );
};

export default TableFilterButton;
