import React, { useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import Amplify from '@aws-amplify/core';
import { ErrorBoundary } from 'react-error-boundary';
import { useCurrentPatient } from 'context/patients';
import { VitalsProvider } from 'context/vitals';
import { ThresholdsProvider } from 'context/thresholds';
import { DateRangeProvider } from 'context/dateRange';
import {
  UnaddressedAlertsProvider,
  AddressedAlertsProvider,
  PatientAlertsProvider,
  VitalsChartScreenshotsProvider,
} from 'context';
import OfficeActiveAlerts from 'pages/OfficeActiveAlerts/OfficeActiveAlerts';
import PatientVitalsHistory from 'pages/PatientVitalsHistory/PatientVitalsHistory';
import AlertDetails from 'pages/AlertDetails/AlertDetails';
import PatientThresholds from 'pages/PatientThresholds/PatientThresholds';
import OfficeAlertHistory from 'pages/OfficeAlertHistory/OfficeAlertHistory';
import PatientAlertHistory from 'pages/PatientAlertHistory/PatientAlertHistory';
import getAppSyncAuthToken from 'utils/getAppSyncAuthToken/getAppSyncAuthToken';
import PatientInformationSubHeader from '../PatientInformationSubHeader/PatientInformationSubHeader';
import { DisplayContainer } from './DataDisplay.styled';
import { useFallback } from 'hooks';
//@ts-ignore
import awsExports from 'aws-exports';

const DataDisplay = () => {
  const currentPatient = useCurrentPatient();
  const [vitalsView, setVitalsView] = useState<'chart' | 'list'>('chart');

  useEffect(() => {
    Amplify.configure({
      ...awsExports,
      graphql_endpoint:
        'https://wzsjr53b35ffrmwy26vl4hpbbu.appsync-api.us-east-1.amazonaws.com/graphql',
      graphql_headers: () => getAppSyncAuthToken(currentPatient?.xmit_id),
    });
  }, []);

  return (
    <ErrorBoundary FallbackComponent={useFallback}>
      <DisplayContainer>
        <Route
          exact
          path="/"
          render={() => {
            return <Redirect to="/office-active-alerts" />;
          }}
        />

        <DateRangeProvider>
          <Route path="/office-active-alerts">
            <UnaddressedAlertsProvider>
              <OfficeActiveAlerts />
            </UnaddressedAlertsProvider>
          </Route>

          <Route
            path={['/patient-alert-history', '/patient-vitals-history', '/patient-thresholds']}
          >
            <PatientInformationSubHeader />
          </Route>

          <Route path="/patient-alert-history">
            <PatientAlertsProvider>
              <PatientAlertHistory />
            </PatientAlertsProvider>
          </Route>

          <Route path="/patient-vitals-history">
            <ThresholdsProvider>
              <VitalsProvider>
                <UnaddressedAlertsProvider>
                  <VitalsChartScreenshotsProvider>
                    <PatientVitalsHistory vitalsView={vitalsView} setVitalsView={setVitalsView} />
                  </VitalsChartScreenshotsProvider>
                </UnaddressedAlertsProvider>
              </VitalsProvider>
            </ThresholdsProvider>
          </Route>

          <Route path="/patient-thresholds">
            <ThresholdsProvider>
              <PatientThresholds />
            </ThresholdsProvider>
          </Route>

          <Route path="/alert-details">
            <ThresholdsProvider>
              <AlertDetails />
            </ThresholdsProvider>
          </Route>

          <Route path="/office-alert-history">
            <AddressedAlertsProvider>
              <OfficeAlertHistory />
            </AddressedAlertsProvider>
          </Route>
        </DateRangeProvider>
      </DisplayContainer>
    </ErrorBoundary>
  );
};

export default DataDisplay;
