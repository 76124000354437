import ky from 'ky';
import Auth from '@aws-amplify/auth';

// returns JWT token of the logged in user for sdsApps
const getAppSyncAuthToken = async (xmit: string | undefined) => {
  const {
    idToken: { jwtToken },
  } = await Auth.currentSession();

  return ky
    .post('https://8n3r6aww29.execute-api.us-east-1.amazonaws.com/prod', {
      headers: {
        Authorization: jwtToken,
      },
    })
    .json<any>()
    .then(data => ({
      'x-api-key': data.key,
      Authorization: jwtToken,
      xmit,
    }));
};

export default getAppSyncAuthToken;
