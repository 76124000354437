import React from 'react';
import ReactDOM from 'react-dom';
import Analytics, { AWSKinesisFirehoseProvider } from '@aws-amplify/analytics';
import Auth from '@aws-amplify/auth';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { ReactQueryConfigProvider } from 'react-query';
import App from './components/display/App/App';
import { ReactQueryDevtools } from 'react-query-devtools';
import theme from './styles/theme';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

// Amplify setup
Analytics.addPluggable(new AWSKinesisFirehoseProvider());
Analytics.configure({
  AWSKinesisFirehose: {
    //   Amazon Kinesis Firehose service region
    region: 'us-east-1',

    //  The buffer size for events in number of items.
    bufferSize: 1000,

    //  The number of events to be deleted from the buffer when flushed.
    flushSize: 100,

    //  The interval in milliseconds to perform a buffer check and flush if necessary.
    flushInterval: 5000, // 5s

    //  The limit for failed recording retries.
    resendLimit: 5,
  },
});

Auth.configure({
  region: 'us-east-1',
  userPoolId: 'us-east-1_ezvY0ePmb',
  userPoolWebClientId: 's9fi7avt81hipnu2tjgmdlte',
  identityPoolId: 'us-east-1:fe34df84-a594-4ae2-b55b-8526e3eef98c',
});

// react-query config
const queryConfig = {
  queries: {
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  },
};

ReactDOM.render(
  <>
    <ReactQueryConfigProvider config={queryConfig}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </BrowserRouter>
    </ReactQueryConfigProvider>
    <ReactQueryDevtools initialIsOpen={false} />
  </>,
  document.getElementById('root'),
);
