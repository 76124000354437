import styled from 'styled-components';
import { SearchOutlined } from '@ant-design/icons';
import { AutoComplete as Auto } from 'antd';

export const PatientListDropdownContainer = styled.div`
  width: 100%;
  max-width: 400px;
  z-index: 998;

  @media (max-width: 1370px) {
    max-width: 650px;
  }
`;

export const SearchIcon = styled(SearchOutlined)`
  margin-right: 1rem;
`;

export const AutoComplete = styled(Auto)``;
export const AutoCompleteOption = styled(Auto.Option)``;
