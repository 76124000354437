import styled from 'styled-components';
import { FilterFilled } from '@ant-design/icons';
import { Menu } from 'antd';

export const SelectMenu = styled(Menu)`
  box-shadow: rgba(0, 0, 0, 0.12) 0px 3px 6px -4px, rgba(0, 0, 0, 0.08) 0px 6px 16px 0px,
    rgba(0, 0, 0, 0.05) 0px 9px 28px 8px !important;
  z-index: 100;
`;

export const SelectItem = styled(Menu.Item)`
  &:hover {
    background-color: #e4edf2;
  }
`;

export const FilterButton = styled(FilterFilled)`
  font-size: 1.2rem;
  opacity: 0.3;
  margin-right: 0.7rem;
  cursor: not-allowed;

  ${props =>
    props.disabled
      ? null
      : `
  &:hover {
    color: #4777b2;
  }
  
  opacity: 0.7;
  cursor: pointer;
  `}
`;
