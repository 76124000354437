import React from 'react';
import _ from 'lodash';
import { BellOutlined } from '@ant-design/icons';
import { useUnaddressedAlerts } from 'context/unaddressedAlerts';
import { useRoutes } from 'hooks';
import { NotificationButtonContainer, Badge } from './NotificationButton.styled';

const NotificationButton = () => {
  const {
    query: { data },
  } = useUnaddressedAlerts();
  const { pushRouteChange } = useRoutes();

  return (
    <div onClick={() => pushRouteChange({ section: 'office-active-alerts' })}>
      <NotificationButtonContainer>
        <Badge count={data?.length}>
          <BellOutlined />
        </Badge>
      </NotificationButtonContainer>
    </div>
  );
};

export default NotificationButton;
