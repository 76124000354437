import styled from 'styled-components';
import React from 'react';
import { SyncOutlined, ReloadOutlined as Reload } from '@ant-design/icons';
import { Body } from 'elements';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const SyncInformation = styled.div`
  height: 28px;
  display: flex;
  align-items: center;
`;

export const SyncIcon = styled(SyncOutlined)`
  font-size: 0.9rem;
  color: rgba(0, 0, 0, 0.25);
`;

export const UpdatedAtText = styled(({ enabled, ...rest }: any) => <Body {...rest} />)`
  ${props =>
    props.enabled &&
    `
    &: hover {
      color: rgba(0, 0, 0, 0.75) !important;
    }
    cursor: pointer;
  `}

  margin: 0;
  font-size: 0.9rem;
  color: rgba(0, 0, 0, 0.25);
  height: 28px;
  transition-duration: 300ms;
`;

export const ReloadOutlined = styled(Reload)`
  margin-left: 0.2rem;
  padding: 0.2rem;
  border-radius: 100%;
`;
