type vitalsTypeToPretty = {
  [key in Vital]: PrettyVital;
};

const vitalsTypeToPretty: vitalsTypeToPretty = {
  heartRate: 'Heart Rate',
  oxygen: 'Oxygen',
  glucose: 'Glucose',
  temperature: 'Temperature',
  weight: 'Weight',
  systolic: 'Systolic',
  diastolic: 'Diastolic',
  fev1: 'Fev 1',
  pef: 'Pef',
};

export default vitalsTypeToPretty;
