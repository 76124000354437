import styled from 'styled-components';

export const GenericAlertTitle = styled.div`
  @media (max-width: 520px) {
    font-size: 32px;
  }
  @media (min-width: 521px) {
    font-size: 40px;
  }
  text-align: center;
  font-weight: 500;
  color: $ecg-danger;
  filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.2));
`;
