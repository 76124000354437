type prettyToAnelto = {
  [key in PrettyVital]: AneltoVital;
};

const prettyToAnelto: prettyToAnelto = {
  Oxygen: 'Oxygen',
  Systolic: 'Systolic',
  Diastolic: 'Diastolic',
  Glucose: 'Glucose',
  'Heart Rate': 'BPM',
  Weight: 'Weight',
  Temperature: 'Thermometer',
  'Fev 1': 'Fev1',
  Pef: 'Pef',
};

export default prettyToAnelto;
