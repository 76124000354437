import React from 'react';
import { motion, useReducedMotion, MotionValue } from 'framer-motion';
import styled from 'styled-components';

type ExportModalAnimationProps = {
  children: React.ReactNode;
};

export const Container = styled(motion.div)`
  position: fixed;
  background-color: white;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;
  display: grid;
  grid-template-columns: 1fr 2fr;
  overflow: hidden;
`;

const ExportModalAnimation = ({ children }: ExportModalAnimationProps) => {
  const shouldReduceMotion = useReducedMotion();

  if (shouldReduceMotion) {
    return <div>{children}</div>;
  }

  return (
    <Container
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.15 }}
      exit={{ opacity: 0 }}
    >
      {children}
    </Container>
  );
};

export default ExportModalAnimation;
