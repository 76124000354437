import styled from 'styled-components';
import { Empty, Skeleton } from 'antd';
import { SubHeader } from 'elements';

export const Card = styled.div`
  width: 100%;
  background-color: white;
  padding: 20px 20px 20px 20px;
  overflow: hidden;
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  height: 300px;
`;

export const ChartTitle = styled(SubHeader)`
  height: 10%;
  padding-left: 1.25rem;
  margin: 0 !important;
`;

export const ChartContainer = styled.div`
  height: 100%;
  width: 100%;
  margin-left: -5%;
`;

export const NoData = styled(Empty)`
  padding-bottom: 40px;
`;

export const LoadingSkeleton = styled(Skeleton)`
  padding-bottom: 40px;
`;

export const TestWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TooltipCard = styled.div`
  background-color: #fff;
  background-clip: padding-box;
  border-radius: ${props => props.theme.borderRadius};
  box-shadow: ${props => props.theme.boxShadow};
`;

export const TooltipTitle = styled.h4`
  min-width: 177px;
  min-height: 32px;
  margin: 0;
  padding: 5px 16px 4px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  border-bottom: 1px solid #e8e8e8;
`;

export const TooltipContent = styled.div`
  padding: 12px 16px;
  color: rgba(0, 0, 0, 0.65);
`;

export const TooltipUnits = styled.span`
  opacity: 0.5;
  font-size: 0.6rem;
`;
