import styled from 'styled-components';
import { Dropdown, Layout, Menu } from 'antd';

export const Container = styled(Layout.Header)`
  height: auto;
  background: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  z-index: 5;
  padding: 0 32px;
`;

export const UserInfo = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
`;

export const SignOutButton = styled(Menu.Item)`
  &:hover {
    background-color: #4bcbf3 !important;
    color: #0b2545 !important;
  }
`;

export const AvatarUsername = styled.a`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-align: center;
  justify-content: center;
`;

export const Username = styled.span`
  @media (max-width: 900px) {
    display: none;
  }
`;
