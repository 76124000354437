import React, { useMemo } from 'react';
import {
  SurveyRenderer,
  SurveyTitle,
  SurveySubtitle,
  QuestionList,
  QuestionItem,
  Title,
  Duration,
  Body,
  LowerBody,
  Number,
  Answer,
} from './Survey.styled';
import parseFlag from './utils/parseFlag';

type SurveyProps = {
  alert: Alert;
};

const Survey = ({ alert }: SurveyProps) => {
  const { flag } = alert;
  const [surveyName, questions, status] = useMemo(() => parseFlag(flag), [flag]);

  return (
    <SurveyRenderer>
      <SurveyTitle>{surveyName}</SurveyTitle>
      <SurveySubtitle>{status}</SurveySubtitle>
      <QuestionList>
        {questions.map(({ question, answer, duration, highlighted }: any, index: number) => {
          const danger = highlighted;
          return (
            <QuestionItem key={question}>
              <Number danger={danger}>{index + 1}</Number>
              <Body danger={danger}>
                <Title danger={danger}>{question}</Title>
                <LowerBody danger={danger}>
                  <Answer>&ldquo;{answer}&rdquo;</Answer>
                  <Duration danger={danger}>Answered in {duration / 1000}s</Duration>
                </LowerBody>
              </Body>
            </QuestionItem>
          );
        })}
      </QuestionList>
    </SurveyRenderer>
  );
};

export default Survey;
