import generatePNG from './generatePNG';
import { ComposedChart } from 'recharts';

type generateChartImages = (args: {
  screenshots: {
    [key in Vital]?: React.RefObject<ComposedChart> | null;
  };
}) => Promise<
  {
    [key in Vital]?: string;
  }
>;

const generateChartImages: generateChartImages = async ({ screenshots }) => {
  if (!screenshots) return {};

  // if this isn't here, the first generatePNG won't finish updating
  // before the screenshot is taken.
  await generatePNG({ ref: screenshots.oxygen });

  const chartImages = {
    oxygen: await generatePNG({ ref: screenshots.oxygen }),
    glucose: await generatePNG({ ref: screenshots.glucose }),
    temperature: await generatePNG({ ref: screenshots.temperature }),
    weight: await generatePNG({ ref: screenshots.weight }),
    systolic: await generatePNG({ ref: screenshots.systolic }),
    fev1: await generatePNG({ ref: screenshots.fev1 }),
    pef: await generatePNG({ ref: screenshots.pef }),
    heartRate: await generatePNG({ ref: screenshots.heartRate }),
  };

  return chartImages;
};

export default generateChartImages;
