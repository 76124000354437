import React, { useState, useRef, useEffect } from 'react';
import { AutoComplete } from 'antd';
import { useRoutes } from 'hooks';
import { useCurrentOffice, useOfficeList } from 'context/offices';
import { Container, SearchIcon } from './OfficeDropdown.styled';

const OfficeDropdown = () => {
  const { data: offices, isLoading } = useOfficeList();
  const currentOffice = useCurrentOffice();
  const [searchValue, setSearchValue] = useState('');
  const selectorRef: any = useRef(null);
  const { pushRouteChange } = useRoutes();

  useEffect(() => {
    if (currentOffice === undefined) setSearchValue('');
    else if (currentOffice?.description) setSearchValue(currentOffice.description);
  }, [currentOffice]);

  return (
    <Container>
      <AutoComplete
        ref={selectorRef}
        placeholder={
          <span>
            <SearchIcon />
            Search Offices
          </span>
        }
        defaultValue={currentOffice?.description}
        value={searchValue}
        onSelect={(officeID, event) => {
          if (selectorRef.current) selectorRef.current.blur();
          if (event.props.value) {
            pushRouteChange({ office: officeID });
          } else pushRouteChange({ office: '', patient: '' });
        }}
        onFocus={() => {
          setSearchValue('');
          pushRouteChange({ patient: '' });
        }}
        onChange={(val, index: any) => {
          setSearchValue(index.children);
        }}
        disabled={isLoading}
        filterOption={(input, option: any) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        style={{ width: '100%' }}
      >
        {offices?.map(office => (
          <AutoComplete.Option value={office.officeID} key={office.officeID}>
            {office.description}
          </AutoComplete.Option>
        ))}
      </AutoComplete>
    </Container>
  );
};

export default OfficeDropdown;
