import React from 'react';
import { Button } from 'elements';
import { CSVLink } from 'react-csv';
import { useFilteredAlerts } from 'hooks';
import moment from 'moment';
import { usePatientAlerts } from 'context/patientAlerts';
import { useCurrentPatient } from 'context/patients';

type PatientAlertHistoryCSVProps = {
  dateRange: [moment.Moment, moment.Moment];
};

const PatientAlertHistoryCSV = ({ dateRange }: PatientAlertHistoryCSVProps) => {
  const { data } = usePatientAlerts();
  const currentPatient = useCurrentPatient();
  const patientAlerts = useFilteredAlerts({ data: data || [], dateRange });
  const title = `${currentPatient?.customer_name} Patient Alert History ${moment().format(
    'MM-DD-YYYY',
  )}`;

  const headers = [
    { label: 'Addressed Time', key: 'addressedTime' },
    { label: 'Patient', key: 'patient' },
    { label: 'Type', key: 'type' },
    { label: 'Issue', key: 'issue' },
    { label: 'Measured At', key: 'measuredAt' },
    { label: 'Limit Over', key: 'limitOver' },
  ];

  return (
    <CSVLink data={patientAlerts} headers={headers} filename={`${title}.csv`} target="_blank">
      <Button>Export</Button>
    </CSVLink>
  );
};

export default PatientAlertHistoryCSV;
