import React from 'react';
import { motion, useReducedMotion } from 'framer-motion';

type VitalsChartCardProps = {
  children: React.ReactNode;
  delay: number;
};

const VitalsChartCard = ({ children, delay }: VitalsChartCardProps) => {
  const shouldReduceMotion = useReducedMotion();

  if (shouldReduceMotion) {
    return <div>{children}</div>;
  }

  return (
    <motion.div
      initial={{ y: 10, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ duration: 0.3, delay }}
    >
      {children}
    </motion.div>
  );
};

export default VitalsChartCard;
