import React from 'react';
import styled, { keyframes } from 'styled-components';
import theme from 'styles/theme';

type ButtonProps = {
  displayType: 'primary' | 'secondary' | 'success' | 'danger' | 'link';
  disabled: boolean;
  loading: boolean;
  onClick?: (event: React.SyntheticEvent<HTMLButtonElement>) => void;
  children: React.ReactNode;
};

export const Container = styled(({ loading, displayType, children, ...rest }: ButtonProps) => (
  <button {...rest} type="button">
    {children}
  </button>
))`
  all: unset;

  /* dynamically set the background-color */
  background-color: ${({ displayType }) => {
    if (displayType === 'secondary') return theme.steel2;
    if (displayType === 'success') return theme.green2;
    if (displayType === 'danger') return theme.danger2;
    if (displayType === 'link') return 'rgba(0, 0, 0, 0)';
    return theme.primary2;
  }};

  /* dynamically set the text color */
  color: ${({ displayType }) => {
    if (displayType === 'secondary') return theme.textDark;
    if (displayType === 'link') return theme.primary2;
    return 'white';
  }};

  position: relative;
  border-radius: ${theme.borderRadius};
  cursor: pointer;
  padding: 8px 12px;
  text-align: center;
  font-family: 'Open Sans';
  transition: all 0.2s;
  user-select: none;
  outline: 0;
  margin: 0;
  font-weight: bold;
  font-size: 0.8rem;

  /* hover css (when not disabled) */
  &:hover:not([disabled]) {
    color: ${({ displayType }) => (displayType === 'link' ? theme.primary1 : 'white')};

    background-color: ${({ displayType }) => {
      if (displayType === 'secondary') return theme.steel3;
      if (displayType === 'success') return theme.green1;
      if (displayType === 'danger') return theme.danger1;
      if (displayType === 'link') return 'rgba(0, 0, 0, 0)';
      return theme.primary1;
    }};
  }

  /* Firefox fix for focus outline */
  ::-moz-focus-inner {
    border: 0;
  }

  /* gray out the button when disabled */
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

// loading spinner css
const motion = () => keyframes`
  0% {
      transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const SpinnerWrapper = styled.div`
  display: inline-block;
  position: relative;
  width: 14px;
  height: 14px;
  margin: 0 10px 0 0;
`;

const RingSpinner = styled.div<{ color: string; size: string; sizeUnit: string }>`
  box-sizing: border-box;
  display: block;
  width: ${p => `${p.size}${p.sizeUnit}`};
  height: ${p => `${p.size}${p.sizeUnit}`};
  margin: 0;
  border: 2px solid ${p => p.color};
  border-radius: 50%;
  animation: ${() => motion()} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: ${p => p.color} transparent transparent transparent;
  :nth-child(1) {
    animation-delay: -0.45s;
  }
  :nth-child(2) {
    animation-delay: -0.3s;
  }
  :nth-child(3) {
    animation-delay: -0.15s;
  }
`;

export const Spinner = () => (
  <SpinnerWrapper>
    <RingSpinner color="white" size="16" sizeUnit="px" />
  </SpinnerWrapper>
);
