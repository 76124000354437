import React from 'react';
import { GenericAlertTitle } from './GenericAlert.styled';

type GenericAlertProps = {
  alert: Alert;
};

const GenericAlert = ({ alert }: GenericAlertProps) => {
  const { flag } = alert;
  return <GenericAlertTitle>{flag}</GenericAlertTitle>;
};

export default GenericAlert;
