import React, { useEffect } from 'react';
import _ from 'lodash';
import { useKinesis, useRoutes, useCognitoUser, useSingleAlert, useUnauthorized } from 'hooks';
import { useOfficeList } from 'context/offices';
import { usePatientList, useCurrentPatient } from 'context/patients';
import { EmptyIcon, LoadingIcon, Unauthorized } from 'elements';
import AlertDetailCard from 'components/alerts/AlertDetailCard/AlertDetailCard';
import ResponsibleParties from 'components/alerts/ResponsibleParties/ResponsibleParties';
import NotesField from 'components/alerts/NotesField/NotesField';
import { AlertDetailsContainer, AlertDetailRow } from './AlertDetails.styled';

const AlertDetails = () => {
  const {
    queryParams: { xmit: account, timestamp },
  } = useRoutes();
  const group = useCognitoUser();
  const offices = useOfficeList();
  const patientList = usePatientList();
  const currentPatient = useCurrentPatient();
  const singleAlert = useSingleAlert({ group, timestamp, account });

  const { isAuthorized } = useUnauthorized({ singleAlert });
  useKinesis();
  if (singleAlert.isLoading || patientList.isLoading || offices.isLoading) return <LoadingIcon />;
  if (!isAuthorized) return <Unauthorized />;
  if (singleAlert.data === undefined) return <EmptyIcon />;

  return (
    <AlertDetailsContainer>
      <AlertDetailRow>
        <AlertDetailCard
          fetchedAlert={singleAlert.data}
          patientName={currentPatient?.customer_name}
          timestamp={timestamp}
        />
        <ResponsibleParties
          patientName={currentPatient?.customer_name}
          patientList={patientList.data}
          fetchedAlert={singleAlert.data}
        />
      </AlertDetailRow>
      <AlertDetailRow>
        <NotesField data={singleAlert.data} isFetching={singleAlert.isFetching} />
      </AlertDetailRow>
    </AlertDetailsContainer>
  );
};

export default AlertDetails;
