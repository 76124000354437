import React from 'react';
import dayjs from 'dayjs';

import useAlertPop from './utils/useAlertPop/useAlertPop';
import { getDetailView, getAlertType, getOfficeId } from './utils/selectors/selectors';
import {
  AlertDetailCardContainer,
  CardTitle,
  CardContainer,
  Header,
  HeaderTitle,
  WarningOutlined,
  Separator,
  DateContainer,
  CardDate,
  ViewContainer,
} from './AlertDetailCare.styled';

const NBSP = () => <span dangerouslySetInnerHTML={{ __html: '&nbsp;' }} />;

type AlertDetailCardProps = {
  fetchedAlert: Alert;
  patientName: string | undefined;
  timestamp: string | undefined;
};

const AlertDetailCard = ({ fetchedAlert, patientName, timestamp }: AlertDetailCardProps) => {
  const DetailView = getDetailView(getAlertType(fetchedAlert));
  const alertStyles = useAlertPop();

  return (
    <AlertDetailCardContainer style={alertStyles}>
      <CardTitle>Alert</CardTitle>
      <CardContainer>
        <Header>
          <HeaderTitle>
            {getOfficeId(fetchedAlert)}
            {' >'}
            <NBSP />
            <strong>{patientName}</strong>
          </HeaderTitle>
          <WarningOutlined />
        </Header>
        <Separator />
        <DateContainer>
          <CardDate>{dayjs(timestamp).format('hh:mm:ss A')}</CardDate>
          <CardDate>{dayjs(timestamp).format('MMMM DD, YYYY')}</CardDate>
        </DateContainer>
        <ViewContainer>
          <DetailView alert={fetchedAlert} />
        </ViewContainer>
      </CardContainer>
    </AlertDetailCardContainer>
  );
};

export default AlertDetailCard;
