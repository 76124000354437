import React, { useEffect } from 'react';
import { I18n } from '@aws-amplify/core';
import { withAuthenticator } from 'aws-amplify-react';
import Lottie from 'react-lottie';
import _ from 'lodash';
import { SignIn, VerifyContact } from 'aws-amplify-react/dist/Auth';
import ForgotPassword from 'aws-amplify-react/dist/Auth/ForgotPassword';
import ConfirmSignIn from 'aws-amplify-react/dist/Auth/ConfirmSignIn';
import RequireNewPassword from 'aws-amplify-react/dist/Auth/RequireNewPassword';
import { Layout } from 'antd';
import { PatientsProvider } from 'context/patients';
import { OfficesProvider } from 'context/offices';
import { useErrorLogging, useIdleTimer } from 'hooks';
import UserLoginAddison from 'animations/UserLoginAddison.json';
import animationAddisonCareLogo from 'animations/LoginAnim_AddisonCareLogo.json';
import animationLogoToAddison from 'animations/LoginAnim_LogoToAddison.json';
import animationAddisonTablet from 'animations/LoginAnim_AddisonWTablet.json';
import animationSunglasses from 'animations/LoginAnim_Sunglasses.json';
import { GlobalStylesProvider } from 'styles/globalStyles';
import Header from '../Header/Header';
import DataDisplay from '../DataDisplay/DataDisplay';
import Sider from '../Sider/Sider';
import { AppLayout, LoginTheme } from './App.styled';

const App = () => {
  useErrorLogging();
  useIdleTimer();

  return (
    <OfficesProvider>
      <PatientsProvider>
        <GlobalStylesProvider>
          <AppLayout>
            <Sider />

            <Layout>
              <Header />
              <DataDisplay />
            </Layout>
          </AppLayout>
        </GlobalStylesProvider>
      </PatientsProvider>
    </OfficesProvider>
  );
};

// replace screen label on Authenticator component
const authScreenLabels = {
  en: {
    'Sign in to your account': (() => (
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: [
            UserLoginAddison,
            animationAddisonCareLogo,
            animationLogoToAddison,
            animationAddisonTablet,
            animationSunglasses,
          ][_.random(0, 4)],
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid meet',
          },
        }}
        height={150}
        width={150}
      />
    ))(),
  },
};

I18n.setLanguage('en');
I18n.putVocabularies(authScreenLabels);

export default withAuthenticator(
  App,
  false,
  [<SignIn />, <ForgotPassword />, <ConfirmSignIn />, <VerifyContact />, <RequireNewPassword />],
  null,
  LoginTheme,
);
