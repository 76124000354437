import React from 'react';
import { Button } from 'elements';
import { CSVLink } from 'react-csv';
import { useFilteredAlerts } from 'hooks';
import moment from 'moment';
import { useCurrentOffice } from 'context/offices';
import { useAddressedAlerts } from 'context/addressedAlerts';

type OfficeAlertHistoryCSVProps = {
  dateRange: [moment.Moment, moment.Moment];
};

const OfficeAlertHistoryCSV = ({ dateRange }: OfficeAlertHistoryCSVProps) => {
  const { query } = useAddressedAlerts();
  const currentOffice = useCurrentOffice();
  const addressedAlerts = useFilteredAlerts({ data: query.data || [], dateRange });
  const title = `${currentOffice?.description} Office Alert History ${moment().format(
    'MM-DD-YYYY',
  )}`;

  const headers = [
    { label: 'Addressed Time', key: 'addressedTime' },
    { label: 'Patient', key: 'patient' },
    { label: 'Type', key: 'type' },
    { label: 'Issue', key: 'issue' },
    { label: 'Measured At', key: 'measuredAt' },
    { label: 'Limit Over', key: 'limitOver' },
  ];

  return (
    <CSVLink data={addressedAlerts} headers={headers} filename={`${title}.csv`} target="_blank">
      <Button>Export</Button>
    </CSVLink>
  );
};

export default OfficeAlertHistoryCSV;
