import API, { graphqlOperation } from '@aws-amplify/api/lib';
import Auth from '@aws-amplify/auth';
import getUserOffices from 'gql/getUserOffices';

const pullOfficeList = async () => {
  const loggedInUserData = await Auth.currentSession();
  const group = loggedInUserData.accessToken.payload['cognito:groups']?.[0];

  // if provider isn't apart of a group, don't attempt fetch the office list
  if (!group) return [];

  const {
    data: {
      listOfficeIDS: { items: offices },
    },
  } = (await API.graphql(
    graphqlOperation(getUserOffices, {
      group,
    }),
  )) as {
    data: {
      listOfficeIDS: {
        items: Office[];
      };
    };
  };

  const sortedOffices = offices.sort((a, b) =>
    a.description.trim().localeCompare(b.description.trim()),
  );

  return sortedOffices;
};

export default pullOfficeList;
