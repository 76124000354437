import moment from 'moment';
import _ from 'lodash';
import { vitalsTypes } from 'dictionary';
import { ThresholdsContext } from 'context/thresholds';
import isVitalOutsideOfThreshold from './isVitalOutsideOfThreshold';

type convertToTableVitals = (args: {
  vitals: VitalsReading[] | undefined;
  thresholds: ThresholdsContext;
  activeAlerts: Alert[] | undefined;
  tableSort: 'asc' | 'desc';
  isSelectAllChecked: boolean;
  checkedVitals: Vital[];
}) => TableVitalsReading[];

/**
 * Each VitalsReading's value is in a different object attribute, and some vitals readings have
 * more then one value. We need this function to separate out all the readings and combine them
 * into a structure with a common accessor.
 */
const convertToTableVitals: convertToTableVitals = ({
  vitals,
  thresholds,
  activeAlerts,
  tableSort,
  isSelectAllChecked,
  checkedVitals,
}) => {
  if (!activeAlerts || !vitals) return [];

  // sort by timestamp
  const tableVitals: TableVitalsReading[] =
    // for each vital type
    vitalsTypes.flatMap(type => {
      return (
        vitals
          .filter(reading => {
            // filter out diastolic (systolic has both)
            if (type === 'diastolic') return false;

            // respect the table filter
            if (!checkedVitals.includes(type) && !isSelectAllChecked) return false;

            // filter out any vital that doesn't match that type
            return reading[type];
          })
          // map each reading into a structure with a common accessor
          .map(reading => {
            const alert = isVitalOutsideOfThreshold({ reading, thresholds, type, activeAlerts });

            const item: TableVitalsReading = {
              timestamp: moment(reading.timestamp).valueOf(),
              value:
                type === 'systolic'
                  ? `${reading.systolic}/${reading.diastolic}`
                  : reading[type] || '',
              type,
              alert,
            };

            return item;
          })
      );
    });

  // ascending
  if (tableSort === 'asc')
    return tableVitals.sort(
      (a, z) => moment(a.timestamp).valueOf() - moment(z.timestamp).valueOf(),
    );

  // descending
  return tableVitals.sort((a, z) => moment(z.timestamp).valueOf() - moment(a.timestamp).valueOf());
};

export default convertToTableVitals;
