// used for the export PDF functionality

const vitalsTypeToCondensed = {
  heartRate: 'HR',
  oxygen: 'O2',
  glucose: 'BG',
  temperature: 'BBT',
  weight: 'WT',
  systolic: 'SBP',
  diastolic: 'DBP',
  fev1: 'FEV1',
  pef: 'PEF',
};

export default vitalsTypeToCondensed;
