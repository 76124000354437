import React, { useMemo } from 'react';

import ResponsiblePartyCard from '../ResponsiblePartyCard/RepsonsiblePartyCard';

import { getResponsibleParties } from './utils/selectors/selectors';
import usePartyFade from './utils/usePartyFade/usePartyFade';
import {
  ResponsiblePartiesContainer,
  Title,
  NoParty,
  TitleMissing,
  PartyStyles,
} from './ResponsibleParties.styled';

type ResponsiblePartiesProps = {
  patientList: Patient[] | undefined;
  fetchedAlert: Alert;
  patientName: string | undefined;
};

const ResponsibleParties = ({
  patientList,
  fetchedAlert,
  patientName,
}: ResponsiblePartiesProps) => {
  const responsibleParties = useMemo(() => getResponsibleParties(patientList, fetchedAlert), [
    patientList,
    fetchedAlert,
  ]);
  const [animatedStyles, animatedParties]: any = usePartyFade(responsibleParties);

  if (responsibleParties.length === 0)
    return (
      <ResponsiblePartiesContainer style={animatedStyles}>
        <Title>Responsible Parties</Title>
        <NoParty>
          <TitleMissing>{patientName} has no responsible parties</TitleMissing>
        </NoParty>
      </ResponsiblePartiesContainer>
    );
  return (
    <ResponsiblePartiesContainer style={animatedStyles}>
      <Title>Responsible Parties</Title>
      {animatedParties.map(({ item: { name, relationship, phone }, props: partyStyles }: any) => (
        <PartyStyles style={partyStyles} key={name}>
          <ResponsiblePartyCard name={name} relationship={relationship} phone={phone} />
        </PartyStyles>
      ))}
    </ResponsiblePartiesContainer>
  );
};

export default ResponsibleParties;
