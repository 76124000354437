import React, { useEffect, useState } from 'react';
import { Table, Divider, notification } from 'antd';
import { usePageSlide, useUnauthorized } from 'hooks';
import { Button, Unauthorized } from 'elements';

import _ from 'lodash';
import { useCurrentPatient } from 'context/patients';
import { LoadingIcon, EmptyIcon } from 'elements';
import { ThresholdsContext } from 'context/thresholds';
import { usePatientList } from 'context/patients';
import { useOfficeList } from 'context/offices';

import {
  TableHeadHead,
  ArrowDownOutlined,
  ArrowUpOutlined,
  OverriddenThreshold,
  ThresholdsInput,
  TableContainer,
} from './ThresholdsTable.styled';

type ThresholdsTableProps = {
  thresholds: ThresholdsContext;
};

const ThresholdsTable = ({
  thresholds: {
    patientThresholds: { data: patientThresholds, isLoading: isLoadingPatientThresholds },
    groupThresholds: { data: groupThresholds, isLoading: isLoadingGroupThresholds },
    updatePatientThresholds: [
      updateThresholds,
      { isSuccess, reset, isError, isLoading: isPosting },
    ],
  },
}: ThresholdsTableProps) => {
  const { isAuthorized } = useUnauthorized();
  const patientList = usePatientList();
  const offices = useOfficeList();

  const animatedStyles = usePageSlide();

  const currentPatient = useCurrentPatient();

  // index of the current row
  const [editingKey, setEditingKey] = useState<string>('');

  // local state for the patient minimum input
  const [patientMin, setPatientMin] = useState<string>('');

  // local state for the patient maximum input
  const [patientMax, setPatientMax] = useState<string>('');

  // reset the mutation and table state when a threshold is updated
  useEffect(() => {
    if (isSuccess) {
      reset();

      setEditingKey('');

      // reset input fields
      setPatientMin('');
      setPatientMax('');
    }

    if (isError) {
      reset();

      setEditingKey('');

      // reset input fields
      setPatientMin('');
      setPatientMax('');
    }
  }, [isSuccess, isError, reset]);

  // reset the editing key when selected patient changes
  useEffect(() => {
    setEditingKey('');
  }, [currentPatient]);

  //  check if the current selected row is matches the key in local state
  const isEditing = (rowRecord: any) => rowRecord.key === editingKey;

  const formattedGroupThresholds = _.map(groupThresholds, (groupThreshold, key) => ({
    vital: _.startCase(key),
    groupMin: groupThreshold.min,
    groupMax: groupThreshold.max,
  }));

  const formattedPatientThresholds = _.map(patientThresholds, (patientThreshold, key) => ({
    vital: _.startCase(key),
    patientMin: patientThreshold.min,
    patientMax: patientThreshold.max,
  }));

  // merge the patient and group thresholds into one array of objects
  const mergedThresholds = formattedPatientThresholds.map((group, idx) => {
    const patient = formattedGroupThresholds.find(o => o.vital === group.vital);
    return { key: idx, ...group, ...patient };
  });

  const columns = [
    {
      title: () => <TableHeadHead>Vital</TableHeadHead>,
      dataIndex: 'vital',
      key: 'vital',
      width: 120,
    },
    {
      title: () => <TableHeadHead>Standard Group Thresholds</TableHeadHead>,
      width: 100,
      children: [
        {
          title: 'Low',
          key: 'groupMin',
          dataIndex: 'groupMin',
          render: (threshold: any, { patientMin: renamedPatMin }: any) =>
            threshold ? (
              <OverriddenThreshold renamedPatMin={renamedPatMin}>
                <ArrowDownOutlined customPatient={false} />
                {threshold}
              </OverriddenThreshold>
            ) : (
              <span>-</span>
            ),
        },
        {
          title: 'High',
          key: 'groupMax',
          dataIndex: 'groupMax',
          render: (threshold: any, { patientMax: renamedPatMax }: any) =>
            threshold ? (
              <OverriddenThreshold renamedPatMax={renamedPatMax}>
                <ArrowUpOutlined customPatient={false} />
                {threshold}
              </OverriddenThreshold>
            ) : (
              <span>-</span>
            ),
        },
      ],
    },
    {
      title: () => <TableHeadHead>Custom Patient Thresholds</TableHeadHead>,
      width: 100,
      children: [
        {
          title: 'Low',
          key: 'patientMin',
          dataIndex: 'patientMin',
          render: (value: any, rowRecord: any, index: any) => {
            return (
              <>
                {rowRecord.patientMin && <ArrowDownOutlined customPatient={true} />}
                <ThresholdsInput
                  type={'number'}
                  data-testid={`${rowRecord.key}-min`}
                  key={
                    // cause a rerender if the row is not being edited
                    isEditing(rowRecord)
                      ? `${value}-${rowRecord}`
                      : `${value}-${rowRecord}-${index}`
                  }
                  disabled={!isEditing(rowRecord) || isPosting}
                  defaultValue={
                    isEditing(rowRecord) ? patientMin || rowRecord.patientMin : rowRecord.patientMin
                  }
                  onChange={e => setPatientMin(e.target.value)}
                />
              </>
            );
          },
        },
        {
          title: 'High',
          key: 'patientMax',
          dataIndex: 'patientMax',
          render: (value: any, rowRecord: any, index: any) => (
            <>
              {rowRecord.patientMax && <ArrowUpOutlined customPatient={true} />}
              <ThresholdsInput
                type={'number'}
                data-testid={`${rowRecord.key}-max`}
                key={
                  // cause a rerender if the row is not being edited
                  isEditing(rowRecord) ? `${value}-${rowRecord}` : `${value}-${rowRecord}-${index}`
                }
                disabled={!isEditing(rowRecord) || isPosting}
                defaultValue={
                  isEditing(rowRecord) ? patientMax || rowRecord.patientMax : rowRecord.patientMax
                }
                onChange={e => setPatientMax(e.target.value)}
              />
            </>
          ),
        },
      ],
    },
    {
      title: '',
      dataIndex: 'operation',
      key: 'operation',
      width: 250,
      render: (text: any, rowRecord: any) => {
        const disabled = editingKey !== '' || !currentPatient;

        // if row not being edited give option todo so
        return !isEditing(rowRecord) ? (
          <span>
            <Button
              type="link"
              disabled={disabled}
              onClick={() => {
                // set local state values on row edit
                setEditingKey(rowRecord.key);
                setPatientMin(rowRecord.patientMin);
                setPatientMax(rowRecord.patientMax);
              }}
            >
              Edit
            </Button>
          </span>
        ) : (
          // if row being edited give option to submit or cancel
          <span>
            <Button
              onClick={() => {
                updateThresholds({
                  rowRecord,
                  patientMin,
                  patientMax,
                  patientThresholds: patientThresholds as PatientThresholds,
                });
              }}
              disabled={
                isPosting ||
                patientMin === '' ||
                patientMax === '' ||
                Number(patientMin) >= Number(patientMax)
              }
            >
              Save
            </Button>
            <Divider type="vertical" />
            <Button
              type="link"
              disabled={isPosting}
              onClick={() => {
                setPatientMin(rowRecord.patientMin);
                setPatientMax(rowRecord.patientMax);
                setEditingKey('');
              }}
            >
              Cancel
            </Button>
          </span>
        );
      },
    },
  ];

  if (
    isLoadingGroupThresholds ||
    isLoadingPatientThresholds ||
    patientList.isLoading ||
    offices.isLoading
  ) {
    return <LoadingIcon />;
  }

  if (!isAuthorized) return <Unauthorized />;

  if (!currentPatient) {
    return <EmptyIcon />;
  }

  return (
    <TableContainer style={{ ...animatedStyles, height: '100%' }}>
      <Table
        pagination={false}
        columns={columns}
        bordered
        size="middle"
        dataSource={mergedThresholds}
      />
    </TableContainer>
  );
};

export default ThresholdsTable;
