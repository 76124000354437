import React, { useState, useEffect } from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Checkbox } from 'antd';
import { Button } from 'elements';
import moment from 'moment';
import _ from 'lodash';
import { AutoSizer, Column, Table } from 'react-virtualized';
import { usePageSlide } from 'hooks';
import {
  GenericTableContainer,
  AutoSizerContainer,
  Empty,
  LoadingContainer,
} from './GenericTable.styled';

type GenericTableProps = {
  tableData: Alert[] | undefined;
  isLoading?: boolean;
  tableAttributes: any[];
  checked?: any[];
  setChecked?: Function;
  defaultChecked?: any[];
};

const GenericTable = ({
  tableData,
  isLoading,
  tableAttributes,
  checked = [],
  setChecked = () => {},
  defaultChecked = [],
}: GenericTableProps) => {
  const [index, setIndex] = useState<any>();
  const [displayData, setDisplayData] = useState<any[]>([]);
  const animatedStyles = usePageSlide();

  // reset checkboxes when data changes
  useEffect(() => {
    setChecked(defaultChecked);
    setIndex(0);
  }, [isLoading]);

  useEffect(() => {
    if (tableData !== undefined) setDisplayData(isLoading ? [] : tableData);
  }, [isLoading, tableData]);

  const cellRenderTypes: any = {
    checkbox: {
      // pass the index of the selected row to the cell renderer
      cellRenderer: ({ rowIndex }: any) => (
        <Checkbox
          checked={(() => checked.includes(rowIndex))()}
          onChange={() =>
            checked.includes(rowIndex)
              ? setChecked(checked.filter(item => item !== rowIndex))
              : setChecked([...checked, rowIndex])
          }
        />
      ),
    },
    button: {
      // pass the index of the selected row to the cell renderer
      cellRenderer: ({ rowIndex, columnIndex }: any) => (
        <Button
          type="link"
          onClick={() => tableAttributes[columnIndex].onClick(displayData[rowIndex])}
        >
          {tableAttributes[columnIndex].buttonText}
        </Button>
      ),
    },
    iconString: {
      // pass the index of the selected row to the cell renderer
      cellRenderer: ({ rowIndex, columnIndex }: any) => {
        if (!displayData[rowIndex][tableAttributes[columnIndex].attributeName]) return <div>-</div>;
        return (
          <div>
            {displayData[rowIndex][tableAttributes[columnIndex].attributeName] && (
              <LegacyIcon
                type={tableAttributes[columnIndex].icon}
                style={{
                  color: tableAttributes[columnIndex].iconColor,
                  marginRight: 5,
                }}
              />
            )}
            {`${displayData[rowIndex][tableAttributes[columnIndex].attributeName]}  `}
          </div>
        );
      },
    },
    timestamp: {
      // pass the index of the selected row to the cell renderer
      cellRenderer: ({ rowIndex, columnIndex }: any) => (
        <div data-testid={`timestamp-row-${rowIndex}`}>
          {moment(displayData[rowIndex][tableAttributes[columnIndex].attributeName]).isValid()
            ? moment
                .utc(displayData[rowIndex][tableAttributes[columnIndex].attributeName])
                .local()
                .format('MMM DD YYYY, h:mm:ss A')
            : moment
                .utc(
                  displayData[rowIndex][tableAttributes[columnIndex].attributeName],
                  'MM-DD-YYYY hh:mm A',
                )
                .local()
                .format('MMM DD YYYY, h:mm:ss A')}
        </div>
      ),
    },
    string: {
      // pass the index of the selected row to the cell renderer
      cellRenderer: ({ rowIndex, columnIndex }: any) => {
        if (!displayData[rowIndex][tableAttributes[columnIndex].attributeName]) return <div>-</div>;
        return <div>{displayData[rowIndex][tableAttributes[columnIndex].attributeName]}</div>;
      },
    },
  };

  const cellRenderType = (index: number) => {
    return cellRenderTypes[tableAttributes[index].attributeType];
  };
  return (
    <GenericTableContainer style={animatedStyles}>
      <AutoSizerContainer>
        <AutoSizer>
          {({ height, width }) => (
            <Table
              headerHeight={56}
              // we want our virtualized table to render during integration tests!
              height={process.env.JEST_WORKER_ID !== undefined ? 10000 : height}
              width={process.env.JEST_WORKER_ID !== undefined ? 10000 : width}
              rowCount={displayData.length}
              rowGetter={({ index }) => displayData[index]}
              rowHeight={56}
              scrollToIndex={index}
            >
              {_.map(tableAttributes, (value, index) => {
                return (
                  <Column
                    key={`${value.attributeName}-${index}`}
                    label={value.header || _.startCase(value.attributeName)}
                    dataKey={value.attributeName}
                    width={width}
                    {...cellRenderType(index)}
                  />
                );
              })}
            </Table>
          )}
        </AutoSizer>
      </AutoSizerContainer>
    </GenericTableContainer>
  );
};

export default GenericTable;
