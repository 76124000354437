import React, { useState, useEffect } from 'react';
import { Button } from 'elements';
import { useCurrentPatient, usePatientList } from 'context/patients';
import { useCurrentOffice } from 'context/offices';
import { AnimatePresence } from 'framer-motion';
import ExportModal from '../ExportModal/ExportModal';

type ExportButtonProps = {
  disabled: boolean;
  showDateRange?: boolean;
};

const ExportButton = ({ disabled: disabledProp, showDateRange }: ExportButtonProps) => {
  const currentPatient = useCurrentPatient();
  const currentOffice = useCurrentOffice();
  const patientList = usePatientList();
  const [isExportModalVisible, setIsExportModalVisible] = useState<boolean>(false);

  const disabled =
    !currentOffice ||
    patientList.isLoading ||
    disabledProp ||
    (currentPatient === undefined &&
      (window.location.pathname === '/patient-vitals-history' ||
        window.location.pathname === '/patient-alert-history' ||
        window.location.pathname === '/patient-thresholds'));

  useEffect(() => {
    // disable scrolling when the modal is open
    if (isExportModalVisible) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = 'auto';
  }, [isExportModalVisible]);

  return (
    <>
      <AnimatePresence>
        {isExportModalVisible ? (
          <ExportModal
            setIsExportModalVisible={setIsExportModalVisible}
            showDateRange={showDateRange}
          />
        ) : null}
      </AnimatePresence>
      <Button disabled={disabled} onClick={() => setIsExportModalVisible(true)}>
        Export
      </Button>
    </>
  );
};

export default ExportButton;
