import React, { useState, useEffect } from 'react';
import moment from 'moment';
import ky from 'ky';
import Auth from '@aws-amplify/auth';

type addressMultipleAlerts = (params: {
  indexArray: [] | undefined;
  setIndexArray: React.Dispatch<React.SetStateAction<[] | undefined>>;
  unaddressedAlerts: Alert[] | undefined;
  refetch: Function | undefined;
}) => Promise<Alert[] | undefined>;

const addressMultipleAlerts: addressMultipleAlerts = async ({
  indexArray,
  setIndexArray,
  unaddressedAlerts,
  refetch,
}) => {
  const username = (await Auth.currentSession()).idToken.payload['cognito:username'];

  // address the alerts in dynamo
  if (unaddressedAlerts === undefined || indexArray === undefined) {
    return undefined;
  }
  await Promise.all(
    indexArray.map(async (checkedIndex: number) => {
      const addressedTime = moment()
        .utc()
        .format();

      return ky.post('https://rwgi3alw14.execute-api.us-east-1.amazonaws.com/prod/address-alert', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: (await Auth.currentSession()).idToken.jwtToken,
        },
        json: {
          xmit_id: unaddressedAlerts[checkedIndex].xmit_id,
          addressed: 'true',
          addressedBy: username,
          timestamp: unaddressedAlerts[checkedIndex].timestamp,
          addressedTime,
          ...(unaddressedAlerts[checkedIndex].notes &&
            unaddressedAlerts[checkedIndex].notes !== '' && {
              notes: unaddressedAlerts[checkedIndex].notes,
            }),
        },
      });
    }),
  );
  await refetch?.();
  setIndexArray([]);
  return unaddressedAlerts;
};

export default addressMultipleAlerts;
