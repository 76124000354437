import React from 'react';
import { DatePicker } from 'antd';
import moment from 'moment';
import { useDateRange } from 'context/dateRange';

type DateRangePickerProps = {
  disabled?: boolean;
};

const DateRangePicker = ({ disabled }: DateRangePickerProps) => {
  const [dateRange, setDateRange] = useDateRange();

  return (
    <DatePicker.RangePicker
      disabled={disabled}
      disabledDate={date => {
        // disabled all future dates
        if (date.isAfter(moment())) return true;

        // disable dates before 90 days prior
        if (date.isBefore(moment().subtract(90, 'days'))) return true;
        return false;
      }}
      allowClear={false}
      use12Hours
      showTime
      format="MM/DD/YYYY h:mm a"
      value={dateRange}
      onChange={val => setDateRange([moment(val?.[0]), moment(val?.[1])])}
      ranges={{
        '7 Days': [
          moment()
            .subtract(7, 'days')
            .startOf('day'),
          moment().endOf('day'),
        ],
        '14 Days': [
          moment()
            .subtract(14, 'days')
            .startOf('day'),
          moment().endOf('day'),
        ],
        '30 Days': [
          moment()
            .subtract(30, 'days')
            .startOf('day'),
          moment().endOf('day'),
        ],
        '90 Days': [
          moment()
            .subtract(90, 'days')
            .startOf('day'),
          moment().endOf('day'),
        ],
      }}
    />
  );
};

export default DateRangePicker;
