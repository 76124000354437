import styled from 'styled-components';
import { animated } from 'react-spring';

export const ControlSubHeaderContainer = styled(animated.div)`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  margin: 32px 0px;
  height: 62px;
  @media (max-width: 800px) {
    justify-content: center;
  }
`;

export const RightSection = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 8px;
`;
