import styled from 'styled-components';
import { Badge as b } from 'antd';

export const NotificationButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0 20px 0 15px;
  height: 100%;
  width: auto;
  color: #0009;

  svg {
    font-size: 20px;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.015);
  }
`;

export const Badge = styled(b)``;
