import React from 'react';
import {
  CartesianGrid,
  ComposedChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  ReferenceLine,
} from 'recharts';
import { useVitalsChart, useFilteredVitals } from 'hooks';
import { Empty } from 'antd';
import moment from 'moment';
import { vitalsTypeToPretty, vitalsTypeToUnit } from 'dictionary';
import { useVitals } from 'context/vitals';
import {
  Card,
  Container,
  ChartContainer,
  ChartTitle,
  NoData,
  TestWrapper,
  TooltipCard,
  TooltipTitle,
  TooltipContent,
  TooltipUnits,
} from '../VitalsChart/VitalsChart.styled';

type BloodPressureChartProps = {
  id: string;
  dateRange: [moment.Moment, moment.Moment];
  systolicMinThreshold?: number | null;
  systolicMaxThreshold?: number | null;
  diastolicMinThreshold?: number | null;
  diastolicMaxThreshold?: number | null;
  systolicGroupMinThreshold?: number | null;
  systolicGroupMaxThreshold?: number | null;
  diastolicGroupMinThreshold?: number | null;
  diastolicGroupMaxThreshold?: number | null;
  dataMinOffset?: number;
  dataMaxOffset?: number;
  dataMin?: number | null;
  dataMax?: number | null;
};

const BloodPressureChart = React.forwardRef<ComposedChart, BloodPressureChartProps>(
  (
    {
      id, // id to give main div
      dateRange, // display vitals within this date range
      systolicMinThreshold = null, // systolic minimum threshold
      systolicMaxThreshold = null, // systolic maximum threshold
      diastolicMinThreshold = null, // systolic minimum threshold
      diastolicMaxThreshold = null, // systolic maximum threshold
      systolicGroupMinThreshold = null, // systolic minimum threshold
      systolicGroupMaxThreshold = null, // systolic maximum threshold
      diastolicGroupMinThreshold = null, // systolic minimum threshold
      diastolicGroupMaxThreshold = null, // systolic maximum threshold
      dataMinOffset = 10, // minimum offset override
      dataMaxOffset = 10, // maximum offset override
      dataMin = null, // minimum value displayed override
      dataMax = null, // maximum value displayed override
    },
    ref,
  ) => {
    // store hooks
    const { data } = useVitals();
    const vitals = useFilteredVitals({ data, dateRange, type: 'systolic' });
    const systolicData = useVitalsChart({
      vitals,
      minThreshold: systolicMinThreshold || systolicGroupMinThreshold,
      maxThreshold: systolicMaxThreshold || systolicGroupMaxThreshold,
      dataMin,
      dataMax,
      vitalType: 'systolic',
      dataMinOffset,
      dataMaxOffset,
    });
    const diastolicData = useVitalsChart({
      vitals,
      minThreshold: diastolicMinThreshold || diastolicGroupMinThreshold,
      maxThreshold: diastolicMaxThreshold || diastolicGroupMaxThreshold,
      dataMin,
      dataMax,
      vitalType: 'diastolic',
      dataMinOffset,
      dataMaxOffset,
    });

    const domainMin = Math.min(systolicData.domainMin, diastolicData.domainMin);
    const domainMax = Math.max(systolicData.domainMax, diastolicData.domainMax);

    return (
      <Card data-testid="vitals-chart__card">
        <ChartTitle>Blood Pressure</ChartTitle>
        <Container id={id}>
          {/* Display empty if no data exists for vitalType */}
          {vitals.length === 0 && (
            <TestWrapper data-testid="vitals-chart__empty">
              <NoData image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </TestWrapper>
          )}

          {/* Display the chart with data if data is loaded and exists for vitalType */}
          {vitals.length !== 0 && (
            <ChartContainer data-testid="vitals-chart-with-data">
              <ResponsiveContainer width="100%" height="100%" minWidth={50} minHeight={50}>
                <ComposedChart data={systolicData.chartData} ref={ref}>
                  <CartesianGrid strokeDasharray="3 3" />

                  <Tooltip content={<CustomTooltip />} coordinate={{ x: 100, y: 100 }} />
                  <XAxis
                    type="category"
                    dataKey="timestamp"
                    domain={['dataMin', 'dataMax']}
                    tickFormatter={date =>
                      moment
                        .utc(date)
                        .local()
                        .format('MM/DD')
                    }
                    scale="point"
                  />
                  <YAxis type="number" domain={[domainMin, domainMax]} scale="linear" />

                  {systolicData.minThreshold ? (
                    <ReferenceLine
                      y={systolicData.minThreshold}
                      label={{
                        //@ts-ignore
                        position: 'bottom',
                        value: `Systolic Minimum`,
                      }}
                      stroke="rgba(230, 64, 53, 0.5)"
                      strokeWidth={3}
                      strokeDasharray="3 3"
                    />
                  ) : null}

                  {systolicData.maxThreshold ? (
                    <ReferenceLine
                      y={systolicData.maxThreshold}
                      label={{
                        //@ts-ignore
                        position: 'top',
                        value: `Systolic Maximum`,
                      }}
                      stroke="rgba(230, 64, 53, 0.5)"
                      strokeWidth={3}
                      strokeDasharray="3 3"
                    />
                  ) : null}

                  {diastolicData.minThreshold ? (
                    <ReferenceLine
                      y={diastolicData.minThreshold}
                      label={{
                        //@ts-ignore
                        position: 'bottom',
                        value: `Diastolic Minimum`,
                      }}
                      stroke="rgba(230, 64, 53, 0.5)"
                      strokeWidth={3}
                      strokeDasharray="3 3"
                    />
                  ) : null}

                  {diastolicData.maxThreshold ? (
                    <ReferenceLine
                      y={diastolicData.maxThreshold}
                      label={{
                        //@ts-ignore
                        position: 'top',
                        value: `Diastolic Maximum`,
                      }}
                      stroke="rgba(230, 64, 53, 0.5)"
                      strokeWidth={3}
                      strokeDasharray="3 3"
                    />
                  ) : null}

                  <Line
                    isAnimationActive={false}
                    dataKey="systolic"
                    stroke="#094074"
                    strokeWidth={3}
                    activeDot={{ fill: '#094074', stroke: '#094074', strokeWidth: 4 }}
                  />

                  <Line
                    isAnimationActive={false}
                    dataKey="diastolic"
                    stroke="#0098c6"
                    strokeWidth={3}
                    activeDot={{ fill: '#0098c6', stroke: '#0098c6', strokeWidth: 4 }}
                  />
                </ComposedChart>
              </ResponsiveContainer>
            </ChartContainer>
          )}
        </Container>
      </Card>
    );
  },
);

type CustomTooltipProps = {
  active?: boolean;
  payload?: {
    payload: {
      aboveThreshold?: number;
      belowThreshold?: number;
      normalRange?: number;
      heartRate?: string;
      oxygen?: string;
    };
    dataKey: Vital;
    value?: string;
  }[];
  label?: string;
};

export const CustomTooltip = ({ active, payload, label }: CustomTooltipProps) => {
  if (!active || !payload) return null;

  // find the correct reading data in the payload array
  const readingData = payload.find(data => typeof data.dataKey === 'string');
  const secondaryReadingData = payload.find(
    data => typeof data.dataKey === 'string' && data.dataKey !== readingData?.dataKey,
  );

  if (!readingData) return null;

  return (
    <TooltipCard>
      <TooltipTitle>
        {moment
          .utc(label)
          .local()
          .format('MMMM D, YYYY - h:mma')}
      </TooltipTitle>
      <TooltipContent>
        <div>
          {vitalsTypeToPretty[readingData.dataKey]}: <b>{readingData.value}</b>&nbsp;
          <TooltipUnits>{vitalsTypeToUnit[readingData.dataKey]}</TooltipUnits>
        </div>
        {secondaryReadingData ? (
          <div>
            {vitalsTypeToPretty[secondaryReadingData.dataKey]}: <b>{secondaryReadingData.value}</b>
            &nbsp;
            <TooltipUnits>{vitalsTypeToUnit[secondaryReadingData.dataKey]}</TooltipUnits>
          </div>
        ) : null}
      </TooltipContent>
    </TooltipCard>
  );
};

export default BloodPressureChart;
