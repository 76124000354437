import * as Sentry from '@sentry/react';
import { notification } from 'antd';

type captureError = {
  error: Error;
};
const captureError = ({ error }: captureError) => {
  Sentry.captureException(error);
  notification.error({
    message: `Error: ${error.toString()}`,
  });
};

export default captureError;
