import styled from 'styled-components';

export const ButtonRow = styled.div`
  display: flex;
  flex-direction: row-reverse;
  padding: 20px;
  padding-bottom: 0px;

  @media (max-width: 800px) {
    flex-direction: column;
    padding: 16px 0 0 0;
  }

  .ant-btn {
    @media (max-width: 800px) {
      width: 100%;
    }
  }
`;
