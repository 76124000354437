import styled from 'styled-components';
import { Body } from 'elements';
import { WarningOutlined } from '@ant-design/icons';

export const Container = styled.div`
  height: 100%;
  min-height: 400px;
`;

export const TableRow = styled.tr`
  display: grid !important;
  grid-template-columns: 1.5fr 3fr 2fr;
  align-items: center;
  padding: 0 2rem;
  background-color: white;
  justify-content: space-between;
  margin-bottom: 1rem;
  height: 90px !important;
  overflow: hidden;
  border-width: 10px;
  border-style: solid;
  border-color: white;
  border-right: none;
  ${props => (props.onClick ? 'cursor: pointer;' : '')}

  &:hover {
    border-image: linear-gradient(to bottom, #4bcbf3, #49c0ad) 1 100%;
    background-color: rgba(255, 255, 255, 0.75);
  }
`;

export const TableCell = styled.td`
  width: auto !important;
`;

export const Reading = styled.span`
  display: flex;
  align-items: center;
`;

export const Unit = styled(Body)`
  margin-left: 0.4rem;
  opacity: 0.4;
  margin-top: 0.4rem;
  font-size: 0.8rem;
`;

export const Timestamp = styled.span`
  display: grid;
  grid-template-columns: 1fr auto auto;
  grid-gap: 1rem;
  justify-content: flex-end;
  justify-items: flex-end;
  align-items: center;
`;

export const Vital = styled.span`
  display: flex;
  align-items: center;
`;

export const ActiveAlertIcon = styled(WarningOutlined)`
  color: ${props => props.theme.danger1};
  margin-left: 1rem;
  font-size: 1.8rem;
`;

export const AddressedAlertIcon = styled(WarningOutlined)`
  color: ${props => props.theme.danger1};
  opacity: 0.4;
  margin-left: 1rem;
  font-size: 1.8rem;
`;

export const MiddleColumn = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
