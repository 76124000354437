import React, { useState } from 'react';
import { IoMdArrowRoundBack } from 'react-icons/io';
import { ExportModalAnimation } from 'animations/framer-motion';
import { useExport } from '../__hooks__';
import ExportSettings from '../ExportSettings/ExportSettings';
import { Button } from 'elements';
import { Container, Controls, Sidebar, BackButton, PreviewContainer } from './ExportModal.styled';

type ExportModalProps = {
  setIsExportModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  showDateRange?: boolean;
};

const ExportModal = ({ setIsExportModalVisible, showDateRange }: ExportModalProps) => {
  const { PDFButton, PDFPreview, CSVButton } = useExport();
  const [showPreview, setShowPreview] = useState(false);

  return (
    <ExportModalAnimation>
      <Controls>
        <Sidebar>
          <BackButton onClick={() => setIsExportModalVisible(false)}>
            <IoMdArrowRoundBack />
          </BackButton>
        </Sidebar>
        <ExportSettings
          setIsExportModalVisible={setIsExportModalVisible}
          PDFButton={PDFButton}
          CSVButton={CSVButton}
          showDateRange={showDateRange}
        />
      </Controls>
      <Container>
        {showPreview ? (
          PDFPreview
        ) : (
          <PreviewContainer>
            <Button onClick={() => setShowPreview(true)}>Open Preview</Button>
          </PreviewContainer>
        )}
      </Container>
    </ExportModalAnimation>
  );
};

export default ExportModal;
