/* eslint-disable prefer-template */
import React from 'react';
import moment from 'moment';
import { useCurrentPatient } from 'context/patients';
import { PatientInformationSubHeaderAnimation } from 'animations/framer-motion';
import { Grid, Item, Label, Value, PatientName } from './PatientInformationSubHeader.styled';

const PatientInformationSubHeader = () => {
  const currentPatient = useCurrentPatient();

  return (
    <PatientInformationSubHeaderAnimation>
      <PatientName>
        {currentPatient?.customer_name ? currentPatient.customer_name : '-'}
      </PatientName>
      <Grid>
        <Item noMobileBorder>
          <Label>
            Phone:{' '}
            <Value>{currentPatient?.customer_phone ? currentPatient?.customer_phone : '-'}</Value>
          </Label>
        </Item>
        <Item>
          <Label>
            DOB: <Value>{currentPatient?.date_of_birth ? currentPatient.date_of_birth : '-'}</Value>
          </Label>
        </Item>
        <Item noMobileBorder>
          <Label>
            Device: <Value>{currentPatient?.xmit_id ? currentPatient.xmit_id : '-'}</Value>
          </Label>
        </Item>
        <Item>
          <Label>
            Activation Date:{' '}
            <Value>
              {currentPatient?.updated_at
                ? moment(currentPatient.activation_date).format('MM/DD/YYYY')
                : '-'}
            </Value>
          </Label>
        </Item>
        <Item noBorder>
          <Label>
            Primary Care:{' '}
            <Value>{currentPatient?.doctor_name ? currentPatient.doctor_name : '-'}</Value>
          </Label>
        </Item>
        <Item noBorder>
          <Label>
            Physician Phone:{' '}
            <Value>{currentPatient?.doctor_phone ? currentPatient?.doctor_phone : '-'}</Value>
          </Label>
        </Item>
      </Grid>
    </PatientInformationSubHeaderAnimation>
  );
};

export default PatientInformationSubHeader;
