const parseFlag = (flag: any) => {
  try {
    const { surveyName, questions, flag: status } = JSON.parse(flag);
    return [surveyName, questions, status];
  } catch (err) {
    throw err;
  }
};

export default parseFlag;
