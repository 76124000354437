import React, { useState, useEffect, useRef } from 'react';
import { usePatientList, useCurrentPatient } from 'context/patients';
import { useCurrentOffice } from 'context/offices';
import { useRoutes } from 'hooks';
import {
  PatientListDropdownContainer,
  SearchIcon,
  AutoComplete,
  AutoCompleteOption,
} from './PatientListDropdown.styled';

type PatientListDropdownProps = {
  disabled: boolean;
};

const PatientListDropdown = ({ disabled }: PatientListDropdownProps) => {
  const currentOffice = useCurrentOffice();
  const { pushRouteChange } = useRoutes();
  const [searchValue, setSearchValue] = useState('');
  const { data: patientList, isLoading } = usePatientList();
  const currentPatient = useCurrentPatient();
  const selectorRef: any = useRef(null);

  useEffect(() => {
    if (currentPatient === undefined) setSearchValue('');
    else setSearchValue(`${currentPatient?.customer_name} - ${currentPatient?.date_of_birth}`);
  }, [currentPatient]);

  return (
    <PatientListDropdownContainer>
      <AutoComplete
        ref={selectorRef}
        placeholder={
          <span>
            <SearchIcon />
            Search Patients
          </span>
        }
        defaultValue={
          currentPatient ? `${currentPatient.customer_name} - ${currentPatient.date_of_birth}` : ''
        }
        value={searchValue}
        onSelect={(patient, event) => {
          if (selectorRef.current) selectorRef.current.blur();
          if (`${event.props.index}`) {
            pushRouteChange({ patient });
          } else {
            pushRouteChange({ patient: '' });
          }
        }}
        onFocus={() => setSearchValue('')}
        onChange={val => {
          // strange antd bug fix
          if (val.startsWith('user-')) return;
          setSearchValue(val);
        }}
        disabled={isLoading || disabled}
        filterOption={(input, option: any) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        style={{ width: '100%' }}
      >
        {currentOffice &&
          patientList?.map(patient => (
            <AutoCompleteOption
              key={`${patient.customer_name} - ${patient.date_of_birth} - ${patient.xmit_id}`}
              value={patient.username}
            >
              {`${patient.customer_name} - ${patient.date_of_birth}`}
            </AutoCompleteOption>
          ))}
      </AutoComplete>
    </PatientListDropdownContainer>
  );
};

export default PatientListDropdown;
