import { useEffect } from 'react';
import Analytics from '@aws-amplify/analytics';
import moment from 'moment';
import { useCurrentPatient } from 'context/patients';
import { useCurrentOffice } from 'context/offices';
import { useCognitoUser } from 'hooks';

const useKinesis = () => {
  const currentPatient = useCurrentPatient();
  const currentOffice = useCurrentOffice();
  const { data: cognitoUser } = useCognitoUser();

  useEffect(() => {
    const isOfficeIndexEmpty = !currentOffice;
    Analytics.record(
      {
        data: {
          url: window.location.origin + window.location.pathname,
          loggedInUser: currentPatient?.username,
          timestamp: moment().utc(),
          currentSelectedOffice: isOfficeIndexEmpty ? currentOffice?.description : null,
          group: cognitoUser?.group,
          currentSelectedPatient: currentPatient?.xmit_id,
        },
        streamName: 'PatientMonitoringDashboard-pagelogs',
      },
      'AWSKinesisFirehose',
    );
  }, [currentOffice, currentPatient, cognitoUser]);
};

export default useKinesis;
