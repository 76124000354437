import React, { useContext, useMemo } from 'react';
import { useQuery, QueryResult } from 'react-query';
import { useRoutes } from 'hooks';
import { useCurrentOffice } from 'context/offices';
import pullPatientList from './utils/pullPatientList';
import { captureError } from 'utils';

type PatientsContext = QueryResult<Patient[], Error>;

export const PatientsContext = React.createContext<PatientsContext | undefined>(undefined);

type PatientsProviderProps = {
  children: React.ReactNode;
};

export const PatientsProvider = ({ children }: PatientsProviderProps) => {
  const currentOffice = useCurrentOffice();
  const query = useQuery(['pullPatientList', { currentOffice }], pullPatientList, {
    enabled: currentOffice,
    onError: error => {
      captureError({ error });
    },
  });
  return <PatientsContext.Provider value={query}>{children}</PatientsContext.Provider>;
};

export const usePatientList = () => {
  const context = useContext(PatientsContext);

  if (context === undefined) {
    throw new Error('usePatientList must be used within a PatientsProvider');
  }

  return context;
};

export const useCurrentPatient = () => {
  const { queryParams } = useRoutes();
  const patientList = usePatientList();

  const currentPatient = useMemo(() => {
    if (!queryParams.patient) return undefined;
    return patientList.data?.find(item => item.username === queryParams.patient);
  }, [queryParams.patient, patientList]);
  return currentPatient;
};
