import styled from 'styled-components';
import React from 'react';
import { Layout, Menu as AntMenu } from 'antd';

export const Menu = styled(AntMenu)`
  height: 100%;
`;

export const SubMenu = styled(AntMenu.SubMenu)``;

export const SiderContainer = styled(Layout.Sider)`
  box-shadow: 2px 0 6px rgba(0, 21, 41, 0.35);
  z-index: 6;
  overflow: hidden;
`;

export const AnimatedIcon = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  min-width: 23px;
`;

export const AnimatedLogo = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 200px;
  padding-left: 18px;
`;

export const MenuItem = styled(({ path, currentLocation, ...rest }: any) => (
  <AntMenu.Item {...rest} />
))`
  background-color: ${props => props.theme.primary3} !important;
  color: rgba(255, 255, 255, 0.65) !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  padding-top: 8px !important;
  height: 56px !important;

  ${props =>
    props.currentLocation.startsWith(props.path) &&
    `
    background-color: ${props.theme.primary2} !important;
    color: ${props.theme.textLight} !important;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    padding-top: 8px !important;
    height: 56px !important;
  `}

  &:hover {
    background-color: ${props => props.theme.primary1} !important;
    color: ${props => props.theme.textDark} !important;
  }
`;
