import { useState, useEffect } from 'react';
import _ from 'lodash';

type useVitalsChart = (args: {
  vitals: VitalsReading[];
  minThreshold: number | null;
  maxThreshold: number | null;
  dataMin: number | null;
  dataMax: number | null;
  vitalType: Vital;
  dataMinOffset: number;
  dataMaxOffset: number;
}) => {
  minThreshold: number | null;
  maxThreshold: number | null;
  domainMin: number;
  domainMax: number;
  chartData: any[];
};

const useVitalsChart: useVitalsChart = ({
  vitals,
  minThreshold,
  maxThreshold,
  dataMin,
  dataMax,
  vitalType,
  dataMinOffset,
  dataMaxOffset,
}) => {
  const [domainMin, setDomainMin] = useState(0);
  const [domainMax, setDomainMax] = useState(0);

  // calculate the actual data min and actual data max
  useEffect(() => {
    if (vitals.length > 0) {
      // calculate the minimum value
      const actualDataMin = Number(_.minBy(vitals, vital => Number(vital[vitalType]))?.[vitalType]);

      // the minimum value should be capped at the minThreshold
      const cappedActualDataMin = Math.min(actualDataMin, minThreshold || Infinity);
      setDomainMin(dataMin || cappedActualDataMin - dataMinOffset);

      // calculate the minimum value
      const actualDataMax = Number(_.maxBy(vitals, vital => Number(vital[vitalType]))?.[vitalType]);

      // the maximum value should be capped at the maxThreshold
      const cappedActualDataMax = Math.max(actualDataMax, minThreshold || -Infinity);
      setDomainMax(dataMax || cappedActualDataMax + dataMaxOffset);
    }
  }, [vitals, vitalType, minThreshold, dataMin, dataMinOffset, dataMax, dataMaxOffset]);

  const chartData = vitals.map(vital => ({
    ...vital,
    belowThreshold: minThreshold || 0,
    normalRange: (maxThreshold || 99999) - (minThreshold || 99999),
    aboveThreshold: maxThreshold ? domainMax : 0,
  }));

  return {
    minThreshold,
    maxThreshold,
    domainMin,
    domainMax,
    // fix for Anelto's duplicate timestamp bug
    chartData: _.uniqBy(chartData, 'timestamp'),
  };
};

export default useVitalsChart;
