import styled from 'styled-components';

export const Sidebar = styled.div`
  background-color: ${props => props.theme.primary4};
  height: 100%;
  box-shadow: 2px 0 6px rgba(0, 21, 41, 0.35);
`;

export const Controls = styled.div`
  background-color: #f0f2f5;
  display: flex;
`;

export const BackButton = styled.div`
  background-color: ${props => props.theme.primary2};
  padding: 1.3rem;
  color: white;
  font-size: 1.3rem;
  line-height: 0;
  cursor: pointer;
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  body {
    height: 100vh !important;
    overflow-y: hidden !important;
  }
`;

export const PreviewContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #1d1d1d;
  background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='https://www.w3.org/2000/svg'%3E%3Cg fill='%23757475' fill-opacity='0.4' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
`;
