import moment from 'moment';

type useFilterByAlerts = (args: {
  data?: Alert[];
  dateRange: [moment.Moment, moment.Moment];
}) => Alert[];

const useFilterByAlerts: useFilterByAlerts = ({ data = [], dateRange }) => {
  return data.filter(item => {
    // filter by addressed time if it exists, else just use timestamp
    // this logic covers the case for office/patient alert history
    if (moment(item.addressedTime || item.timestamp).isBefore(dateRange[0])) return false;
    if (moment(item.addressedTime || item.timestamp).isAfter(dateRange[1])) return false;

    return item;
  });
};

export default useFilterByAlerts;
