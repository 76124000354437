import { useTrail } from 'react-spring';
import { useMotionPreference } from 'web-api-hooks';

export default function useNotesFade() {
  const prefersReducedMotion = useMotionPreference() === 'reduce' || window.name === 'nodejs';
  const animatedStyles = useTrail(2, {
    transform: 'translate3d(0,0px,0)',
    opacity: 1,
    from: {
      transform: 'translate3d(0,25px,0)',
      opacity: 0,
    },
    delay: 269,
    immediate: prefersReducedMotion,
  });
  return animatedStyles;
}
