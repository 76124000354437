import React, { useContext } from 'react';
import { useQuery, QueryResult, useMutation, queryCache, MutationResultPair } from 'react-query';
import { useCognitoUser } from 'hooks';
import { useCurrentPatient } from 'context/patients';
import { notification } from 'antd';
import pullGroupThresholds from './utils/pullGroupThresholds';
import pullPatientThresholds from './utils/pullPatientThresholds';
import updatePatientThresholds from './utils/updatePatientThresholds';
import { captureError } from 'utils';

export type ThresholdsContext = {
  groupThresholds: QueryResult<GroupThresholds, Error>;
  patientThresholds: QueryResult<PatientThresholds, Error>;
  updatePatientThresholds: MutationResultPair<
    GroupThresholds,
    {
      rowRecord: string;
      patientMin: string;
      patientMax: string;
      patientThresholds: PatientThresholds;
    },
    Error
  >;
};

const ThresholdsContext = React.createContext<ThresholdsContext | undefined>(undefined);

type ThresholdsProviderProps = {
  children: React.ReactNode;
};

export const ThresholdsProvider = ({ children }: ThresholdsProviderProps) => {
  const currentPatient = useCurrentPatient();
  const { data: cognitoUser } = useCognitoUser();
  const groupThresholds = useQuery('pullGroupThresholds', pullGroupThresholds, {
    enabled: currentPatient,
    onError: error => {
      captureError({ error });
    },
  });
  const patientThresholds = useQuery(
    ['pullPatientThresholds', { currentPatient }],
    pullPatientThresholds,
    {
      enabled: currentPatient,
      onError: error => {
        captureError({ error });
      },
    },
  );

  // onSuccess method for updatePatientThresholds
  const onSuccess = (data: GroupThresholds) => {
    // update the query to reflect the changes
    queryCache.setQueryData(['pullPatientThresholds', { currentPatient }], data);
    notification.success({ message: 'Threshold successfully updated' });
  };

  const updatePatientThresholdsMutation = useMutation(
    async (args: {
      rowRecord: string;
      patientMin: string;
      patientMax: string;
      patientThresholds: PatientThresholds;
    }) =>
      updatePatientThresholds({
        ...args,
        currentPatient,
        group: cognitoUser?.group,
      }),
    {
      onSuccess,
      onError: err => captureError({ error: err }),
    },
  );
  return (
    <ThresholdsContext.Provider
      value={{
        groupThresholds,
        patientThresholds,
        updatePatientThresholds: updatePatientThresholdsMutation,
      }}
    >
      {children}
    </ThresholdsContext.Provider>
  );
};

export const useThresholds = () => {
  const context = useContext(ThresholdsContext);

  if (context === undefined) {
    throw new Error('useThresholds must be used within a ThresholdsProvider');
  }

  return context;
};
