import styled from 'styled-components';
import { SearchOutlined } from '@ant-design/icons';

export const Container = styled.div`
  width: 100%;
  margin-right: 15px;
  max-width: 400px;
  z-index: 999;

  @media (max-width: 1370px) {
    max-width: 650px;
  }

  @media (max-width: 800px) {
    margin: 0;
  }
`;

export const SearchIcon = styled(SearchOutlined)`
  margin-right: 1rem;
`;
