import Auth from '@aws-amplify/auth';

const getCognitoUserInfo = async () => {
  const loggedInUserData = await Auth.currentSession();

  const cognitoUser: User = {
    group: loggedInUserData.accessToken.payload['cognito:groups']?.[0],
    username: loggedInUserData.idToken.payload['cognito:username'],
    email: undefined,
  };

  return cognitoUser;
};

export default getCognitoUserInfo;
