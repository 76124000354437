import ky from 'ky';
import Auth from '@aws-amplify/auth';

type pullSingleAlert = (
  key: string,
  args: {
    group: string | undefined;
    timestamp: string | undefined;
    xmit_id: string | undefined;
  },
) => Promise<Alert>;

const pullSingleAlert: pullSingleAlert = async (key, { group, timestamp, xmit_id }) => {
  const {
    idToken: { jwtToken },
  } = await Auth.currentSession();

  const response = await ky
    .post('https://p8g3dk8q6i.execute-api.us-east-1.amazonaws.com/prod', {
      json: {
        xmit_id,
        timestamp,
        carePlanId: group,
        token: jwtToken,
      },
    })
    .json<Alert>();

  return response;
};

export default pullSingleAlert;
