import React, { useContext, useState, useEffect } from 'react';
import { useCognitoUser } from 'hooks';
import { useCurrentPatient } from 'context/patients';
import { useQuery, QueryResult } from 'react-query';
import pullVitalsData from './utils/pullVitalsData';
import { captureError } from 'utils';

type VitalsContext = QueryResult<VitalsReading[], Error>;

export const VitalsContext = React.createContext<VitalsContext | undefined>(undefined);

type VitalsProviderProps = {
  children: React.ReactNode;
};

export const VitalsProvider = ({ children }: VitalsProviderProps) => {
  const currentPatient = useCurrentPatient();
  const { data: cognitoUser } = useCognitoUser();
  const query = useQuery(
    ['pullVitalsData', { currentPatient, group: cognitoUser?.group }],
    (key, args) => pullVitalsData(key, { ...args }),
    {
      refetchOnWindowFocus: true,
      // 5 minute stale time
      staleTime: 300000,
      onError: error => {
        captureError({ error });
      },
    },
  );

  return <VitalsContext.Provider value={query}>{children}</VitalsContext.Provider>;
};

export const useVitals = () => {
  const context = useContext(VitalsContext);

  if (context === undefined) {
    throw new Error('useVitals must be used within a VitalsProvider');
  }

  return context;
};
