import moment from 'moment';
import ky from 'ky';
import Auth from '@aws-amplify/auth';

type addressSingleAlert = (params: {
  notes: string | undefined;
  unaddressedAlert: Alert;
}) => Promise<Alert>;

const addressSingleAlert: addressSingleAlert = async ({ notes, unaddressedAlert }) => {
  const addressedTime = moment().format();
  const username = (await Auth.currentSession()).idToken.payload['cognito:username'];

  // address the alert in dynamo
  await ky.post('https://rwgi3alw14.execute-api.us-east-1.amazonaws.com/prod/address-alert', {
    headers: {
      'Content-Type': 'application/json',
      Authorization: (await Auth.currentSession()).idToken.jwtToken,
    },
    json: {
      xmit_id: unaddressedAlert.xmit_id,
      addressed: 'true',
      addressedBy: username,
      timestamp: unaddressedAlert.timestamp,
      addressedTime,
      ...(notes &&
        notes !== '' && {
          notes,
        }),
    },
  });

  return unaddressedAlert;
};

export default addressSingleAlert;
