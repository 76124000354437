import React from 'react';
import { usePageFade } from 'hooks';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import { ControlSubHeaderContainer, RightSection } from './ControlSubHeader.styled';

type ControlSubHeaderProps = {
  children?: React.ReactNode;
  breadcrumbSections: string[];
  isFetching?: boolean;
  isSuccess?: boolean;
  refetch?: Array<() => Promise<any>>;
};

const ControlSubHeader = ({
  children,
  breadcrumbSections,
  isFetching,
  isSuccess,
  refetch,
}: ControlSubHeaderProps) => {
  const animatedStyles = usePageFade();

  return (
    <div>
      <ControlSubHeaderContainer style={animatedStyles}>
        <Breadcrumb
          refetch={refetch}
          sections={breadcrumbSections}
          isFetching={isFetching}
          isSuccess={isSuccess}
        />
        <RightSection>{children}</RightSection>
      </ControlSubHeaderContainer>
    </div>
  );
};

export default ControlSubHeader;
