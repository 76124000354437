import React from 'react';
import _ from 'lodash';
import { OfficeActiveAlertsTable } from 'components/alerts';
import { useUnaddressedAlerts } from 'context/unaddressedAlerts';
import { useKinesis, useAlertMutations } from 'hooks';
import ControlSubHeader from 'components/display/ControlSubHeader/ControlSubHeader';
import { ExportButton } from 'components/export';
import { OfficeActiveAlertsContainer } from './OfficeActiveAlerts.styled';

const OfficeActiveAlerts = () => {
  const { query } = useUnaddressedAlerts();
  const { addressMultipleAlerts } = useAlertMutations();

  useKinesis();

  return (
    <OfficeActiveAlertsContainer>
      <ControlSubHeader
        breadcrumbSections={['Office', 'Active Alerts']}
        isFetching={query.isFetching}
        refetch={[query.refetch]}
        isSuccess={query.isSuccess}
      >
        <ExportButton disabled={query.isLoading} />
      </ControlSubHeader>
      <OfficeActiveAlertsTable
        officeActiveAlertsQuery={query}
        addressMultipleAlerts={addressMultipleAlerts}
      />
    </OfficeActiveAlertsContainer>
  );
};

export default OfficeActiveAlerts;
