import { useSpring, useTransition } from 'react-spring';
import { useMotionPreference } from 'web-api-hooks';

export default function usePartyFade(parties: any) {
  const prefersReducedMotion = useMotionPreference() === 'reduce' || window.name === 'nodejs';
  const animatedStyles = useSpring({
    opacity: 1,
    transform: 'translate3d(0px,0px,0px)',
    from: {
      opacity: 0,
      transform: 'translate3d(25px,0px,0px)',
    },
    immediate: prefersReducedMotion,
    delay: 269,
  });
  const animatedParties = useTransition(parties, party => party.name, {
    from: {
      transform: 'scale3d(1.05,1.05,1.05)',
      opacity: 0,
    },
    enter: {
      transform: 'scale3d(1,1,1)',
      opacity: 1,
    },
    immediate: prefersReducedMotion,
    trail: 269,
    delay: 369,
  });
  return [animatedStyles, animatedParties];
}
