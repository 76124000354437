import styled from 'styled-components';
import theme from 'styles/theme';

export const Title = styled.h1<{ light?: boolean }>`
  display: inline-block;
  color: ${({ light }) => (light ? theme.textLight : theme.textDark)};
  font-family: ${theme.headerFont};
  font-weight: bold;
  font-size: 4rem;
`;

export const SubTitle = styled.h2<{ light?: boolean }>`
  display: inline-block;
  color: ${({ light }) => (light ? theme.textLight : theme.textDark)};
  font-family: ${theme.headerFont};
  font-size: 3rem;
`;

export const Header = styled.h3<{ light?: boolean; noMargins?: boolean }>`
  display: inline-block;
  color: ${({ light }) => (light ? theme.textLight : theme.textDark)};
  font-family: ${theme.headerFont};
  font-weight: bold;
  margin-top: 3rem;
  margin-bottom: 1.5rem;
  font-size: 2rem;
  margin-top: ${props => (props.noMargins ? '0' : '3rem')};
  margin-bottom: ${props => (props.noMargins ? '0' : '1.5rem')};
`;

export const SubHeader = styled.h4<{ light?: boolean; noMargins?: boolean }>`
  display: inline-block;
  color: ${({ light }) => (light ? theme.textLight : theme.textDark)};
  font-family: ${theme.headerFont};
  font-size: 1.4rem;
  margin-bottom: ${props => (props.noMargins ? '0' : '1em')};
`;

export const Body = styled.p<{ light?: boolean; noMargins?: boolean }>`
  color: ${({ light }) => (light ? theme.textLight : theme.textDark)};
  font-family: ${theme.bodyFont};
  margin-top: 0rem;
  font-size: 1rem;
  line-height: 1.7rem;
  margin-bottom: ${props => (props.noMargins ? '0' : '1em')};
`;
