import { useMutation, MutationResultPair, MutateOptions } from 'react-query';
import generatePDF from './utils/generatePDF';

type useGeneratePDF = () => MutationResultPair<
  Blob,
  {
    document: React.ReactElement;
    title: string;
    generateChartImages?: Function;
  },
  Error
>;

const useGeneratePDF: useGeneratePDF = () => {
  const mutation = useMutation(generatePDF);

  return mutation;
};

export default useGeneratePDF;
