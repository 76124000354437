import React from 'react';
import { useCurrentOffice } from 'context/offices';
import { useCurrentPatient } from 'context/patients';
import { ThresholdsContext, useThresholds } from 'context/thresholds';
import { vitalsTypes, vitalsTypeToPretty } from 'dictionary';
import moment from 'moment';
import { Button } from 'elements';
import { useGeneratePDF } from 'components/export/__hooks__';
import { Document, PDFViewer } from '@react-pdf/renderer';
import PDFHeader from './PDFHeader';
import { PagePadding, Body, TableRow, TableColumn, Table, TableHeader, Footer } from './styles';

type WrapperProps = {
  display: 'preview' | 'button';
};

const Wrapper = ({ display }: WrapperProps) => {
  const thresholds = useThresholds();
  const currentOffice = useCurrentOffice();
  const currentPatient = useCurrentPatient();
  const title = `${currentPatient?.customer_name} Patient Thresholds ${moment().format(
    'MM-DD-YYYY',
  )}`;
  const [generatePDF, { isLoading }] = useGeneratePDF();

  const document = (
    <PatientThresholdsPDF
      thresholds={thresholds}
      currentOffice={currentOffice}
      title={title}
      patientName={currentPatient?.customer_name}
      dob={currentPatient?.date_of_birth}
      mrn={currentPatient?.MRNum}
    />
  );

  if (display === 'preview') {
    return (
      <PDFViewer width="100%" height="100%">
        {document}
      </PDFViewer>
    );
  }

  return (
    <Button loading={isLoading} onClick={() => generatePDF({ document, title })}>
      Export
    </Button>
  );
};

type PatientThresholdsPDFProps = {
  thresholds: ThresholdsContext;
  currentOffice: Office | undefined;
  title: string;
  patientName?: string;
  dob?: string;
  mrn?: string;
};

const PatientThresholdsPDF = ({
  thresholds,
  currentOffice,
  title,
  patientName,
  dob,
  mrn,
}: PatientThresholdsPDFProps) => (
  <Document title={title}>
    <PagePadding wrap>
      <PDFHeader
        currentOffice={currentOffice}
        title="Patient Thresholds"
        patientName={patientName}
        dob={dob}
        mrn={mrn}
      />

      <Table>
        {/* Table Header Row */}
        <TableRow fixed>
          <TableColumn>
            <TableHeader>Vital</TableHeader>
          </TableColumn>
          <TableColumn>
            <TableHeader>Group Low</TableHeader>
          </TableColumn>
          <TableColumn>
            <TableHeader>Group High</TableHeader>
          </TableColumn>
          <TableColumn>
            <TableHeader>Patient Low</TableHeader>
          </TableColumn>
          <TableColumn>
            <TableHeader>Patient High</TableHeader>
          </TableColumn>
        </TableRow>

        {/* Table Data Rows */}
        {vitalsTypes.map(vital => (
          <TableRow wrap={false} key={vital}>
            <TableColumn>
              <Body>{vitalsTypeToPretty[vital] || '-'}</Body>
            </TableColumn>
            <TableColumn>
              <Body>{thresholds.groupThresholds.data?.[vital].min || '-'}</Body>
            </TableColumn>
            <TableColumn>
              <Body>{thresholds.groupThresholds.data?.[vital].max || '-'}</Body>
            </TableColumn>
            <TableColumn>
              <Body>{thresholds.patientThresholds.data?.[vital].min || '-'}</Body>
            </TableColumn>
            <TableColumn>
              <Body>{thresholds.patientThresholds.data?.[vital].max || '-'}</Body>
            </TableColumn>
          </TableRow>
        ))}
      </Table>
      <Footer fixed>
        <Body fixed>Patient Thresholds</Body>
        <Body
          fixed
          render={({ pageNumber, totalPages }: { pageNumber: number; totalPages: number }) =>
            `${pageNumber} / ${totalPages}`
          }
        />
      </Footer>
    </PagePadding>
  </Document>
);

export default Wrapper;
