import React, { useState } from 'react';
import { QueryResult, MutationResultPair } from 'react-query';
import { EmptyIcon, LoadingIcon, Button, Unauthorized } from 'elements';
import GenericTable from 'elements/GenericTable/GenericTable';
import { useOfficeList } from 'context/offices';
import { usePatientList } from 'context/patients';
import { useRoutes, useUnauthorized } from 'hooks';
import { ButtonRow } from './OfficeActiveAlertsTable.styled';

type OfficeActiveAlertsTableProps = {
  officeActiveAlertsQuery: QueryResult<Alert[], Error>;
  addressMultipleAlerts: MutationResultPair<
    Alert[] | undefined,
    {
      indexArray: [] | undefined;
      setIndexArray: React.Dispatch<React.SetStateAction<[] | undefined>>;
      unaddressedAlerts: Alert[] | undefined;
      refetch: Function | undefined;
    },
    Error
  >;
};

const OfficeActiveAlertsTable = ({
  officeActiveAlertsQuery,
  addressMultipleAlerts,
}: OfficeActiveAlertsTableProps) => {
  const { isAuthorized } = useUnauthorized();
  const officeList = useOfficeList();
  const patientList = usePatientList();
  const { pushRouteChange } = useRoutes();
  const [checked, setChecked] = useState<[]>();
  const [addressMultipleAlertsFunction, { isLoading, reset }] = addressMultipleAlerts;
  const { refetch } = officeActiveAlertsQuery;

  const activeAlertsAttributes = [
    {
      attributeName: 'timestamp',
      attributeType: 'timestamp',
      width: 300,
      flex: 2,
    },
    { attributeName: 'patient', attributeType: 'string' },
    { attributeName: 'type', attributeType: 'string' },
    {
      attributeName: 'addressed',
      attributeType: 'checkbox',
    },
    {
      attributeName: 'details',
      attributeType: 'button',
      buttonText: 'Details',
      header: ' ',
      onClick: (alert: Alert) => {
        pushRouteChange({
          section: 'alert-details',
          xmit: alert.xmit_id,
          timestamp: alert.timestamp,
          patient:
            patientList.data?.find(patient => patient.xmit_id === alert.xmit_id)?.username || '',
        });
      },
    },
  ];

  if (officeActiveAlertsQuery.isLoading || officeList.isLoading || patientList.isLoading) {
    return <LoadingIcon />;
  }
  if (!isAuthorized) return <Unauthorized />;

  if (!officeActiveAlertsQuery.data?.length) {
    return <EmptyIcon />;
  }

  return (
    <>
      <GenericTable
        tableData={officeActiveAlertsQuery?.data}
        isLoading={officeActiveAlertsQuery.isLoading}
        tableAttributes={activeAlertsAttributes}
        checked={checked}
        setChecked={setChecked}
      />
      <ButtonRow>
        <div>
          <Button
            type="secondary"
            disabled={checked?.length === 0}
            onClick={() => {
              setChecked([]);
            }}
          >
            Clear
          </Button>
          &nbsp;
          <Button
            type="primary"
            loading={isLoading}
            disabled={checked?.length === 0}
            onClick={async () => {
              await addressMultipleAlertsFunction({
                indexArray: checked,
                setIndexArray: setChecked,
                unaddressedAlerts: officeActiveAlertsQuery.data,
                refetch,
              });
              reset();
            }}
          >
            Address
          </Button>
        </div>
      </ButtonRow>
    </>
  );
};

export default OfficeActiveAlertsTable;
