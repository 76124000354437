import styled from 'styled-components';
import { UserOutlined as icon } from '@ant-design/icons';

export const ResponsiblePartyCardContainer = styled.div`
  width: 100%;
  border-radius: 12px;
  background-color: rgba(255, 255, 255, 1);
  border: 1px rgba(0, 0, 0, 0.15) solid;
  padding: 0.5rem;
  margin-bottom: 8px;
`;

export const Relationship = styled.p`
  color: rgba(0, 0, 0, 0.85);
  text-align: start;
  font-weight: bold;
  margin: 0px;
  margin-bottom: 0.125rem;
  font-size: 16px;
`;

export const Name = styled.p`
  text-align: center;
  font-weight: lighter;
  color: rgba(0, 0, 0, 0.85);
  margin: 0px;
  font-size: 20px;
`;

export const Number = styled.p`
  color: rgba(0, 0, 0, 0.85);
  text-align: center;
  margin: 0px;
  font-size: 18px;
  font-weight: 400;
`;

export const UserOutlined = styled(icon)``;
