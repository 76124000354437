import React from 'react';
import { Empty } from 'antd';
import { Container } from './EmptyIcon.styled';

const EmptyIcon = () => {
  return (
    <Container>
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
    </Container>
  );
};

export default EmptyIcon;
