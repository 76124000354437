import React, { useContext, useState } from 'react';
import moment from 'moment';

type DateRangeContext = [
  [moment.Moment, moment.Moment],
  React.Dispatch<[moment.Moment, moment.Moment]>,
];

export const DateRangeContext = React.createContext<DateRangeContext | undefined>(undefined);

type DateRangeProviderProps = {
  children: React.ReactNode;
};

export const DateRangeProvider = ({ children }: DateRangeProviderProps) => {
  const state = useState<[moment.Moment, moment.Moment]>([
    moment()
      .subtract(7, 'days')
      .startOf('day'),
    moment().endOf('day'),
  ]);

  return <DateRangeContext.Provider value={state}>{children}</DateRangeContext.Provider>;
};

export const useDateRange = () => {
  const context = useContext(DateRangeContext);

  if (context === undefined) {
    throw new Error('useDateRange must be used within a DateRangeProvider');
  }

  return context;
};
