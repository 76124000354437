import React, { ReactNode, useContext, useState } from 'react';
import { QueryResult, useQuery } from 'react-query';
import { useRoutes } from 'hooks';
import pullOfficeList from './utils/pullOfficeList';
import { captureError } from 'utils';

type OfficesContext = QueryResult<Office[], Error>;

const OfficesContext = React.createContext<OfficesContext | undefined>(undefined);

type OfficesProviderProps = {
  children: ReactNode;
};

export const OfficesProvider = ({ children }: OfficesProviderProps) => {
  const officeList = useQuery('pullOfficeList', pullOfficeList, {
    onError: error => {
      captureError({ error });
    },
  });

  return <OfficesContext.Provider value={officeList}>{children}</OfficesContext.Provider>;
};

export const useOfficeList = () => {
  const context = useContext(OfficesContext);

  if (context === undefined) {
    throw new Error('useOfficeList must be used within a OfficesProvider');
  }

  return context;
};

export const useCurrentOffice = () => {
  const context = useContext(OfficesContext);
  const params = useRoutes();
  if (context === undefined) {
    throw new Error('useCurrentOffice must be used within a OfficesProvider');
  }
  const currentOffice = context.data?.find(office => office.officeID === params.queryParams.office);

  return currentOffice;
};
