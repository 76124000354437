import React, { useState, useEffect } from 'react';
import { Breadcrumb as AntBreadcrumb } from 'antd';
import moment from 'moment';
import {
  Container,
  SyncIcon,
  SyncInformation,
  UpdatedAtText,
  ReloadOutlined,
} from './Breadcrumbs.styled';

type BreadcrumbProps = {
  sections: string[];
  isFetching?: boolean;
  isSuccess?: boolean;
  refetch?: Array<() => Promise<any>>;
};

const Breadcrumb = ({ sections, isFetching, isSuccess, refetch }: BreadcrumbProps) => {
  const [lastUpdated, setLastUpdated] = useState<string>();

  useEffect(() => {
    if (!isFetching && isSuccess) {
      setLastUpdated(moment().format());
    } else {
      setLastUpdated('');
    }
  }, [isFetching, isSuccess]);

  return (
    <Container>
      <AntBreadcrumb style={{ fontSize: '1.1rem' }}>
        {sections.map(section => (
          <AntBreadcrumb.Item key={section}>{section}</AntBreadcrumb.Item>
        ))}
      </AntBreadcrumb>
      <SyncInformation>
        {isFetching ? (
          <SyncIcon data-testid="sync-icon" spin />
        ) : (
          <UpdatedAtText
            onClick={async () => {
              // Refetch all queries needed per page
              if (!isFetching && isSuccess && refetch) {
                await Promise.all(
                  refetch.map(refetch => {
                    refetch();
                  }),
                );
              }
            }}
            enabled={!isFetching && isSuccess}
          >
            Last updated {!isFetching && isSuccess ? moment(lastUpdated).format('l LT') : '-'}
            {!isFetching && isSuccess ? <ReloadOutlined data-testid="reload-icon" /> : null}
          </UpdatedAtText>
        )}
      </SyncInformation>
    </Container>
  );
};

export default Breadcrumb;
