import React, { useContext, useMemo } from 'react';
import { QueryResult, useMutation, useQuery } from 'react-query';
import _ from 'lodash';
import { notification } from 'antd';
import { useHistory } from 'react-router-dom';
import { useCurrentOffice } from 'context/offices';
import { usePatientList } from 'context/patients';
import { useCognitoUser, useRoutes } from 'hooks';
import { pullUnaddressedAlerts, addressMultipleAlerts } from './utils';
import { captureError } from 'utils';

type UnaddressedAlertsContext = QueryResult<Alert[], Error>;

export const UnaddressedAlertsContext = React.createContext<UnaddressedAlertsContext | undefined>(
  undefined,
);

type UnaddressedAlertsProviderProps = {
  children: React.ReactNode;
};

// UnaddressedAlerts Provider
export const UnaddressedAlertsProvider = ({ children }: UnaddressedAlertsProviderProps) => {
  const currentOffice = useCurrentOffice();
  const history = useHistory();
  const patientList = usePatientList();
  const { data: cognitoUser } = useCognitoUser();

  const query = useQuery(
    [
      'pullUnaddressedAlerts',
      {
        currentOffice,
        group: cognitoUser?.group,
        isAddressed: 'false',
        patientList: patientList.data || [],
      },
    ],
    pullUnaddressedAlerts,
    {
      staleTime: 0,
      enabled: currentOffice && patientList.isSuccess,
      onError: error => {
        captureError({ error });
      },
    },
  );
  return (
    <UnaddressedAlertsContext.Provider value={query}>{children}</UnaddressedAlertsContext.Provider>
  );
};

export const useUnaddressedAlerts = () => {
  const context = useContext(UnaddressedAlertsContext);

  if (context === undefined) {
    throw new Error('useUnaddressedAlerts must be used within a AlertsProvider');
  }

  return {
    query: context,
  };
};

export const useSelectedAlert = ({
  timestamp,
  xmit_id,
}: {
  timestamp: string;
  xmit_id: string;
}) => {
  const context = useContext(UnaddressedAlertsContext);

  const selectedAlert = useMemo(() => {
    return _.find(context?.data, { timestamp, xmit_id });
  }, [context, timestamp, xmit_id]);

  return { data: selectedAlert };
};
