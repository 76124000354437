import React from 'react';
import { Button } from 'elements';
import { CSVLink } from 'react-csv';
import { useFilteredVitals } from 'hooks';
import { useVitals } from 'context/vitals';
import moment from 'moment';
import { useCurrentPatient } from 'context/patients';

type PatientVitalsHistoryCSVProps = {
  dateRange: [moment.Moment, moment.Moment];
};

const PatientVitalsHistoryCSV = ({ dateRange }: PatientVitalsHistoryCSVProps) => {
  const { data } = useVitals();
  const vitals = useFilteredVitals({ data, dateRange });
  const currentPatient = useCurrentPatient();
  const title = `${currentPatient?.customer_name} Patient Vitals History ${moment().format(
    'MM-DD-YYYY',
  )}`;

  const headers = [
    { label: 'Timestamp', key: 'timestamp' },
    { label: 'Blood Pressure', key: 'bloodPressure' },
    { label: 'Heart Rate', key: 'heartRate' },
    { label: 'Weight', key: 'weight' },
    { label: 'Oxygen', key: 'oxygen' },
    { label: 'Glucose', key: 'glucose' },
    { label: 'Temperature', key: 'temperature' },
    { label: 'Fev 1', key: 'fev1' },
    { label: 'Pef', key: 'pef' },
  ];

  const formattedVitals = vitals
    .map(item => {
      return {
        ...item,
        timestamp: moment(item.timestamp).toISOString(),
        bloodPressure: item.systolic ? `${item.systolic}/${item.diastolic}` : undefined,
      };
    })
    // sort in descending order
    .sort(({ timestamp: z }, { timestamp: a }) => moment(a).valueOf() - moment(z).valueOf());

  return (
    <CSVLink data={formattedVitals} headers={headers} filename={`${title}.csv`} target="_blank">
      <Button>Export</Button>
    </CSVLink>
  );
};

export default PatientVitalsHistoryCSV;
